import axios, { Axios, AxiosResponse } from 'axios';

// nst DEBUG = process.env.NODE_ENV === 'development';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SHOWTIME_API_URL,
  timeout: 15000,
});


export default axiosInstance;
