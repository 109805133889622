import React, { useEffect, useState } from "react";
import SHTTeamStatsCard from "../../components/SHTTeamStatsCard";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import SHTLineHeader from "../../components/SHTLineHeader";
import { Container, Spinner } from "react-bootstrap";
import SHTText from "../../components/SHTText";

import PlayerImage from "../../assets/images/svg/statsinfoimage.svg";
import "../../assets/styles/page/team_info_stats_detail_style.css";

import SHTSpinner from "../../components/SHTSpinner";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import RoasterHeader from "../roaster/RoasterHeader";
import RoasterFilter from "../roaster/RoasterFilter";
import SHTNoResult from "../../components/SHTNoResult";
import SHTButton from "../../components/SHTButton";
import Swal from "sweetalert2";
import { formatDate, formatImage } from "../../utils/helper";
import toast, { Toaster } from "react-hot-toast";
import SHTCreateContractModal from "../../components/SHTCreateContractModal";

type MyParams = {
  id: string;
};

type defenseStatProp = {
  player_image: string;
  int: string;
  fp: string;
  bd: string;
  sack: string;
  blk: string;
  tds: string;
};

interface cardDetailsProps {
  defenseStats: any;
}
const TeamContract = ({
  fetchTeamContracts,
  updateContracts,
  fetchPlayersWithoutContract,
  fetchTeamPlayers,
  sendContracts,
  fetchTeamsById,
}: any) => {
  const navigate = useNavigate();
  const { team_id } = useParams();
  const [teamContracts, setTeamContracts] = useState([]);
  const [teamInfoImage, setTeamInfoImage] = useState();
  const [teamColor, setTeamColor] = useState();
  const [loading, setLoading] = useState(true);
  const [contractLoading, setContractLoading] = useState<any>(true);
  const [playerLoadingStates, setPlayerLoadingStates]: any = useState({});
  const [findPlayerLoading, setFindPlayerLoading] = useState<any>(false);
  const [fetchPlayersLoading, setFetchPlayersLoading] = useState<any>(false);
  const [teamLoading, setTeamLoading] = useState(true);

  const [players, setPlayers] = useState([]);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const [player_id, setPlayeID] = useState<any>();

  const { id } = useParams<MyParams>();

  useEffect(() => {
    handleFetchTeamContract();
    handleFetchTeamInfo(team_id);
    handleFetchTeamPlayers(team_id);
  }, []);

  const handleNavigate = (page: string) => {
    if (page === "roaster") {
      navigate(`/roaster/${team_id}`);
    } else if (page === "info") {
      navigate(`/team-info/${team_id}`);
    } else if (page === "contracts") {
      navigate(`/contracts/${team_id}`);
    } else {
      navigate(`/contracts/${team_id}`);
    }
  };

  const handleCloseCreateContractModal = () => {
    setShowCreateContractModal(false);
  };

  const handleShowCreateContractModal = (id: any) => {
    setPlayeID(id);
    setShowCreateContractModal(true);
  };

  const handleFetchTeamContract = async () => {
    const params = {
      team_id: id,
    };
    setLoading(true);
    await fetchTeamContracts()
      .then((response: RequestResponseInterface) => {
        if (response.data.response_code === "3000") {
          setTeamContracts(response.data.results);
          setLoading(false);
          setContractLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setContractLoading(false);
      });
  };

  const handleFetchTeamInfo = async (id: any) => {
    setTeamLoading(true);
    await fetchTeamsById(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTeamLoading(false);
          setTeamInfoImage(response.data.data.team_image[0].image);
          setTeamColor(response.data.data.team_color);
        } else {
          setTeamLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamLoading(false);
      });
  };

  const handleFindPlayer = async () => {
    setFindPlayerLoading(true);
    await fetchPlayersWithoutContract()
      .then((response: RequestResponseInterface) => {
    
        if (response.status === 200) {
        
          setPlayers(response.data.results);
          setFindPlayerLoading(false);
        }
      })
      .catch((err: any) => {
        setFindPlayerLoading(false);
        if (err.response.status === 400) {
          toast.error("Error finding player");
        }
        toast.error("Error finding player");
      });
  };

  const handleFetchTeamPlayers = async (id: any) => {
    setFetchPlayersLoading(true);
    await fetchTeamPlayers(id)
      .then((response: RequestResponseInterface) => {
        if (response.data.response_code === 3000) {
          setTeamPlayers(response.data.data);
          setFetchPlayersLoading(false);
        }
      })
      .catch((err: any) => {
        setFetchPlayersLoading(false);
        if (err.response.status === 400) {
          toast.error("Error fetching player");
        }
        toast.error("Error fetching player");
      });
  };

  const handleCloseContract = async (
    team_id: string,
    player_id: string,
    contract_id: string
  ) => {
    Swal.fire({
      text: "Are you sure you want to terminate this contract?",
      icon: "warning",
      confirmButtonText: "Terminate",
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setPlayerLoadingStates((prevState: any) => ({
          ...prevState,
          [player_id]: true,
        }));
        const payload = { team_id, player_id, contract_status: "TERMINATED" };
  
        toast.promise(
          updateContracts(contract_id, payload)
            .then((response: RequestResponseInterface) => {
              if (response.status === 200) {
                return response; // Resolve the promise successfully
              } else {
                throw new Error("Error terminating contract"); // Reject the promise
              }
            })
            .finally(() => {
              setPlayerLoadingStates((prevState: any) => ({
                ...prevState,
                [player_id]: false,
              }));
            }),
          {
            loading: "Sending termination request...",
            success: "A contract termination request has been sent to the player",
            error: "Error terminating contract",
          }
        );
      }
    });
  };
  
  
  const handleSendContract = async (data: any) => {
    Swal.fire({
      text: "Are you sure you want to send this contract?",
      icon: "warning",
      confirmButtonText: "Continue",
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setPlayerLoadingStates((prevState: any) => ({
          ...prevState,
          [player_id]: true,
        }));
  
        const payload = { team_id, player_id, ...data };
        setShowCreateContractModal(false);
        const contractPromise = sendContracts(payload);
  
        toast.promise(
          contractPromise,
          {
            loading: "Sending contract...",
            success: "Contract successfully sent to this user.",
            error: (err) => {
              return `Failed to send contract: ${err.response?.data?.data || err.message}`;
            },
          }
        );
  
        await contractPromise
          .then((response: RequestResponseInterface) => {
            if (response.status === 201) {
              setPlayerLoadingStates((prevState: any) => ({
                ...prevState,
                [player_id]: false,
              }));
            } else {
              toast.error(response.data);
              setPlayerLoadingStates((prevState: any) => ({
                ...prevState,
                [player_id]: false,
              }));
            }
          })
          .catch((err: any) => {
            console.log(err);
            setPlayerLoadingStates((prevState: any) => ({
              ...prevState,
              [player_id]: false,
            }));
          });
      }
    });
  };
  

  const handleNavigatePlayerRoaster = (player_id: string) => {
    navigate(`/players/roaster/${player_id}/${team_id}`);
  };

  const renderPlayers = (players: any, type: string) => (
    <div>
      <SHTCreateContractModal
        show={showCreateContractModal}
        handleClose={handleCloseCreateContractModal}
        handleSubmitData={handleSendContract}
      />

      {players?.length > 0 ? (
        <>
          <Toaster />
          <div className="stats_table_container">
            <div className=" overflow-auto">
              <table>
                <thead className="title_head">
                  <tr>
                    <th className="py-4 px-3 title_head">
                      <SHTText size="p" color="white" text="Player Name" />
                    </th>

                    <th className="py-4 px-3 title_head"></th>

                    <th className="py-4 px-3 title_head">
                      <SHTText size="p" color="white" text="Action" />
                    </th>
                  </tr>
                </thead>

                {players?.map((stat: any) => {
                  return (
                    <tr className="table_details">
                      <th className="py-4 px-3 d-flex align-items-center">
                        <img
                          src={formatImage(stat.player_image)}
                          alt="Player Image"
                          style={{ width: 50, height: 50 }}
                        />
                        <div className="mx-2">
                          {" "}
                          <SHTText
                            size="p"
                            color="white"
                            text={stat.player_name}
                          />
                        </div>
                      </th>

                      <th className="py-4 px-3 "></th>

                      <th className="py-4 px-3">
                      
                          <span style={{ marginLeft: 20 }}>
                            <SHTButton
                              type="submit"
                              name="Send Contract"
                              color="stats_primary"
                              loading={playerLoadingStates[stat.id]}
                              handleClick={() =>
                                handleShowCreateContractModal(stat.id)
                              }
                            />
                          </span>
                    
                      </th>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className="d-flex justify-content-center py-4 load_more">
              <SHTText size="p" color="blue" text="LOAD MORE" />
            </div>
          </div>
        </>
      ) : (
        <SHTNoResult loading={contractLoading} />
      )}
    </div>
  );

  return (
    <SHTSpinner loading={loading}>
      <section>
        <RoasterHeader bannerImage={teamInfoImage} bannerColor={teamColor} />
        <RoasterFilter
          page="contracts"
          handleNavigate={(page: string) => handleNavigate(page)}
        />

        <Container>
          <div>
            <div className="mb-5 ">
              <div className="py-4">
                <SHTLineHeader
                  headerText="ALL CONTRACTS"
                  lineColor="white"
                  lineWidth="short"
                />
              </div>
              {teamContracts?.length > 0 ? (
                <>
                  <div className="stats_table_container">
                    <div className=" overflow-auto">
                      <table>
                        <thead className="title_head">
                          <tr>
                            <th className="py-4 px-3 title_head">
                              <SHTText
                                size="p"
                                color="white"
                                text="Player Name"
                              />
                            </th>
                            <th className="py-4 px-3 title_head">
                              <SHTText size="p" color="white" text="Status" />
                            </th>
                            <th className="py-4 px-3 title_head">
                              <SHTText
                                size="p"
                                color="white"
                                text="Date sent"
                              />
                            </th>
                            <th className="py-4 px-3 title_head">
                              <SHTText size="p" color="white" text="Action" />
                            </th>
                          </tr>
                        </thead>

                        {teamContracts?.map((stat: any) => {
                          return (
                            <tr className="table_details">
                              <th className="py-4 px-3 d-flex align-items-center">
                                <img
                                  src={formatImage(stat.player.player_image)}
                                  alt="Player Image"
                                  style={{ width: 50, height: 50 }}
                                />
                                <div className="mx-2">
                                  {" "}
                                  <SHTText
                                    size="p"
                                    color="white"
                                    text={stat.player.player_name}
                                  />
                                </div>
                              </th>

                              <th className="py-4 px-3">
                                <span
                                  style={{ marginTop: 8 }}
                                  className={`${
                                    stat.contract_status === "APPROVED"
                                      ? "text-bg-primary"
                                      : stat.contract_status === "PENDING"
                                      ? "text-bg-warning"
                                      : "text-bg-danger"
                                  } badge rounded-pill`}
                                >
                                  {stat.contract_status}
                                </span>
                              </th>

                              <th className="py-4 px-3">
                                <SHTText
                                  size="p"
                                  color="white"
                                  text={formatDate(stat.created_at)}
                                />
                              </th>

                              <th className="py-4 px-3">
                                {stat.contract_status === "APPROVED" && (
                                  <span style={{ marginLeft: 20 }}>
                                    <SHTButton
                                      type="submit"
                                      name="Terminate"
                                      color="stats_primary"
                                      loading={playerLoadingStates[stat.id]}
                                      handleClick={() =>
                                        handleCloseContract(
                                          stat.team.id,
                                          stat.player.id,
                                          stat.id
                                        )
                                      }
                                    />
                                  </span>
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      </table>
                    </div>

                    <div className="d-flex justify-content-center py-4 load_more">
                      <SHTText size="p" color="blue" text="LOAD MORE" />
                    </div>
                  </div>
                </>
              ) : (
                <SHTNoResult loading={contractLoading} />
              )}
            </div>

            <div className="py-4">
              <SHTLineHeader
                headerText="SEND A CONTRACTS"
                lineColor="white"
                lineWidth="short"
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <div>
                <SHTButton
                  type="submit"
                  name="Find Players"
                  color="stats_primary"
                  handleClick={() => handleFindPlayer()}
                />
              </div>

              <div>
                {findPlayerLoading && (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ width: "20px", height: "20px" }}
                  />
                )}
              </div>
            </div>

            <div className="py-4">{renderPlayers(players, "contract")}</div>

           
          </div>
        </Container>
      </section>
    </SHTSpinner>
  );
};
const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamContract);
