import React, { FC, useState } from "react";
import Card from "react-bootstrap/Card";
import SHTText from "./SHTText";

import "../assets/styles/page/sh_team_card_style.css";
import SHTButton from "./SHTButton";

interface CardInputFieldProps {
  image?: string;
  name?: any;
  handleOnClick?: any;
}
const SHTTeamCard: FC<CardInputFieldProps> = ({
  image,
  name,
  handleOnClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      className="sh_background_dark"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card.Body className="sh_background_dark ">
        <div className="sh_team_card">
          <div
            className={`${
              isHovered ? "league_card_upper_hover" : "league_card_upper"
            }`}
          ></div>
          <div className="d-flex align-items-center p-5">
            <img src={image} alt="team Name" className="team_image_teams" />

            <SHTButton
              name={name}
              type="submit"
              color="empty"
              handleClick={handleOnClick}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SHTTeamCard;
