import moment from "moment";

import ProfileImage from "../../assets/images/svg/placeholder.jpeg";

export const formatDate = (date: any) => {
  return moment(date).format("MMM DD, YYYY");
};

export function formatTime(time: any) {
  const [hourString, minute] = time.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}

export function truncateCharacters(str: any, maxLength: number) {
  if (str?.length <= maxLength) {
    return str;
  }

  return str?.substring(0, maxLength) + "...";
}

export function convertToAge(date: any) {
  if (date) {
    const birthdate: any = new Date(date);

    const currentDate = Date.now();

    const ageInMilliseconds = currentDate - birthdate;
    const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); //
    const age = Math.floor(ageInYears);

    return age;
  }
  return null;
}

export const getSeason = (year: any) => {
  if (year) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return currentYear - year;
  }

  return null;
};

export const formatStat = (stat: any) => {
  return stat ? stat : 0;
};

export const formatImage = (image: any) => {
  return image ? image : ProfileImage;
};

export const handleGetWinner = (obj: any, id: any) => {
  if (obj === null) {
    return "D";
  } else {
    return obj === id ? "W" : "L";
  }
};
