import React, { useState } from "react";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/team_registration_style.css";
import SHTButton from "../../components/SHTButton";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import TeamInformation from "./TeamInformation";
import Logo from "../../assets/images/showtime_logo.png";
import checkMark from "../../assets/images/svg/checkMark.svg";
import { MdOutlineArrowBackIos } from "react-icons/md";
import TeamManagement from "./TeamManagement";
import PlayerArrangement from "./PlayerArrangement";
import SponsoeshipInformation from "./SponsorshipInformation";
import TeamDetails from "./TeamDetails";
import LegalAgreement from "./LegalAgreement";
import CompletionReceived from "./CompletionReceived";
import { Container } from "react-bootstrap";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import TeamSocialsReg from "./TeamSocialsReg";
import toast, { Toaster } from "react-hot-toast";
import axios from "../../utils/axios";

const TeamRegistration = ({ teamRegistration, teamImageSubmit }: any) => {
  const [page, setPage] = useState("1");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setData]: any = useState({});
  const [loading, setLoading] = useState(false);

  const handleNextClick = (data: any) => {
    const currentPageIndex = parseInt(page);
    const nextPageIndex = currentPageIndex + 1;
    if (nextPageIndex <= steps.length) {
      setPage(`${nextPageIndex}`);
    }
    handleNextForm();
    //___________
    console.log(data);
    setData((prevData: any) => ({ ...prevData, ...data }));
  };

  const handlePreviousClick = () => {
    const currentPageIndex = parseInt(page);
    const previousPageIndex = currentPageIndex - 1;
    if (previousPageIndex >= 1) {
      setPage(`${previousPageIndex}`);
    }
  };

  const handlePrevForm = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    handlePreviousClick();
  };

  const steps = [
    {
      idx: 1,
      tag: "Team Information",
    },
    {
      idx: 2,
      tag: "Team Socials",
    },
    {
      idx: 3,
      tag: "Team Management",
    },
    {
      idx: 4,
      tag: "Players arrangement",
    },
    {
      idx: 5,
      tag: "Sponsorship Information",
    },
    {
      idx: 6,
      tag: "Team Details",
    },
    {
      idx: 7,
      tag: "Legal Agreements",
    },
  ];

  // const handleSubmitTeamImage = async (image: any) => {
  //   await teamImageSubmit(formData.team_image)
  //     .then((response: RequestResponseInterface) => {
  //       if (response.status === 201) {
  //       } else {
  //         toast.error("unable to save team image");
  //       }
  //     })
  //     .catch((err: any) => {
  //       toast.error("unable to save team image");
  //     });
  // };

  const handleSubmitTeamImage = async () => {
    try {
      const response = await axios.put("/teams/create-teams/", formData.team_image);
      if (response.status === 201) {
       
      } else {
      //  toast.error("unable to save team image");
      }
    } catch (error) {
     // toast.error("unable to save team image");
    } 
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    console.log(formData);

    handleSubmitTeamImage();

    delete formData.team_image;

    await teamRegistration(formData)
      .then((response: RequestResponseInterface) => {
        if (response.status === 201) {
          setLoading(false);
          const currentPageIndex = parseInt(page);
          const nextPageIndex = currentPageIndex + 1;
          if (nextPageIndex <= steps.length) {
            setPage(`${nextPageIndex}`);
          }

          handleNextForm();
        } else {
          toast.error("Something went wrong!");

          setLoading(false);
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong!");

        setLoading(false);
      });
  };
  const formArray = [
    <TeamInformation handleSubmitData={handleNextClick} />,
    <TeamSocialsReg handleSubmitData={handleNextClick} />,
    <TeamManagement handleSubmitData={handleNextClick} />,
    <PlayerArrangement handleSubmitData={handleNextClick} />,
    <SponsoeshipInformation handleSubmitData={handleNextClick} />,
    <TeamDetails handleSubmitData={handleNextClick} />,
    <LegalAgreement handleSubmitData={handleSubmitForm} loading={loading} />,
    <CompletionReceived />,
  ];

  const handleNextForm = () => {
    if (currentIndex < formArray.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <section className="team_registration_container">
      <Toaster />
      <Container>
        <main className="team_registration_card">
          <main className="  counter_container">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                style={{ width: 100, height: 60, marginBottom: 10 }}
                alt="Logo"
              />
            </div>
            <div className="">
              <SHTText size="h3" text="New Team Registration" color="blue" />
              <SHTText
                size="cardP"
                text="Join the Excitement: Register Your New Team for the Showtime! Ready to dive into the action? "
                color="gray"
              />
            </div>
            <div className="step_counter">
              {steps.map((step: any, idx: number) => {
                return (
                  <section className="d-flex items-center" key={idx}>
                    <div className={`team_registration_index`}>
                      <div
                        className={`${
                          page >= step.idx.toString()
                            ? "register_idx_active"
                            : "register_idx"
                        }`}
                      >
                        {page > step.idx.toString() ? (
                          <div className="pb-1 check_active">
                            <img src={checkMark} alt="Checkmark" width="10px" />
                          </div>
                        ) : (
                          <SHTText
                            size="p"
                            color={`${
                              page > step.idx.toString() ? "blue" : "gray"
                            }`}
                            text={step.idx}
                          />
                        )}
                      </div>

                      {idx !== steps.length - 1 && (
                        <div
                          className={`${
                            page >= step.idx.toString()
                              ? "team_registration_border_bottom_active"
                              : "team_registration_border_bottom"
                          }`}
                        ></div>
                      )}
                    </div>
                    <div className="mx-4 mt-3">
                      <SHTText
                        size="p"
                        text={step.tag}
                        color={`${
                          page >= step.idx.toString() ? "blue" : "gray"
                        }`}
                      />
                    </div>
                  </section>
                );
              })}
            </div>
          </main>
          <main className="form_container">
            <section className="">{formArray[currentIndex]}</section>
            <section className="form_button_container">
              {currentIndex < formArray.length - 1 && (
                <div className="form_button">
                  <div
                    className="d-flex align-items-center return_text"
                    onClick={handlePrevForm}
                  >
                    {currentIndex > 0 && (
                      <>
                        <MdOutlineArrowBackIos color="blue" size={20} />
                        <div className="mx-3">
                          <SHTText text="Go back" color="blue" size="p" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </section>
          </main>
        </main>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamRegistration);
