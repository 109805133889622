import React from "react";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import "../../assets/styles/page/first_form_style.css";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import {
  EMAIL_INPUT_VALIDATION,
  PHONE_NUMBER_INPUT_VALIDATION,
} from "../../utils/validation";

interface FormInputs {
  player_name: string;
  player_email: string;
  phone_number: string;
  home_address: string;
}
const FirstForm = ({ handleSubmitData }: any) => {
  let registerInput: FormInputs = {
    player_name: "",
    player_email: "",
    phone_number: "",
    home_address: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  return (
    <section className="first_form_container">
      <div className="pb-4">
        <SHTText size="h3" text="Player Information" color="gray" />
      </div>

      <div className="form_element">
        <Controller
          control={control}
          name="player_email"
          rules={EMAIL_INPUT_VALIDATION}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Email"
              placeholder=""
              type="messageText"
              value={value}
              // editable={loading}
              onChangeText={onChange}
              error={errors.player_email && errors.player_email.message}
            />
          )}
        />
      </div>
      <div className="form_element">
        <Controller
          control={control}
          name="player_name"
          rules={{
            required: "Name is needed",
          }}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Name"
              placeholder=""
              type="messageText"
              value={value}
              onChangeText={onChange}
              error={errors.player_name && errors.player_name.message}
            />
          )}
        />
      </div>
      <div className="form_element">
        <Controller
          control={control}
          name="phone_number"
          rules={PHONE_NUMBER_INPUT_VALIDATION}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Phone Number"
              placeholder=""
              type="messageText"
              value={value}
              onChangeText={onChange}
              error={errors.phone_number && errors.phone_number.message}
            />
          )}
        />
      </div>
      <div className="form_element">
        <Controller
          control={control}
          name="home_address"
          rules={{
            required: "Address is needed",
          }}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Home Address"
              placeholder=""
              type="messageText"
              value={value}
              onChangeText={onChange}
              error={errors.home_address && errors.home_address.message}
            />
          )}
        />
      </div>

      <div className="team_next">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(handleSubmitData)}
        />
      </div>
    </section>
  );
};

export default FirstForm;
