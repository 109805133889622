import React from "react";
import { Container } from "react-bootstrap";
import GameBackground from "../../assets/images/svg/gameBanner.svg";
import "../../assets/styles/page/game_details_style.css";
import GameFilter from "./GameFilter";
import GameCardDetails from "./GameCardDetails";
import GameFirstTable from "./GameFirstTable";
import GameSecondTable from "./GameSecondTable";

interface propsInterface {
  gameDetails: any;
  gameFirstTableDetails: any;
  passingDetail: any;
  receivingDetails: any;
  defenseDetail: any;
  rushDetail: any;
  teamData: any;
  highlights: any;
  highlightsLoading: any;
  handleNavigate: any;
  gameInfo: any;
  handleSelectTeam?: any;
  handleChange: any;
  open: any;
  handleOpenDetails: any;
  handleNavigateToHighlights: any;
  touchdown: any
  interception: any
  turnover: any
  loading: any
  dropdownTouchdown:any
  dropdownInterception:any
  dropdownTurnover:any
  detailsLoading:any
}

const GameDetails = (props: propsInterface) => {
  const {
    gameDetails,
    gameFirstTableDetails,
    passingDetail,
    receivingDetails,
    defenseDetail,
    rushDetail,
    teamData,
    highlights,
    highlightsLoading,
    handleNavigate,
    gameInfo,
    handleSelectTeam,
    handleChange,
    open,
    handleOpenDetails,
    handleNavigateToHighlights,
    touchdown,
    interception,
    turnover,
    loading,
    dropdownTouchdown,
    dropdownInterception,
    dropdownTurnover,
    detailsLoading
  } = props;

  return (
    <Container>
      <section className="row sht_container_space d-flex justify-content-between">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img
            src={gameInfo?.fixture_image}
            alt="Game Banner"
            className="game_backround_image"
          />
          <GameFilter
            handleChange={handleChange}
            handleNavigateToHighlights={handleNavigateToHighlights}
          />

          <GameCardDetails
            dropdownDetailsTouchdown={dropdownTouchdown}
            dropdownDetailsTurnover={dropdownTurnover}
            dropdownDetailsInnterception={dropdownInterception}
            loading={loading}
            touchdown={touchdown}
            interception={interception}
            turnover={turnover}
            open={open}
            handleOpenDetails={handleOpenDetails}
          />
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12 game_table_container">
          <GameFirstTable gameDetail={gameFirstTableDetails} />
          <GameSecondTable
            detailsLoading={detailsLoading}
            gameInfo={gameInfo}
            handleNavigate={handleNavigate}
            highlights={highlights}
            highlightsLoading={highlightsLoading}
            teamDataStats={teamData}
            passingDetail={passingDetail}
            receivingDetails={receivingDetails}
            defenseDetail={defenseDetail}
            rushDetails={rushDetail}
            handleButtonClick={(team_id: string) => handleSelectTeam(team_id)}
          />
        </div>
      </section>
    </Container>
  );
};

export default GameDetails;
