import React from "react";
import SHTRadio from "../../components/SHTRadio";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import SHTNoResult from "../../components/SHTNoResult";

interface FormInputs {
  team_id: any;
}
const ThirdForm = ({ handleSubmitData, team, loading }: any) => {
  let registerInput: FormInputs = {
    team_id: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const onSubmit = (data: FormInputs) => {
    // Call the external submit handler
    handleSubmitData(data);
  };
  return (
    <section className="first_form_container">
     
        <div className="pb-4">
          <SHTText size="h3" text="Player Information" color="gray" />
        </div>
        <form >
        {!loading ? (
          <SHTRadio
            name="team"
            options={team}
            control={control}
            onRadioChange={(value) => setValue("team_id", value)}
            legend=<p className="radio_text">If yes, What team?</p>
          />
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </form>
      <div className="team_next">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};
export default ThirdForm;
