import React, { useState } from "react";
import "../../assets/styles/page/register_player_style.css";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import SHTText from "../../components/SHTText";
import checkMark from "../../assets/images/svg/checkMark.svg";
import { MdOutlineArrowBackIos } from "react-icons/md";
import FirstForm from "./FirstForm";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";
import FourthForm from "./FourthForm";
import FifthForm from "./FifthForm";
import CompletedForm from "./CompletedForm";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

import {
  RequestResponseInterface,
  teamResponse,
} from "../../interfaces/axios.dto";
interface TeamsProps {
  fetchTeams: Function;
  gamePosition: Function;
  playerRegistration: Function;
}

const RegisterTeamPage = ({
  fetchTeams,
  playerRegistration,
  gamePosition,
}: TeamsProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [teamLoading, setTeamLoading] = useState(false);
  const [positionLoading, setPositionLoading] = useState(false);
  const [page, setPage] = useState("1");
  const [team, setTeam] = useState([]);
  const [position, setPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setData]: any = useState({});

  const handleTeamFetch = async () => {
    setTeamLoading(true);
    await fetchTeams()
      .then((response: teamResponse) => {
        setTeamLoading(false);
        if (response.status === 200) {
          const teams = response.data.results;
          const options = teams.map((team: any) => ({
            label: team.name,
            value: team.id,
          }));
          setTeam(options);
          setTeamLoading(false);
        } else {
          setTeamLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamLoading(false);
      });
  };
  const handleFetchTeamPosition = async () => {
    setPositionLoading(true);
    await gamePosition()
      .then((response: teamResponse) => {
        setPositionLoading(false);
        if (response.status === 200) {
          const positions = response.data.results;
          const options = positions.map((team: any) => ({
            label: team.name,
            value: team.id,
          }));
          setPosition(options);
          setPositionLoading(false);
        } else {
          setPositionLoading(false);
        }
      })
      .catch((err: any) => {
        setPositionLoading(false);
      });
  };

  const handleSubmitForm = async (data: any) => {
    // await setData((prevData: any) => ({ ...prevData, ...data }));
    const payload = { ...formData, ...data };

    setLoading(true);
    await playerRegistration(payload)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setLoading(false);
          const currentPageIndex = parseInt(page);
          const nextPageIndex = currentPageIndex + 1;
          if (nextPageIndex <= steps.length) {
            setPage(`${nextPageIndex} `);
          }
          handleNextForm();
        } else {
          toast.error("Something went wrong!");

          setLoading(false);
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong!");

        setLoading(false);
      });
  };
  const steps = [
    {
      idx: 1,
    },
    {
      idx: 2,
    },
    {
      idx: 3,
    },
    {
      idx: 4,
    },
    {
      idx: 5,
    },
  ];

  const handleNextClick = (data: any) => {
    const currentPageIndex = parseInt(page);
    const nextPageIndex = currentPageIndex + 1;
    if (nextPageIndex <= steps.length) {
      setPage(`${nextPageIndex}`);
    }
    handleNextForm();
    setData((prevData: any) => ({ ...prevData, ...data }));
  };
  const handleNextForm = () => {
    if (currentIndex < formArray.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    const currentPageIndex = parseInt(page);
    const previousPageIndex = currentPageIndex - 1;
    if (previousPageIndex >= 1) {
      setPage(`${previousPageIndex}`);
    }
  };

  const handleNextThirdForm = (data: any) => {
    handleNextClick(data);
    handleTeamFetch();
  };
  const handleNextFourthForm = (data: any) => {
    handleNextClick(data);
    handleFetchTeamPosition();
  };

  const handlePrevForm = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    handlePreviousClick();
  };
  const formArray = [
    <FirstForm handleSubmitData={handleNextClick} />,
    <SecondForm handleSubmitData={handleNextThirdForm} />,
    <ThirdForm
      handleSubmitData={handleNextFourthForm}
      team={team}
      loading={teamLoading}
    />,
    <FourthForm
      handleSubmitData={handleNextClick}
      position={position}
      loading={positionLoading}
    />,
    <FifthForm handleSubmitData={handleSubmitForm} loading={loading} />,
    <CompletedForm />,
  ];
  return (
    <section className="register_team_container">
      <Toaster />
      <Container>
        <section className="register_form_container">
          <main className="register_form_left_bar">
            <div className="d-flex justify-content-center">
              <img
                src={Logo}
                style={{ width: 100, height: 60, marginBottom: 10 }}
                alt="Logo"
              />
            </div>
            <div className="d-flex justify-content-center text-center">
              <SHTText
                size="h1"
                text="SHOWTIME PLAYER REGISTRATION"
                color="white"
              />
            </div>
          </main>
          <main className="register_form_right_bar ">
            <section className="items-align-center justify-content-center d-flex mt-4">
              {steps.map((step: any, idx: number) => {
                return (
                  <section className=" " key={idx}>
                    <div
                      className={` d-flex align-items-center justify-content-center `}
                    >
                      <div
                        className={` ${
                          page >= step.idx.toString()
                            ? "register_idx_active d-flex"
                            : "register_idx"
                        } `}
                      >
                        {page > step.idx.toString() ? (
                          <div className="pb-1 check_active">
                            <img src={checkMark} alt="Checkmark" width="10px" />
                          </div>
                        ) : (
                          <SHTText
                            size="p"
                            color={`${
                              page > step.idx.toString() ? "blue" : "gray"
                            }`}
                            text={step.idx}
                          />
                        )}
                      </div>
                      <div>
                        {idx !== steps.length - 1 && (
                          <div
                            className={`${
                              page >= step.idx.toString()
                                ? "player_registration_border_bottom_active"
                                : "player_registration_border_bottom"
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  </section>
                );
              })}
            </section>
            <main className=" team_form_container">
              <section className="mb-5">{formArray[currentIndex]}</section>
              <section className="team_form_button d-flex align-items-center justify-content-between">
                {currentIndex < formArray.length - 1 && (
                  <>
                    <div>
                      {currentIndex > 0 && (
                        <div
                          className="d-flex align-items-center pointer px-5"
                          onClick={handlePrevForm}
                        >
                          <MdOutlineArrowBackIos color="blue" size={20} />

                          <SHTText text="Go back" color="blue" size="p" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </section>
            </main>
          </main>
        </section>
      </Container>
    </section>
  );
};

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(RegisterTeamPage);
