import React, { useState, useEffect } from "react";
import TeamFilter from "./TeamFilter";
import TeamDetails from "./TeamDetails";
import TeamHeader from "./TeamHeader";
import SHTSpinner from "../../components/SHTSpinner";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { teamResponse } from "../../interfaces/axios.dto";
import ScoreFilter from "../score/ScoreFilter";

interface TeamsProps {
  fetchTeams: Function;
}

const Teams = ({ fetchTeams }: TeamsProps) => {
  const [headerType, setHeaderType] = useState("");
  const [teamDetails, setTeamDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleTeamFetch(null);
  }, []);

  const handleTeamFetch = async (filter: string | null) => {
    setTeamDetails([]);
    setLoading(true);
    await fetchTeams(filter)
      .then((response: teamResponse) => {
        if (response.status === 200) {
          setTeamDetails(response.data.results);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const handleNavigate = async (type: string) => {
    await setHeaderType(type);
    navigate(`/${type}`);
  };

  const handleNavigateTeamProfile = (id: string, name: string) => {
    navigate(`/team-info/${id}`);
  };

  return (
    <SHTSpinner loading={false}>
      <section className="sh_background_dark">
        <TeamHeader
          handleNavigate={(type: string) => handleNavigate(type)}
          type={headerType}
        />
        <ScoreFilter
           subTitle="SHOWTIME TEAMS"
           title={"TEAMS"}
          handleFilter={(filter: string) => handleTeamFetch(filter)}
        />
        <TeamDetails
          cardDetails={teamDetails}
          actionName="VIEW PROFILE"
          loading={loading}
          handleClick={(id: string, name: string) =>
            handleNavigateTeamProfile(id, name)
          }
        />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(Teams);
