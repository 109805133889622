import React from "react";
import "../../assets/styles/page/about_featured_style.css";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";

type cardProps = {
  image: string;
  head: string;
  title: string;
};

interface cardInterface {
  trophyDetails: cardProps[];
  newsReducer?: any;
}

const FeatureCard = ({ trophyDetails, newsReducer }: cardInterface) => {
  return (
    <section
      className={
        newsReducer.mode == "light"
          ? "about_feature_body sh_next_match_background_light"
          : "about_feature_body "
      }
    >
      <Container>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="w-50 my-3 ">
              <SHTText size="h2" text="CHAMPION’S FEATURES" color="white" />
            </div>
            <div className="my-3">
              <SHTText
                size="p"
                color="gray"
                text="Division One of the Showtime Flag Football League represents the pinnacle of competition within theleague. "
              />
            </div>
            <div className="my-3">
              <SHTText
                size="p"
                color="gray"
                text="The division features of the most skilled and accomplished players, showcasing their exceptional talents on the field. "
              />
            </div>
            <div className="my-3">
              <SHTText
                size="p"
                color="gray"
                text="The division features of the most skilled and accomplished players, showcasing their exceptional talents on the field. "
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 m-auto">
            <div className="d-flex flex-wrap justify-content-center">
              {trophyDetails.map((detail: cardProps) => {
                return (
                  <div
                    className={
                      newsReducer.mode == "light"
                        ? "about_featured_medals sh_background_light"
                        : "about_featured_medals "
                    }
                  >
                    <div className="d-flex justify-content-center my-2">
                      {" "}
                      <img
                        src={detail.image}
                        alt="American Football cup"
                        width="50px"
                      />
                    </div>
                    <SHTText size="h5" text={detail.head} color="white" />
                    <SHTText size="p" text={detail.title} color="gray" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(FeatureCard);
