import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../assets/styles/page/game_table_style.css";
import SHTGameStatsCard from "../../components/SHTGameStatsCard";
import Highlights from "../score/Highlights";
import SHTText from "../../components/SHTText";
interface propsInterface {
  passingDetail: any;
  receivingDetails: any;
  defenseDetail: any;
  rushDetails: any;
  teamDataStats: any;
  highlights: any;
  highlightsLoading: any;
  handleNavigate: any;
  gameInfo: any;
  handleButtonClick?: any;
  detailsLoading: any;
}

const GameSecondTable = (props: propsInterface) => {
  const {
    passingDetail,
    receivingDetails,
    defenseDetail,
    rushDetails,
    teamDataStats,
    highlights,
    highlightsLoading,
    handleNavigate,
    gameInfo,
    handleButtonClick,
    detailsLoading,
  } = props;
  const [activeTab, setActiveTab] = useState("Stats");
  const handleTab = (type: any) => {
    setActiveTab(type);
  };
  return (
    <section>
      <main className="my-3">
        <section className="my-5 d-flex justify-content-between game_table_filter">
          <div
            className={`${
              activeTab === "Stats"
                ? "game_switch_text_active "
                : "game_switch_text"
            } cursor-pointer`}
            onClick={() => handleTab("Stats")}
          >
            <SHTText
              text="Stats"
              size="h6"
              color={activeTab === "Stats" ? "blue" : "gray"}
            />
          </div>
          <div
            className={`${
              activeTab === "Highlight"
                ? "game_switch_text_active"
                : "game_switch_text"
            } cursor-pointer`}
            onClick={() => handleTab("Highlight")}
          >
            <SHTText
              text="Highlights"
              size="h6"
              color={activeTab === "Highlight" ? "blue" : "gray"}
            />
          </div>
        </section>
        <section className="">
          {activeTab === "Stats" && (
            <div className="sh_background_dark m-4">
              <SHTGameStatsCard
                handleButtonClick={(team_id: string) =>
                  handleButtonClick(team_id)
                }
                detailsLoading={detailsLoading}
                gameInfo={gameInfo}
                teamDetail={teamDataStats}
                PassingDetails={passingDetail}
                ReceivingDetails={receivingDetails}
                DefenseDetails={defenseDetail}
                RushindDetails={rushDetails}
              />
            </div>
          )}
          {activeTab === "Highlight" && (
            <Highlights
              highlights={highlights}
              loading={highlightsLoading}
              handleOnClick={handleNavigate}
            />
          )}
        </section>
      </main>
    </section>
  );
};

export default GameSecondTable;
