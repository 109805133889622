import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import "../assets/styles/components/sht_profile_style.css";
import { RequestResponseInterface } from "../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import authReducer from "../store/reducer/auth_reducer";

const ProfileDropDown = ({ logout, newsReducer }: any) => {
  const navigate = useNavigate();
  const user = Cookies.get("user");
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleProfileClick = () => {
    if (newsReducer.user.user_data.status === "Player") {
      navigate(
        `/players/roaster/${newsReducer.user._id}/${newsReducer.user.team_id}`
      );
      return;
    }

    if (newsReducer.user.user_data.status === "Team") {
      navigate(`/team-info/${newsReducer.user._id}`);
      return;
    }
  };

  const handleScheduleClick = () => {
    navigate(`/teams/schedule/${newsReducer.user.team_id}`);
  };

  const handleLogoutClick = async () => {
    Cookies.set("auth_token", "");
    Cookies.set("user", "");
    navigate("/");
    setLoading(true);
    await logout()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          navigate("/");
          Cookies.set("auth_token", "");
          Cookies.set("user", "");
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <div className="user-icon-container">
      <div className="user-icon" onClick={toggleDropdown}>
        <FaUserCircle size={24} color="white" />
      </div>
      {isOpen && (
        <div className="dropdown-content">
          <button onClick={handleProfileClick}>View Profile</button>
          {/* <button onClick={handleScheduleClick}>Check Schedule</button> */}
          <hr></hr>
          <button onClick={handleLogoutClick}>Logout</button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(ProfileDropDown);
