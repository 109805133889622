import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import SHTInput from "./SHTInput";
import SHTButton from "./SHTButton";
import "../assets/styles/page/team_information_style.css";
import SHTText from "./SHTText";
import SHTFileUpload from "./SHTFileUpload"; // Assuming you have a custom file upload component

type FormInputs = {
  start_date: string;
  end_date: string;
  salary: string;
  uniform_number: string;
  contract_document: File | null; // Adding a new field for the document upload
};

const SHTCreateContractModal = ({ show, handleClose, handleSubmitData }: any) => {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormInputs>();

  const startDate = watch("start_date");
  const endDate = watch("end_date");

  const formatDate = (date: string) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const onSubmit = (data: FormInputs) => {
    // Check if start date is earlier than end date
    if (new Date(data.start_date) >= new Date(data.end_date)) {
      alert("Start date must be earlier than end date");
      return;
    }

    // Format dates to YYYY-MM-DD
    const formattedData = {
      ...data,
      start_date: formatDate(data.start_date),
      end_date: formatDate(data.end_date),
    };

    handleSubmitData(formattedData);
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length > 0) {
      setValue("contract_document", files[0]);
      setUploadedFileName(files[0].name);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <SHTText size="h5" text="Create Contract" color="gray" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form_element">
            <Controller
              control={control}
              name="start_date"
              rules={{ required: "Start Date is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Start Date"
                  placeholder="YYYY-MM-DD"
                  type="date"
                  value={value}
                  onChangeText={onChange}
                  error={errors.start_date && errors.start_date.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="end_date"
              rules={{
                required: "End Date is needed",
                validate: (value) =>
                  new Date(value) > new Date(startDate) ||
                  "End date must be later than start date",
              }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="End Date"
                  placeholder="YYYY-MM-DD"
                  type="date"
                  value={value}
                  onChangeText={onChange}
                  error={errors.end_date && errors.end_date.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="salary"
              rules={{ required: "Salary is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Salary"
                  placeholder="Enter salary"
                  type="number"
                  value={value}
                  onChangeText={onChange}
                  error={errors.salary && errors.salary.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="uniform_number"
              rules={{ required: "Uniform Number is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Uniform Number"
                  placeholder="Enter uniform number"
                  type="number"
                  value={value}
                  onChangeText={onChange}
                  error={errors.uniform_number && errors.uniform_number.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <SHTText text="Upload Contract Document" size="h6" color="gray" />
            <SHTFileUpload
              onDrop={(files) => handleFileUpload(files)}
              accept="application/pdf" // Accept only PDF files for the contract
            >
              <div className="upload_container">
                <SHTText text="Click to upload contract" size="p" color="gray" />
              </div>
            </SHTFileUpload>
            {uploadedFileName && (
              <div className="uploaded_file_info">
                <SHTText text={`Uploaded file: ${uploadedFileName}`} size="p" color="gray" />
              </div>
            )}
            {errors.contract_document && (
              <p className="text-danger">{errors.contract_document.message}</p>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <SHTButton
          type="submit"
          name="Save Changes"
          handleClick={handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SHTCreateContractModal;
