import React, { useState } from "react";
import "../../assets/styles/page/team_hero_style.css";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import Player1 from "../../assets/images/svg/bestTeam1.png";

import SHTButton from "../../components/SHTButton";
type CardProps = {
  handleFetchTeamOfTheWeek: Function;
};

interface PropsInterface {
  type: string;
}

const TeamHero = ({
  handleFetchTeamOfTheWeek,
  type,
}: CardProps & PropsInterface) => {
  const [active, setActive] = useState(true);

  const handleClick = (type: string) => {
    if (type === "OFFENSE") {
      setActive(true);
    }
    if (type === "DEFENSE") {
      setActive(false);
    }
    handleFetchTeamOfTheWeek(type);
  };
  return (
    <section className="sh_background_dark team_hero_container">
      <Container className="d-flex justify-content-between align-items-center">
        <div className="hero_banner">
          <SHTText size="h2" text="TEAM OF THE WEEK" color="white" />
          <SHTText
            size="p"
            text="Grab your tickets and join us every sunday to experience exciting games and watch wonderful skilled players while they entertain us in the world of flag football."
            color="gray"
          />
          <div className="cta">
            <div className="cta_button">
              <SHTButton
                type="submit"
                name="OFFENSE"
                color={active ? "primary" : "dark"}
                className="px-5"
                handleClick={() => handleClick("OFFENSE")}
              />
            </div>
            <div className="cta_button2">
              <SHTButton
                type="submit"
                name="DEFENSE"
                color={active ? "dark" : "primary"}
                className="px-5 "
                handleClick={() => handleClick("DEFENSE")}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <img
              src={Player1}
              alt="First Player on the banner"
              className="team_of_week_image"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

TeamHero.defaultProps = {
  type: "defense",
};

export default TeamHero;
