import React, { useState } from "react";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/stats_header_style.css";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
type CardProps = {
  handleFetchStatsCategory: Function;
};

interface PropsInterface {
  type: string;
}
const StatsHeader = ({
  handleFetchStatsCategory,
  type,
}: CardProps & PropsInterface) => {
  const [active, setActive] = useState(true);
  const handleClick = (type: string) => {

    if(type==="player"){
        setActive(true)
    }
    if(type==="team"){
        setActive(false)
    }
    handleFetchStatsCategory(type);

  };
  return (
    <section className="stats_header">
      <Container className="d-flex align-items-center h-100 py-5 ">
        <div>
          <SHTText size="h1" text="STATS" color="white" />
          <SHTText size="h5" text="SHOWTIME STATS" color="gray" />
          <div className="d-flex my-3">
            <SHTButton
              name="Player stats"
              color={active ? "primary" : "dark"}
              type="submit"
              handleClick={() => handleClick("player")}
            />
            <div className="mx-3">
              <SHTButton
                name="Team stats"
                color={active ? "dark" : "primary"}
                type="submit"
                handleClick={() => handleClick("team")}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default StatsHeader;
