const initState: any = {
  news: [],
  user: [],
  divisions: [],
  mode: "",
};

const newsReducer = (state = initState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_DIVISION":
      return {
        ...state,
        divisions: payload,
      };
    case "MODE":
      return {
        ...state,
        mode: payload,
      };
    case "USER":
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
};

export default newsReducer;
