import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import "../../assets/styles/page/sht_nextmatch_style.css";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
import SHTNoResult from "../../components/SHTNoResult";

type cardProps = {
  division: string;
  date: string;
  time: string;
  address: string;
  team: any;
  homeTeamImage: string;
  awayTeamImage: string;
};
interface cardInterface {
  nextMatchObject: cardProps[];
  handleNavigateToFixtures: Function;
  loading: any;
  newsReducer?: any;
}

const NextMatch = ({
  nextMatchObject,
  handleNavigateToFixtures,
  loading,
  newsReducer,
}: cardInterface) => {
  return (
    <Container>
      <section>
        <main
          className={
            newsReducer.mode == "light"
              ? "sh_nextmatch_body py-5 sh_next_match_background_light"
              : "sh_nextmatch_body py-5 "
          }
        >
          <div className="header_with_line_container mb-3">
            <SHTText
              size="h1"
              color={/* newsReducer.mode == "dark" && */ "white"}
              text="NEXT GAME"
            />
            <hr className="header_with_line"></hr>
          </div>

          {nextMatchObject?.length > 0 ? (
            <div>
              {nextMatchObject.map((data: cardProps, index: number) => (
                <div
                  className="d-flex justify-content-around align-items-center sh_nextmatch_body_card"
                  key={index}
                >
                  <div>
                    <img
                      src={data.homeTeamImage}
                      alt="first team image"
                      className="next_match_team_image"
                    />
                  </div>
                  <div className=" p-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <SHTText
                        size="h5"
                        color="white"
                        text={data.division || "DIVISION ONE"}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <SHTText size="p" color="gray" text={data.date} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <SHTText size="h2" color="white" text={data.time} />
                    </div>
                    <div className="d-flex justify-content-center text-center px-3">
                      <SHTText size="p" color="gray" text={data.address} />
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <SHTButton
                        name="VIEW FIXTURES"
                        handleClick={() => handleNavigateToFixtures()}
                        type="submit"
                        className=""
                      />
                    </div>
                  </div>
                  <div className="justify-content-center mt-2">
                    <img
                      src={data.awayTeamImage}
                      alt="second team image"
                      className="next_match_team_image"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <SHTNoResult loading={loading} />
          )}
        </main>
      </section>
    </Container>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(NextMatch);
