import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import SHTCard from "./SHTCard";
import {
  RequestResponseInterface,
  newsInterface,
} from "../interfaces/axios.dto";
import SHTText from "./SHTText";
import Line from "../assets/images/svg/Line 23.svg";
import "../assets/styles/components/sht_news_section_style.css";
import { Container } from "react-bootstrap";
import SHTButton from "./SHTButton";
import SHTNoResult from "./SHTNoResult";

interface propsInterface {
  fetchlatestNews: Function;
  handleOnClick?: any;
  latestNews?: any;
  handleViewAllNews?: Function;
  loading: boolean;
}
const SHTNewsSection = (props: propsInterface) => {
  const { handleOnClick, latestNews, handleViewAllNews, loading } = props;

  return (
    <Container className="sht_container_space">
      <div className="header_with_line_container">
        <SHTText size="h1" color="white" text="LATEST NEWS" />
        <hr className="header_with_line"></hr>
      </div>

      <div className="sh_news_container">
        {latestNews?.length > 0 ? (
          <>
            {latestNews.map((news: newsInterface) => {
              return (
                <div key={news.id}>
                  <SHTCard
                    handleOnClick={handleOnClick}
                    id={news.id}
                    //@ts-ignore
                    image={news?.featured_image?.image}
                    alt={news?.alt_text}
                    title={news.title}
                    date={news.created_at}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </div>

      <div className="d-flex justify-content-center align-items-center mt-5 ">
        <SHTButton
          name="VIEW ALL NEWS"
          type="submit"
          handleClick={handleViewAllNews}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTNewsSection);
