import React, { useState, useEffect } from "react";

import {
  RequestResponseInterface,
  newsInterface,
} from "../../interfaces/axios.dto";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import SHTSpinner from "../../components/SHTSpinner";
import HighLightHeader from "./HighLightHeader";
import HighLightsComponent from "./HighLightsComponent";

type MyParams = {
  id: string;
};

const HighLights = ({ fetchGamesHighlights }: any) => {
  const { id } = useParams<MyParams>();
  const [highlights, setHighlights]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    handleGameHighlightsFetch();
  }, []);

  const handleGameHighlightsFetch = async () => {
    setLoading(true);

    const params = {
      game__id: id,
    };
    await fetchGamesHighlights(params)
      .then((response: RequestResponseInterface) => {
        setLoading(false);
        if (response.status === 200) {
          setHighlights(response.data.results);
        } else {
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleNavigate = (id: string) => {
    navigate(`/highlightdetails/${id}`);
  };

  return (
    <SHTSpinner loading={false}>
      <HighLightHeader />
      <HighLightsComponent
        highlights={highlights}
        handleOnClick={(id: string) => handleNavigate(id)}
        loading={loading}
      />
    </SHTSpinner>
  );
};
const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(HighLights);
