import React, { FC } from "react";
import { Container } from "react-bootstrap";
import "../assets/styles/components/sht_score_card_style.css";
import SHTText from "./SHTText";
import SHTButton from "./SHTButton";

interface CardInputFieldProps {
  id?: number;
  secondImage?: string;
  firstImage?: string;
  date?: string;
  children?: React.ReactNode;
  time?: string;
  handleOnClick?: any;
  handleViewGameDetails?: any;
  team?: string;
  firstFormation?: string;
  firstScore?: string;
  secondFormation?: string;
  secondScore?: string;
  firstTeam?: string;
  secondTeam: any;
}

const SHTScoreCard: FC<CardInputFieldProps> = ({
  id,
  secondImage,
  firstImage,
  date,
  time,
  handleOnClick,
  handleViewGameDetails,
  firstTeam,
  secondTeam,
  firstFormation,
  firstScore,
  secondFormation,
  secondScore,
}) => {
  return (
    <Container>
      <section className="score_card_container row">
        <div className="col-8 ">
          <div className="p-3">
            <SHTText size="p" text={`${date} ${time}`} color="gray" />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={firstImage} alt="team_1" className="team_image" />
              <SHTText size="h6" text={firstTeam} color="white" />
            </div>
            <div className="d-flex">
              <SHTText size="p" text={firstFormation} color="gray" />
              <div className="px-3">
                <SHTText size="p" text={firstScore} color="white" />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={secondImage} alt="team_2" className="team_image" />
              <SHTText size="h6" text={secondTeam} color="white" />
            </div>
            <div className="d-flex">
              <SHTText size="p" text={secondFormation} color="gray" />
              <div className="px-3">
                <SHTText size="p" text={secondScore} color="white" />
              </div>
            </div>
          </div>
          <div className="score_card_footer">
            <div className="d-flex justify-content-between align-items-center p-3">
              <div className="">
                <SHTText size="p" text="How to watch" color="gray" />
                <div className="d-flex">
                  <SHTText size="light" text="YouTube," color="gray" />
                  <SHTText size="light" text="more" color="blue" />
                </div>
              </div>
              <div onClick={handleViewGameDetails} className="pointer">
                <SHTText size="p" text="Game details ›" color="blue" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4  score_card_button_container">
          <div className="d-flex justify-content-center align-items-center h-100">
            <SHTButton
              type="submit"
              name="Highlight"
              color="highlight"
              handleClick={handleOnClick}
            />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default SHTScoreCard;
