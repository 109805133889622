import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import "../assets/styles/page/latest_news_filter_style.css";
import { Container } from "react-bootstrap";
import SHTText from "./SHTText";
import Dropdown from "react-bootstrap/Dropdown";
import SHTButton from "./SHTButton";
import { RequestResponseInterface } from "../interfaces/axios.dto";
import store from "../store";

const SHTScoreFilter = ({
  handleFilter,
  subTitle,
  title,
  fetchTournaments,
  newsReducer,
  no_header,
}: any) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [divisions, setDivision] = useState(newsReducer.divisions);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleTournamantFetch();
  }, []);

  const handleTournamantFetch = async () => {
    setLoading(true);
    await fetchTournaments()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const newArray = response?.data?.results?.map((data: any) => {
            return data.name;
          });

          setDivision(newArray);

          store.dispatch({
            type: "SET_DIVISION",
            payload: newArray,
          });
          setLoading(false);
        } else {
        
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  // const divisions = ["IN SEASON", "PRE-SEASON", "DIVISION 2", "DIVISION 1"];
  const weeks = ["Week 1", "Week 1", "Week 3"];
  const years = ["2024", "2023"];

  const [_type, setType] = useState("");
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");
  const [params, setParams] = useState<any>();

  const handleClick = (type: string, filterType: string) => {
    if (filterType === "divisions") setType(type);
    if (filterType === "years") setYear(type);
    if (filterType === "weeks") setWeek(type);
    setParams({
      division: type /* , created_at_year: year, week_number: week */,
    });
    handleFilter({
      division: type /* , created_at_year: year, week_number: week */,
    });
  };

  return (
    <section>
      <Container>
        <div className="py-4">
          <div className="">
            <SHTText size="p" text={subTitle} color="gray" />
          </div>
          <div>
            <SHTText size="h2" text={title} color="white" />
          </div>
        </div>
        <div className="d-flex justify-content-between py-4 filter-class">
          <div className="d-flex">
            {divisions?.map((division: string) => (
              <div style={{ marginRight: 5 }}>
                <SHTButton
                  name={division}
                  type="submit"
                  color={_type === division ? "primary" : "secondary"}
                  handleClick={() => handleClick(division, "divisions")}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTScoreFilter);
