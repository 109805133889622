import React, { useEffect, useState } from "react";
import StandingHeader from "./StandingHeader";
import SHTSpinner from "../../components/SHTSpinner";
import StandingDivision from "./StandingDivision";
import titans from "../../assets/images/svg/titansLogo.svg";
import ScoreFilter from "../score/ScoreFilter";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
const StandingPage = ({ teamStandings, newsReducer }: any) => {
  const [type, setType] = useState<any>("division");
  const [loading, setLoading] = useState(false);
  const [division, setDivision] = useState("");
  const [standingsData, setStandings] = useState([]);

  const handleFetchStandingCategory = (type: string) => {
    setType(type);
  };

  useEffect(() => {
    handleGetStandings({
      division: newsReducer?.divisions[0] ? newsReducer?.divisions[0] : "Group A",
    });
  }, []);

  const handleGetStandings = async (filter: any) => {
    console.log(filter);
    setStandings([]);
    setDivision(filter);

    setLoading(true);
    await teamStandings(filter)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const standings = response.data.results.map((data: any) => {
            return {
              id: data.id,
              image: data?.team?.team_image[0]?.image,
              team: data?.team?.name,
              win: data?.wins,
              loose: data?.losses,
              draw: data?.draws,
              pct: data?.pct,
              pf: data?.points_for,
              pa: data?.points_against,
              point: data?.net_point,
              strike: data?.strk,
              last: data?.last5,
            };
          });

          setStandings(standings);

          setLoading(false);
        } else {
          console.log("error fetching skills");
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <SHTSpinner loading={false}>
      <section>
        <StandingHeader
          handleFetchStandingCategory={handleFetchStandingCategory}
        />
        <ScoreFilter
          subTitle="2024 STANDINGS"
          title={"DIVISIONS"}
          handleFilter={(filter: string) => handleGetStandings(filter)}
        />
        {type === "division" && (
          <StandingDivision
            divisionOne={standingsData}
            loading={loading}
            //    division={division}
            // divisionTwo={divisionTwoTable}
          />
        )}
        {/* {type === "league" && <StandingLeague league={leagueDetail} />} */}
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(StandingPage);
