import React from "react";
import { Spinner } from "react-bootstrap";
import SHTText from "./SHTText";

interface NoResultPropsInterface {
  loading: boolean;
}

function SHTNoResult({ loading }: NoResultPropsInterface) {
  return (
    <div className="no_result_container">
      {loading ? (
        <Spinner animation="border" variant="light" />
      ) : (
        <SHTText size="p" text="NO RESULT" color="gray" />
      )}
    </div>
  );
}

export default SHTNoResult;
