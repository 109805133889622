import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import SHTText from "../../components/SHTText";
import SHTTrendingNews from "../../components/SHTTrendigNews";
import { useParams } from "react-router-dom";
import SHTSpinner from "../../components/SHTSpinner";
import "../../assets/styles/page/sht_news_details_style.css";
import { Container } from "react-bootstrap";
import { formatDate } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

interface NewsDetailsProps {
  fetchNewsDetails: Function;
}

type MyParams = {
  id: string;
};

function NewsDetail(props: NewsDetailsProps) {
  const { fetchNewsDetails } = props;
  const [relatedNews, setRelatedNews]: any = useState();
  const [loading, setLoading] = useState<boolean>(true);

  const [news, setNews]: any = useState({});
  const { id } = useParams<MyParams>();

  const navigate = useNavigate();

  useEffect(() => {
    handleFetchNews(id);
  }, []);

  const handleFetchNews = async (id: any) => {
    setLoading(true);
    await fetchNewsDetails(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setNews(response.data.data);
          setRelatedNews(response.data.data.related_news);

          setLoading(false);
        } else {
        }
      })
      .catch((err: any) => {});
  };

  const handleNavigate = (id: string) => {
    navigate(`/news/${id}`);
    handleFetchNews(id);
  };

  return (
    <SHTSpinner loading={loading}>
    
      <Container className="sht_content_space">
      <section className="news_content_image_container">
        <img
          src={news?.featured_image?.image}
          alt="news_image"
          className="news_content_image"
        />
      </section>
        <div>
          <div>
            <SHTText
              text={formatDate(news.created_at)}
              size="small"
              color="gray"
            />
          </div>

          <div>
            <SHTText text={news.title} size="h3" color="white" />
          </div>

          <div className="sht_content_space">
            <div
              className="dark_mode"
              dangerouslySetInnerHTML={{ __html: news.body }}
            />
          </div>
        </div>
      </Container>
      <div>
        <SHTTrendingNews
          loading={loading}
          title="RELATED POST"
          trendingNews={relatedNews}
          handleOnClick={(id: string) => handleNavigate(id)}
        />
      </div>
    </SHTSpinner>
  );
}

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(NewsDetail);
