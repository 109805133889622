import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import SHTText from "./SHTText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SHTButton from "./SHTButton";
import "../assets/styles/components/sht_footer_style.css";
import { Link } from "react-router-dom";
import { RequestResponseInterface } from "../interfaces/axios.dto";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_INPUT_VALIDATION } from "../utils/validation";
import SHTInput from "./SHTInput";
import toast, { Toaster } from "react-hot-toast";
import { FaFacebook, FaTwitter, FaLinkedin, FaTiktok, FaInstagram, FaYoutube } from "react-icons/fa";
const SHTFooter = ({ newsReducer, sendSubscribedEmail }: any) => {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);

  let registerInput: any = {
    email: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const handleSubmitData = async (data: any) => {
    setloading(true);
    await sendSubscribedEmail(data)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          toast.success("Your email has been sent");
          setloading(false);
        } else {
          toast.error("An error occurred");
          setloading(false);
        }
      })
      .catch((err: any) => {
        toast.error("An error occurred");
        setloading(false);
      });
  };

  const socialMediaLinks = [
    { name: "Twitter", icon: <FaTwitter size="30" color="grey"/>, link: "https://twitter.com/ShowtimeFlag" },
    { name: "Tiktok", icon: <FaTiktok size="30" color="grey"/>, link: "https://www.tiktok.com/@showtimeflag" },
    { name: "Instagran", icon: <FaInstagram size="30" color="grey"/>, link: "https://www.instagram.com/sfflshowtime/?hl=en" },
    { name: "Youtube", icon: <FaYoutube size="30" color="grey"/>, link: "https://www.youtube.com/@SFFLShowtime" },
  
  ];

  return (
    <div className="sht_footer_container ">
      <Toaster />
      <section>
        <main
          className={
            newsReducer.mode == "light"
              ? "row  sht_footer sh_next_match_background_light"
              : "row  sht_footer "
          }
        >
          <div className="col-lg-2 col-md-4 sht_footer_col">
            <div className="py-1">
              <SHTText size="strong" text="QUICK LINKS" color="white" />
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText size="light" text="HOME" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/scores">
                <SHTText size="light" text="SCORES" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/league/schedule">
                <SHTText size="light" text="SCHEDULES" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/teams">
                <SHTText size="light" text="TEAMS" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/stats">
                <SHTText size="light" text="STATS" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/standings">
                <SHTText size="light" text="STANDINGS" color="gray" />
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 sht_footer_col">
            <div className="py-1">
              <SHTText size="strong" text="ABOUT SHOWTIME" color="white" />
            </div>
            <div className="py-1">
              <Link to="/about">
                <SHTText size="light" text="ABOUT" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/teams">
                <SHTText size="light" text="DIVISIONS" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/teams">
                <SHTText size="light" text="TEAMS AND PLAYERS" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText
                  size="light"
                  text="RULES AND REGULATION"
                  color="gray"
                />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText size="light" text="FAQ" color="gray" />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 sht_footer_col">
            <div className="py-1">
              <SHTText size="strong" text="CONTACT" color="white" />
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText size="light" text="CONTACT US" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/team-registration">
                <SHTText size="light" text="REGISTER A TEAM" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/player-registration">
                <SHTText size="light" text="SOLO REGISTRATION" color="gray" />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText
                  size="light"
                  text="SPONSORSHIPS OPPORTUITIES"
                  color="gray"
                />
              </Link>
            </div>
            <div className="py-1">
              <Link to="/">
                <SHTText
                  size="light"
                  text="VOLUNTEER OPPORTUITIES"
                  color="gray"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 sht_footer_col">
            <div className="py-1">
              <SHTText size="strong" text="NEWSLETTER" color="white" />
            </div>
            <div className="py-1">
              <SHTText
                size="light"
                text="Subscribe to our news paper and stay connected 
with the latest news, updates and exciting 
happenings in the league."
                color="gray"
              />
            </div>
            <div className="py-1">
              <Form className="px-3">
                <Row className="">
                  <Col>
                    <Controller
                      control={control}
                      name="email"
                      rules={EMAIL_INPUT_VALIDATION}
                      render={({ field: { value, onChange } }) => (
                        <SHTInput
                          placeholder="Enter your email"
                          type="messageText"
                          value={value}
                          // editable={loading}
                          onChangeText={onChange}
                          error={errors.email && errors.email.message}
                        />
                      )}
                    />
                  </Col>
                  <Col xs="auto" className="subscribe_button">
                    <SHTButton
                      name="SUBSCRIBE"
                      type="submit"
                      size="sm"
                      loading={loading}
                      handleClick={handleSubmit(handleSubmitData)}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="py-1 mb-3">
              <SHTText
                size="light"
                text="By subscribing, you agree to our Privacy Policy"
                color="gray"
              />
            </div>
            <div className="py-1 mb-2">
              <Row>
                {socialMediaLinks.map((platform, index) => (
                  <Col key={index} xs="auto" className="social_media_icon">
                    <a href={platform.link} target="_blank" rel="noreferrer" className="m-3">
                      <span style={{ color: "#10243E" }}>{platform.icon}</span>
                    </a>
                  </Col>
                ))}
              </Row>
            </div>
          </div>

          <div className="horizontal-line mt-5 mx-2 ">
            <div className="mt-3">
              <SHTText
                size="light"
                text="@ 2023 Showtime Flag Football League. All rights reserved."
                color="gray"
              />
            </div>
          </div>
        </main>
      </section>
    </div>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTFooter);
