import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTFileUpload from "../../components/SHTFileUpload";
import FileUpload from "../../assets/images/svg/file.svg";
import SHTButton from "../../components/SHTButton";
import {
  COLOR_INPUT_VALIDATION,
  EMAIL_INPUT_VALIDATION,
} from "../../utils/validation";
import useFormPersistence from "../../utils/helper/useFormPersist";

type FormInputs = {
  team_instagram: string;
  team_tiktok: string;
  team_facebook: string;
  team_twitter: any;
};
const TeamSocialsReg = ({ onUpdateFormData, handleSubmitData }: any) => {
  let loginInitalValues: FormInputs = {
    team_instagram: "",
    team_tiktok: "",
    team_facebook: "",
    team_twitter: "",
  };
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: loginInitalValues,
  });

  // Use the custom hook to handle form persistence
  useFormPersistence<FormInputs>("teamSocials", setValue, watch);

  return (
    <section className="first_form_container">
      <div className="pb-4">
        <SHTText size="h2" text="Team Socials" color="gray" />
      </div>
      <div className="form_list" style={{ marginBottom: 40 }}>
        <div className="form_element">
          <Controller
            control={control}
            name="team_instagram"
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Instagram Account"
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
                error={errors.team_instagram && errors.team_instagram.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="team_tiktok"
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Tiktok Account"
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
                error={errors.team_tiktok && errors.team_tiktok.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="team_facebook"
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Facebook Account"
                placeholder=""
                type="messageText"
                value={value}
                // editable={loading}
                onChangeText={onChange}
                error={errors.team_facebook && errors.team_facebook.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="team_twitter"
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Twitter Account"
                placeholder=""
                type="messageText"
                value={value}
                // editable={loading}
                onChangeText={onChange}
                error={errors.team_twitter && errors.team_twitter.message}
              />
            )}
          />
        </div>
      </div>

      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(handleSubmitData)}
        />
      </div>
    </section>
  );
};

export default TeamSocialsReg;
