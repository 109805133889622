import React, { useEffect, useState } from "react";

import SHTLeagueCard from "../../components/SHTLeagueCard";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import SHTSpinner from "../../components/SHTSpinner";
import LeagueFilter from "./LeagueFilter";
import LeagueDetails from "./LeagueDetails";
import LeagueBye from "./LeagueBye";
import Team1 from "../../assets/images/svg/leagueteam1.svg";
import Team2 from "../../assets/images/svg/leagueteam2.svg";
import { useNavigate } from "react-router-dom";
import TeamHeader from "../team_schedule/TeamHeader";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import ScoreFilter from "../score/ScoreFilter";

interface leagePropsInterface {
  fetchFixtures: Function;
}

const League = (props: leagePropsInterface) => {
  const { fetchFixtures } = props;
  const [fixtures, setFixtures] = useState([]);
  const [pagination, setPagination] = useState();
  const [current, setCurrent] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = React.useState(true);

  const [filter, setFilter] = useState<any>("League");

  useEffect(() => {
    handleFetchFixtures(null);
  }, []);

  const navigate = useNavigate();

  const handleNavigate = async (type: string) => {
    setActive(type === "league");
    navigate(`/${type}/schedule`);
  };

  const handleFetchFixtures = async (params: any) => {
    const new_params = {
      division: params?.division,
      created_at_year: params?.created_at_year,
      week_number: params?.week_number,
      page: current,
    };

    setFilter(new_params);
    setFixtures([]);
    setLoading(true);
    await fetchFixtures(new_params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setFixtures(response.data.results);
          setPagination(response.data);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const PaginationChange = (page: any) => {
    setCurrent(page);
  };
  return (
    <SHTSpinner loading={false}>
      <TeamHeader
        handleNavigate={(type: string) => handleNavigate(type)}
        active={active}
      />
      <ScoreFilter
        subTitle="SHOWTIME SCHEDULE"
        title={"SCHEDULES"}
        handleFilter={(filter: string) => handleFetchFixtures(filter)}
      />

      <section className="sht_container_space">
        <LeagueDetails
          handlePageChange={PaginationChange}
          pagination={pagination}
          cardDetails={fixtures}
          loading={loading}
        />
        <LeagueBye />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(League);
