import React from "react";
import SHTGameCard from "../../components/SHTGameCard";
import SHTNoResult from "../../components/SHTNoResult";

interface PropsInterface {
  touchdown: any;
  interception: any;
  turnover: any;
  open: any;
  handleOpenDetails: any;
  loading: any;
  dropdownDetailsTouchdown: any;
  dropdownDetailsTurnover: any;
  dropdownDetailsInnterception: any;
}

const GameCardDetails: React.FC<PropsInterface> = ({
  touchdown,
  interception,
  turnover,
  open,
  handleOpenDetails,
  loading,
  dropdownDetailsTouchdown,
  dropdownDetailsInnterception,
  dropdownDetailsTurnover,
}) => {
  
  return !loading ? (
    <section>
      <div className="my-3">
        {touchdown && Object.keys(touchdown).length > 0 && (
          <SHTGameCard
            dropdownDetails={dropdownDetailsTouchdown}
            handleOpenDetails={handleOpenDetails}
            open={open}
            teamImage={touchdown?.teamImage}
            teamName={"Touchdown"}
            time={"11:00am"}
            plays={touchdown?.touchdownCounts}
            start={touchdown?.abbreviation}
          />
        )}
      </div>
      <div className="my-3">
        {turnover && Object.keys(turnover).length > 0 && (
          <SHTGameCard
            dropdownDetails={dropdownDetailsTurnover}
            handleOpenDetails={handleOpenDetails}
            open={open}
            teamImage={turnover?.teamImage}
            teamName={"Turnovers"}
            time={"11:00am"}
            plays={turnover?.turnOverCounts}
            start={turnover?.abbreviation}
          />
        )}
      </div>
      <div className="my-3">
        {interception && Object.keys(interception).length > 0 && (
          <SHTGameCard
            dropdownDetails={dropdownDetailsInnterception}
            handleOpenDetails={handleOpenDetails}
            open={open}
            teamImage={interception?.teamImage}
            teamName={"Interceptions"}
            time={"11:00am"}
            start={interception?.abbreviation}
          />
        )}
      </div>
    </section>
  ) : (
    <SHTNoResult loading={loading} />
  );
};

export default GameCardDetails;
