import { useEffect } from "react";
import { useForm, UseFormSetValue, UseFormWatch } from "react-hook-form";

const useFormPersistence = <T,>(
  storageKey: string,
  //@ts-ignore
  setValue: UseFormSetValue<T>,
  //@ts-ignore
  watch: UseFormWatch<T>
) => {
  useEffect(() => {
    const savedValues = localStorage.getItem(storageKey);
    if (savedValues) {
      const parsedValues = JSON.parse(savedValues);
      Object.keys(parsedValues).forEach((key) => {
        //@ts-ignore
        setValue(key as keyof T, parsedValues[key]);
      });
    }
  }, [setValue, storageKey]);

  useEffect(() => {
    const subscription = watch((values) => {
      localStorage.setItem(storageKey, JSON.stringify(values));
    });
    return () => subscription.unsubscribe();
  }, [watch, storageKey]);
};

export default useFormPersistence;
