import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTButton from "../../components/SHTButton";

interface FormInputs {
  declaration: boolean;
}

interface Agreement {
  id: number;
  text: string;
}

const LegalAgreement = ({ handleSubmitData, loading }: any) => {
  const [active, setActive] = useState(false);
  let loginInitialValues: FormInputs = {
    declaration: false,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: loginInitialValues,
  });

  const declarationValue = watch("declaration");

  const agreements: Agreement[] = [
    {
      id: 0,
      text: "The league logo must be permanently displayed  on team jerseys.",
    },
    {
      id: 1,
      text: "Foul language or abusive behavior towards officials would not be tolerated.",
    },
    { id: 2, text: "The referee’s decision is final and binding." },
    { id: 3, text: "Respect league officials and adhere to their decisions." },
    { id: 4, text: "Teams must comply with all league rules and regulations." },
    {
      id: 5,
      text: "Players, team owners, captains and coaches must always behave with respect and professionalism.",
    },
    { id: 6, text: "Team must prioritize safety both on and off the field." },
    { id: 7, text: "No weapons are allowed on the premises." },
    {
      id: 8,
      text: "All participants must respect security personnel and follow established protocols.",
    },
    { id: 9, text: "Respect the security officials and protocols." },
  ];
  

  return (
    <section className="first_form_container team_management">
      <div className="">
        <SHTText size="h2" text="Code of Conduct" color="gray" />
      </div>
      <div className="form_list">
        <div className="form_agreement">
          {agreements.map((agreement: Agreement) => (
            <ul className="form_list" key={agreement.id}>
              <li>
                <SHTText text={agreement.text} size="p" color="gray" />
              </li>
            </ul>
          ))}
          <div className="mb">
           
            <Controller
              control={control}
              name="declaration"
              rules={{
                required: "You must indicate your consent by checking the box.",
              }}
              render={({ field: { value, onChange } }) => (
                <div>
                  <input
                    type="checkbox"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                  />{" "}
                  <span className="label_text">
                  I hereby attest that I have read, understood and agree to abide by the Code of Conduct as outlined above by the Showtime Flag Football League. I acknowledge that failure to comply with this Code of Conduct may result in disciplinary action.t.
                  </span>
                  {errors.declaration && (
                    <p className="text-danger">{errors.declaration.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          disabled={!declarationValue}
          name="Submit"
          loading={loading}
          handleClick={handleSubmit(handleSubmitData)}
        />
      </div>
    </section>
  );
};

export default LegalAgreement;
