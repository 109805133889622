import React from "react";
import SHTText from "./SHTText";

interface SHTLineHeaderProps {
    headerText: string;
    lineColor?: string;
    lineWidth?: string;
    className?: string;
}

const SHTLineHeader: React.FC<SHTLineHeaderProps> = ({ headerText, lineColor, lineWidth, className }) => {
    let lineColorClass = ""; 
    let lineWidthClass = "";

    switch (lineColor) {
        case "blue":
            lineColorClass = "line_color_blue";
            break;
        case "white":
            lineColorClass = "line_color_white";
            break;
        default:
            lineColorClass = "line_color_blue"; 
            break;
    }

    switch (lineWidth) {
        case "long":
            lineWidthClass = "line_color_long";
            break;
        case "short":
            lineWidthClass = "line_color_short";
            break;
        default:
            lineWidthClass = "line_color_long"; 
            break;
    }

    const combinedClassName = `${lineColorClass} ${lineWidthClass} ${className || ''}`;

    return (
        <section className="mb-3">
            <SHTText size="h4" color="white" text={headerText} />
            <div className="horizontal-line">
                <div className={combinedClassName}></div>
            </div>
        </section>
    );
};

export default SHTLineHeader;
