import React, { useState } from "react";
import "../../assets/styles/page/latest_news_filter_style.css";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import Dropdown from "react-bootstrap/Dropdown";
import SHTButton from "../../components/SHTButton";

interface PropsInterface {
  handleFetchStat: Function;
}

const StatsFilter = ({ handleFetchStat }: PropsInterface) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [type, setType] = useState("PASSING");

  const handleClick = (type: string) => {
    setType(type);
    handleFetchStat(type);
  };

  return (
    <section className="latest_news_filter overflow-scroll">
      <Container>
        <div className="py-4">
          <div className="">
            <SHTText
              size="p"
              text={`${currentYear} PLAYER STATS`}
              color="gray"
            />
          </div>
          <div>
            <SHTText size="h2" text={type} color="white" />
          </div>
        </div>
        <div className="d-flex justify-content-between py-4">
          <div className="d-flex">
            <SHTButton
              name="PASSING"
              type="submit"
              color={type === "PASSING" ? "primary" : "secondary"}
              handleClick={(type: string) => handleClick("PASSING")}
            />
            <div className="mx-2">
              <SHTButton
                name="RECEIVING"
                type="submit"
                color={type === "RECEIVING" ? "primary" : "secondary"}
                handleClick={(type: string) => handleClick("RECEIVING")}
              />
            </div>
            <SHTButton
              name="DEFENSE"
              type="submit"
              color={type === "DEFENSE" ? "primary" : "secondary"}
              handleClick={() => handleClick("DEFENSE")}
            />
            <div className="mx-2">
              <SHTButton
                name="RUSHING"
                type="submit"
                color={type === "RUSHING" ? "primary" : "secondary"}
                handleClick={() => handleClick("RUSHING")}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

StatsFilter.defaultProps = {
  type: "PASSING",
};

export default StatsFilter;
