import React from "react";
import AuthBackground from "../../assets/images/svg/authBackground.tsx.png";
import Logo from "../../assets/images/showtime_logo.png";
import "../../assets/styles/page/login_style.css";
import Google from "../../assets/images/svg/googleIcon.svg";
import Facebook from "../../assets/images/svg/facebookIcon.svg";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";

interface FormInputs {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}
const SignUpPage = () => {
  let loginInitalValues: FormInputs = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });

  return (
    <section className="login_container">
      <section className="login_children">
        <main className="login_logo_container">
          <img
            src={Logo}
            style={{ width: 100, height: 60, marginBottom: 10 }}
            alt="Logo"
          />
        </main>
        <main className="login_form_container">
          <section className="login_image_container">
            <div className="form_image">
              <img
                src={AuthBackground}
                alt="Player Image"
                className="form_player_image"
              />
            </div>

            <div className="login_form">
              <section className="d-flex">
                <div className="d-flex align-items-center auth_container ">
                  <img src={Google} alt="Google Icon" />
                  <div className="mx-3">
                    <SHTText size="p" text="Google" color="gray" />
                  </div>
                </div>
                <div className="d-flex align-items-center auth_container auth_container_facebook">
                  <img src={Facebook} alt="Facebook Icon" />
                  <div className="mx-3">
                    <SHTText size="p" text="Facebook" color="gray" />
                  </div>
                </div>
              </section>
              <section className="d-flex justify-content-between align-items-center my-4">
                <div className="straight-line"></div>
                <SHTText size="cardLight" text="Or" color="white" />
                <div className="straight-line"></div>
              </section>

              <section>
                <div className="d-flex justify-content-between">
                  <div className="sign_up_field">
                    <Controller
                      control={control}
                      name="firstName"
                      rules={{
                        required: "First Name is needed",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <SHTInput
                          hasLabel={false}
                          placeholder="First Name"
                          type="messageText"
                          value={value}
                          // editable={loading}
                          onChangeText={onChange}
                        />
                      )}
                    />
                  </div>
                  <div className="sign_up_field">
                    <Controller
                      control={control}
                      name="lastName"
                      rules={{
                        required: "Last Name is needed",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <SHTInput
                          hasLabel={false}
                          placeholder="Last Name"
                          type="messageText"
                          value={value}
                          // editable={loading}
                          onChangeText={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: "Email is needed",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <SHTInput
                      hasLabel={false}
                      placeholder="Email"
                      type="messageText"
                      value={value}
                      // editable={loading}
                      onChangeText={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="password"
                  rules={{
                    required: "Password is needed",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <SHTInput
                      hasLabel={false}
                      placeholder="**********"
                      type="messageText"
                      value={value}
                      // editable={loading}
                      onChangeText={onChange}
                    />
                  )}
                />
              </section>
              <section className="d-flex justify-content-center mt-3 align-items-center">
                <SHTButton type="submit" name="Sign Up" />
              </section>
            </div>
          </section>
          <section></section>
        </main>
      </section>
    </section>
  );
};

export default SignUpPage;
