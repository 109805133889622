import React, { useState, useEffect } from "react";
import LatestNewsHeader from "./LatestewsHeader";
// import LatestNewsFilter from "./LatestNewsFilter";
import LatestNewsFilter from "./LatestNewsFilter";
import {
  RequestResponseInterface,
  newsInterface,
} from "../../interfaces/axios.dto";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
// import NewsComponent from "./NewsComponent";
import NewsComponent from "./NewsComponent";
import { useNavigate } from "react-router-dom";
import SHTSpinner from "../../components/SHTSpinner";

interface PropsInterface {
  latestNews: newsInterface[];
}

const LatestNews = ({ fetchlatestNews }: any) => {
  const [latestNews, setLatestNews]: any = useState([]);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handlelatestNewsFetch();
  }, [currentPage]);

  const handlelatestNewsFetch = async () => {
    const params = {
      page: currentPage,
    };

    const updatedParams = {
      ...params,
      // ...filter,
    };
    setLatestNews([]);
    setLoading(true);
    await fetchlatestNews(updatedParams)
      .then((response: RequestResponseInterface) => {
        setLoading(false);
        if (response.status === 200) {
          setTotalPages(response.data.total_pages);
          setLatestNews(response.data.results);
        } else {
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const PaginationChange = (page: any) => {
    setCurrentPage(page);
  };
  const handleNavigate = (id: string) => {
    navigate(`/news/${id}`);
  };

  return (
    <SHTSpinner loading={false}>
      <div className="sh_background_dark">
        <LatestNewsHeader />
        <LatestNewsFilter />
        <NewsComponent
          currentPage={currentPage}
          loading={loading}
          totalPages={totalPages}
          handlePageChange={PaginationChange}
          latestNews={latestNews}
          handleOnClick={(id: string) => handleNavigate(id)}
        />
      </div>
    </SHTSpinner>
  );
};
const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(LatestNews);
