import React from "react";
import "../../assets/styles/page/about_team_style.css";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";

type cardProps = {
  image: string;
  name: string;
  post: string;
};

interface cardInterface {
  teamDetails: cardProps[];
}

const Team = ({ teamDetails }: cardInterface) => {
  return (
    <section className="about_team_body sht_container_space">
      <Container>
        <div className="header_with_line_container">
          <SHTText size="h1" color="white" text="MEET THE TEAM" />
          <hr className="header_with_line"></hr>
        </div>
        <div className="team_container">
          {teamDetails?.map((team: cardProps) => {
            return (
              <div className="team_exe_card my-5 px-4">
                <div className="">
                  <img src={team.image} alt="Our first Team" width={"100%"} height={"300px"}/>
                </div>
                <div className="d-flex justify-content-center">
                  <SHTText size="h4" color="white" text={team.name}/>
                </div>
                <div className="d-flex justify-content-center">
                  <SHTText size="p" color="gray" text={team.post} />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Team;
