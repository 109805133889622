import React from "react";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import { formatStat } from "../../utils/helper";

interface statsDetails {
  statsDetails: any[];
  handleClick: any;
}
const TeamDefenseStats = (props: statsDetails) => {
  const { statsDetails, handleClick } = props;
  return (
    <Container className="sht_container_space">
      <div className="stats_table_container">
        <table>
          <tr className="stats_table_header">
            <th className="py-4 px-3">
              <SHTText size="p" color="white" text="Team" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="FP" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="BD" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="PICK" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="SACK" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="BLK" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="TDS" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="XTDS" />
            </th>
          </tr>
          {statsDetails.map((stat: any, index: number) => {
            return (
              <tr
                key={index}
                className="stats_table_data"
                onClick={() => handleClick(stat?.id)}
              >
                <td className="py-3 ">
                  <div className="d-flex align-items-center">
                    <img
                      src={stat.image}
                      alt="Profile Image"
                      className="team_image_tiny"
                    />
                    <div className="mx-2">
                      <SHTText size="p" color="gray" text={stat.team} />
                    </div>
                  </div>
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.fp)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.bd)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.pick)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.sack)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.blk)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.tds)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.xtds)} />
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </Container>
  );
};

export default TeamDefenseStats;
