import React from "react";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import "../../assets/styles/page/first_form_style.css";
import SHTInput from "../../components/SHTInput";
import SHTRadio from "../../components/SHTRadio";
import SHTButton from "../../components/SHTButton";

interface FormInputs {
  dob: string;
  have_played_flag_football_before: string;
  have_played_in_showtime_before: string;
}
const SecondForm = ({ handleSubmitData }: any) => {
  let registerInput: FormInputs = {
    dob: "",
    have_played_flag_football_before: "",
    have_played_in_showtime_before: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });
  const RadiOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const onSubmit = (data: FormInputs) => {
    // Call the external submit handler
    handleSubmitData(data);
  };
  return (
    <section className="first_form_container">
      <div className="pb-4">
        <SHTText size="h3" text="Player Information" color="gray" />
      </div>
      <form>
        <div className="mb-3">
          <Controller
            control={control}
            name="dob"
            rules={{
              required: "Date of birth is needed",
            }}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Date of Birth"
                placeholder=""
                type="date"
                value={value}
                onChangeText={onChange}
                error={errors.dob && errors.dob.message}
              />
            )}
          />
        </div>

        <div className="my-4">
          <SHTRadio
            name="have_played_flag_football_before"
            options={RadiOptions}
            control={control}
            onRadioChange={(value) =>
              setValue("have_played_flag_football_before", value)
            }
            legend=<p className="radio_text">
              Have you played Flag Football before?
            </p>
          />
        </div>
        <div className="my-4">
          <SHTRadio
            name="have_played_in_showtime_before"
            options={RadiOptions}
            control={control}
            onRadioChange={(value) =>
              setValue("have_played_in_showtime_before", value)
            }
            legend=<p className="radio_text">Do you play in Showtime Flag?</p>
          />
        </div>
      </form>
      <div className="team_next">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default SecondForm;
