import React, { useEffect, useState } from "react";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
import "../../assets/styles/page/team_header_style.css";

interface TeamHeaderProps {
  handleNavigate: Function;
  active: boolean;
}

const TeamHeader = ({ handleNavigate, active }: TeamHeaderProps) => {
  const handleChangeNav = (type: any) => {
    handleNavigate(type);
  };
  return (
    <section className={!active ? "team_header" : "league_header_container"}>
      <Container className="py-2">
        <div className="d-flex h-100 ">
          <div className="overlay"></div>
          <div className="header_text">
            <SHTText size="h2" text="FIXTURES" color="white" />
            <SHTText size="p" text="UPCOMING GAMES" color="gray" />
          </div>
        </div>
        <div className="header_text my-3 d-flex">
          <SHTButton
            name="League"
            type="submit"
            color={active ? "primary" : "dark"}
            handleClick={() => handleChangeNav("league")}
          />
          <div className="mx-3">
            <SHTButton
              name="Team"
              type="submit"
              color={active ? "dark" : "primary"}
              handleClick={() => handleChangeNav("team")}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeamHeader;
