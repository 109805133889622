import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PaginationProps {
  currentPage: any;
  totalPages: any;
  onPageChange: any;
}

function SHTPagination(props: PaginationProps) {
  const { currentPage, totalPages, onPageChange } = props;

  const renderPaginationItems = () => {
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className="container-fluid mt-5 mb-5 justify-content-center">
      {totalPages <= 1 ? (
        <></>
      ) : (
        <>
          <div className="" style={{ textAlign: "center" }}>
            <Pagination size="lg">
              <Pagination.First onClick={() => onPageChange(1)} />
              <Pagination.Prev
                onClick={() =>
                  onPageChange(currentPage <= 1 ? 1 : currentPage - 1)
                }
              />

              {renderPaginationItems()}

              <Pagination.Next
                onClick={() =>
                  onPageChange(
                    currentPage === totalPages ? totalPages : currentPage + 1
                  )
                }
              />
              <Pagination.Last onClick={() => onPageChange(totalPages)} />
            </Pagination>
          </div>
        </>
      )}
    </div>
  );
}

export default SHTPagination;
