import React, { ReactNode, useCallback } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/components/sht_file_upload_style.css";

interface FileDropzoneProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  children?: ReactNode;
  className?: string;
  name?: string;
  type?: string;
  ref?: object;
  accept?: any;
}

const SHTFileUpload = (props: FileDropzoneProps) => {
  const { onDrop, children, className, accept } = props;
  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      onDrop(acceptedFiles, rejectedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: accept,
  });

  return (
    <Row>
      <Col className={className}>
        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? "active" : ""}`}
        >
          <input {...getInputProps()} />
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default SHTFileUpload;
