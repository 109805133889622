import React from "react";
import "../../assets/styles/page/game_table_style.css";
import SHTText from "../../components/SHTText";

type gameDetailProps = {
  homeName: string;
  awayName: string;
  firstLr: number;
  secondLr: number;
  thirdLr: number;
  fourthLr: number;
  totalLr: number;
  firstTa: number;
  secondTa: number;
  thirdTa: number;
  fourthTa: number;
  totalTa: number;
};

interface cardInterface {
  gameDetail: gameDetailProps;
}
const GameFirstTable = ({ gameDetail }: cardInterface) => {
  return (
    <div className="game_first_table_wrapper">
      <table className="">
        <thead className="">
          <tr>
            <th></th>
            <th className="text-center table_detail_header_box">
              <SHTText text="1" color="white" size="p" />
            </th>
            <th className="text-center table_detail_header_box">
              <SHTText text="2" color="white" size="p" />
            </th>
            <th className="text-center table_detail_header_box">
              <SHTText text="3" color="white" size="p" />
            </th>
            <th className="text-center table_detail_header_box">
              <SHTText text="4" color="white" size="p" />
            </th>
            <th className="text-center table_detail_header_box">
              <SHTText text="Total" color="white" size="p" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.homeName} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.firstLr} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.secondLr} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.thirdLr} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.fourthLr} color="white" size="p" />
            </td>
            <td className="text-center table_detail_last_box table_detail_last_box">
              <SHTText text={gameDetail.totalLr} color="white" size="p" />
            </td>
          </tr>
          <tr>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.awayName} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.firstTa} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.secondTa} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.thirdTa} color="white" size="p" />
            </td>
            <td className="text-center table_detail_box">
              <SHTText text={gameDetail.fourthTa} color="white" size="p" />
            </td>
            <td className="text-center table_detail_last_box table_detail_last_box">
              <SHTText text={gameDetail.totalTa} color="white" size="p" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GameFirstTable;
