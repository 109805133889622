import React, { FC } from "react";
import "../assets/styles/components/sht_team_stats_card_style.css";
import SHTText from "./SHTText";

interface CardInputFieldProps {
  id?: string;
  title?: string;
  player_image?: string;
  jersey_number?: string;
  position?: string;
  first_player_name?: string;
  second_player_name?: string;
  third_player_name?: string;
}

const SHTTeamStatsCard: FC<CardInputFieldProps> = ({
  id,
  title,
  player_image,
  jersey_number,
  position,
  first_player_name,
  second_player_name,
  third_player_name,
}) => {
  return (
    <section className="team_stats_card_body ">
      <div className="team_stats_card_title p-3">
        <SHTText size="h5" text={title} color="white" />
      </div>
      <div className="d-flex align-items-center justify-content-between p-3">
        <div className="d-flex align-items-center">
          <img
            src={player_image}
            alt="Player Image"
            style={{ width: "70px", height: "70px" }}
          />
          <div className="mx-3">
            <SHTText size="p" text={first_player_name} color="white" />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="mx-2">
            <SHTText size="p" text={jersey_number} color="white" />
          </div>
          <SHTText size="small" text={position} color="white" />
        </div>
      </div>
      <div className="second_player_name">
        <div className="mx-3 py-3 d-flex justify-content-between align-items-center">
          <SHTText size="p" text={second_player_name} color="white" />
          <SHTText size="p" text={position} color="white" />
        </div>
      </div>
      <div className="mx-3 py-3 d-flex justify-content-between align-items-center">
        <SHTText size="p" text={third_player_name} color="white" />
      </div>
    </section>
  );
};

export default SHTTeamStatsCard;
