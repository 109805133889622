import React, { useEffect, useLayoutEffect, useState } from "react";
import TeamHero from "./TeamHero";
import TeamWeekFilter from "./TeamWeekFilter";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import TeamWeekCard from "./TeamWeekCard";
import BestTeam from "../../assets/images/svg/bestteamimage.svg";
import TeamImage from "../../assets/images/svg/teamcardImage.svg";
import SHTSpinner from "../../components/SHTSpinner";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
const TeamWeek = ({ fetchTeamWeek }: any) => {
  const [teamOfTheWeek, setTeamOfTheWeek] = useState<TeamState[]>([]);
  const [bestTeam, setBestTeam] = useState<TeamState[]>([]);
  const [bestTeamLoading, setBestTeamLoading] = useState<boolean>(true);

  //fix when you get exact data from endpoint
  const [content, setContent] = useState<any>([]);

  const [type, setType] = useState<any>([]);

  interface TeamState {
    profileImage?: string;
    clubImage?: string;
    name: string;
    firstTeam?: string;
    secondTeam?: string;
    firstScore?: string;
    secondScore?: string;
    att?: string;
    comp?: string;
    tds?: string;
    xp?: string;
    int?: string;
    position?: string;
  }
  useLayoutEffect(() => {
    handleTrendingNewsFetch("OFFENSE");
  }, []);

  const handleTrendingNewsFetch = async (type: string) => {
    setBestTeam([]);
    setBestTeamLoading(true);
    const params = {
      position_category: type,
    };
    await fetchTeamWeek(params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          console.log(response.data);

          setTeamOfTheWeek(response.data.data);

          const newArray = response.data.data.player.map((data: any) => {
            return {
              name: data.player_name,
              position: data?.position?.position_abbrevation,
              att: data.statistics.att || "n/a",
              comp: data.statistics.completion_percentage || "n/a",
              tds: data.statistics.touchdowns || "n/a",
              xp: data.statistics.extra_points || "n/a",
              int: data.statistics.interceptions || "n/a",
              clubImage: data.team.team_image[0].image,
              profileImage: data.player_image || TeamImage,
            };
          });

          setBestTeam(newArray);
          setBestTeamLoading(false);
        } else {
          console.log("error fetching skills");
          setBestTeamLoading(false);
        }
      })
      .catch((err: any) => {
        setBestTeamLoading(false);
      });
  };

  const handleFetchTeamOfTheWeek = (type: string) => {
    handleTrendingNewsFetch(type);
  };

  return (
    <SHTSpinner loading={false}>
      <section className="sh_background_dark">
        <TeamHero
          handleFetchTeamOfTheWeek={(type: string) =>
            handleFetchTeamOfTheWeek(type)
          }
          type={type}
        />
        <TeamWeekFilter teamOfTheWeek={teamOfTheWeek} />
        <TeamWeekCard cardDetails={bestTeam} loading={bestTeamLoading} />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamWeek);
