import React, { useState, CSSProperties, ReactElement, useEffect } from "react";
import "../assets/styles/components/sht_header_style.css";
import Logo from "../assets/images/showtime_logo.png";
import { RiMenu3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import SHTMobileNav from "./SHTMobileNav";
import store from "../store";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import MenuItem from "./MenuItem";
import ProfileDropDown from "./ProfileDropDown";
import Cookies from "js-cookie";
import SHTButton from "./SHTButton";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

interface HeaderItemProps {
  newsReducer?: any;
}

const SHTHeader = (props: HeaderItemProps) => {
  const navigate = useNavigate();
  const { newsReducer } = props;

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };
  const closeOpenMenu = () => {
    setOpenMenu(false);
  };

  const changeMode = (mode: string) => {
    store.dispatch({
      type: "MODE",
      payload: mode,
    });
  };

  const auth_token = Cookies.get("auth_token");
  return (
    <Container>
      <header
        className={
          newsReducer.mode == "light"
            ? "header sh_next_match_background_light"
            : " header"
        }
      >
        <div className="header_logo">
        <img src={Logo} style={{ width: 100, height: 60, marginBottom: 10 }} alt="Logo" />
        </div>

        <div className="menu_items">
          <MenuItem label="HOME" link="/" />
          <MenuItem label="ABOUT" link="/about" />
          <MenuItem label="SCORES" link="/scores" />
          <MenuItem label="NEWS" link="/news" />
          <MenuItem label="SCHEDULES" link="/league/schedule" />
          <MenuItem label="TEAMS " link="/teams" />
          <MenuItem label="STATS" link="/stats" />
          <MenuItem label="STANDINGS" link="/standings" />
        </div>

        <div
          style={{                  
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          {/* <div
            className=""
            onClick={() =>
              changeMode(newsReducer.mode === "light" ? "dark" : "light")
            }
          >
            <img src={logo} alt="Logo" className="header_mode" />
          </div> */}

          <div className="profile_login">
            {auth_token ? (
              <ProfileDropDown />
            ) : (
              <div>
                <div>
                  <SHTButton
                    type="submit"
                    name="Login"
                    color="primary"
                    size="sm"
                    handleClick={() => navigate("/login")}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="hambugger_menu" onClick={handleOpenMenu}>
            <RiMenu3Fill color="white" size={30} />
          </div>
        </div>
      </header>
      {openMenu && (
        <SHTMobileNav handleClose={closeOpenMenu} auth_token={auth_token} />
      )}
    </Container>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTHeader);
