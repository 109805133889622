import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import "./assets/styles/theme.css";
import NewsDetail from "./pages/new_details_page/NewsDetail";
import LatestNews from "./pages/latest_news_page/LatestNews";
import AboutPage from "./pages/about/AboutPage";
import TeamSchedule from "./pages/team_schedule/TeamSchedule";
import League from "./pages/league_schedule/League";
import TeamWeek from "./pages/team_of_the_week/TeamWeek";
import Teams from "./pages/team/Teams";
import HighlightDetails from "./pages/highlight/HighlightDetails";
import ScorePage from "./pages/score/ScorePage";
import TeamScheduleDetailsPage from "./pages/team_schedule_details_page/TeamScheduleDetailsPage";
import GamePage from "./pages/game/GamePage";
import RoasterPage from "./pages/roaster/RoasterPage";
import RoasterPlayerInfo from "./pages/roaster_player_info/RoasterPlayerInfo";
import StatsPage from "./pages/stats/StatsPage";
import TeamInfoPage from "./pages/team_info/TeamInfoPage";
import HighLights from "./pages/highlight/HighLights";
import StandingPage from "./pages/standings/StandingPage";
import TeamInfoStat from "./pages/team_info_stats/TeamInfoStat";
import LoginPage from "./pages/login/LoginPage";
import TeamRegistration from "./pages/team_registration/TeamRegistration";
import SignUpPage from "./pages/sign_up/SignUpPage";
import RegisterTeamPage from "./pages/register_team/RegisterTeamPage";
import RegisterPage from "./pages/register_page/RegisterPage";
import VerifyEmail from "./pages/register_page/VerifyEmail";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";
import ChangePassword from "./pages/change_password/ChangePassword";
import TeamContract from "./pages/contracts/TeamContract";
import TeamProfileEditConfirmation from "./pages/edit_confirmation/TeamProfileEditConfirmation";
import NotFound from "./components/404page";
import ContractTerminationConfirmation from "./pages/contract_termination/ContractTerminationConfirmation";

const routes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/** done*/}
        <Route path="/news" element={<LatestNews />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/about" element={<AboutPage />} /> {/** done*/}
        <Route path="/league/schedule" element={<League />} /> {/** done*/}
        <Route path="/team/schedule" element={<TeamSchedule />} /> {/** done*/}
        <Route path="/team-of-the-week" element={<TeamWeek />} />
        <Route
          path="/highlightdetails/:id"
          element={<HighlightDetails />}
        />{" "}
        {/** done*/}
        <Route path="/scores" element={<ScorePage />} /> {/** done*/}
        <Route path="/teams" element={<Teams />} /> {/** done*/}
        <Route path="/games/:id/:team_id" element={<GamePage />} />
        <Route path="/roaster/:id" element={<RoasterPage />} />
        <Route path="/team-info/:id" element={<TeamInfoPage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/contracts/:team_id" element={<TeamContract />} />
        <Route path="/team-info/stats/:id" element={<TeamInfoStat />} />
        <Route
          path="/players/roaster/:id/:team_id"
          element={<RoasterPlayerInfo />}
        />
        <Route path="/players/roaster/:id/" element={<RoasterPlayerInfo />} />
        <Route path="/games/highlights/:id" element={<HighLights />} />
        <Route path="/standings" element={<StandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ChangePassword />} />
        <Route path="/team-registration" element={<TeamRegistration />} />
        <Route path="/player-registration" element={<RegisterTeamPage />} />
        <Route path="/activate-account/:token" element={<VerifyEmail />} />
        <Route
          path="/teams/schedule/:id"
          element={<TeamScheduleDetailsPage />}
        />
        <Route path="/team-verify-edit/:id" element={<TeamProfileEditConfirmation />} />
        <Route path="/confirm-contract-status/:id" element={<ContractTerminationConfirmation />} />
        <Route element={<NotFound />} /> 
      </Routes>
    </Router>
  );
};

export default routes;
