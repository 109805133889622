import React from "react";
import SHTTeamStatsCard from "../../components/SHTTeamStatsCard";
import SHTLineHeader from "../../components/SHTLineHeader";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";

import PlayerImage from "../../assets/images/svg/statsinfoimage.svg";
import "../../assets/styles/page/team_info_stats_detail_style.css";

type teamStatProps = {
  team_logo?: string;
  home_team_score: string;
  tag: string;
  away_team_score: string;
};

type offenseStatProp = {
  player_image: string;
  att: string;
  cp: string;
  rec: string;
  drops: string;
  tds: string;
  xtds: string;
};
type defenseStatProp = {
  player_image: string;
  int: string;
  fp: string;
  bd: string;
  sack: string;
  blk: string;
  tds: string;
};

interface cardDetailsProps {
  cardDetailsOffense: any;
  cardDetailsDefense: any;
  teamStats: any;
  offenseStats: any;
  defenseStats: any;
}
const TeanInfoStatsDetails = (props: cardDetailsProps) => {
  const {
    cardDetailsOffense,
    cardDetailsDefense,
    teamStats,
    offenseStats,
    defenseStats,
  } = props;
  return (
    <Container className="">
      {/* Team Statistics */}
      <div className="roaster_table_container ">
        <div className="roaster_table">
          <div className="p-4">
            <SHTText size="h4" text="Team Statistics" color="white" />
          </div>
          <div className="p-4 statistics_text">
            <SHTText
              size="h5"
              text="2023 Preseasons Statistics"
              color="white"
            />
          </div>
          <div className="py-4 statistics_image d-flex justify-content-between align-items-center px-4">
            <div className="d-flex align-items-center">
              <img src={teamStats.team_logo} alt="Titan Logo" />
              <div className="mx-3">
                {" "}
                <SHTText size="p" text={teamStats.team_name} color="white" />
              </div>
            </div>
            <SHTText size="p" text="OPPONENTS" color="white" />
          </div>
          {teamStats.statisctics.map((stat: teamStatProps) => {
            return (
              <section className="statistics_details d-flex justify-content-between align-items-center px-4 py-4">
                <SHTText size="p" text={stat.home_team_score} color="white" />
                <SHTText size="p" text={stat.tag} color="white" />
                <SHTText size="p" text={stat.away_team_score} color="white" />
              </section>
            );
          })}
          <div className="d-flex justify-content-center py-4 load_more">
            <SHTText size="p" color="blue" text="VIEW ALL TEAM STATS" />
          </div>
        </div>
      </div>
      <div>
        <SHTLineHeader headerText="STATS" lineWidth="short" lineColor="white" />
        <div className="py-3">
          <SHTText size="h5" text="Top Offense" color="white" />
        </div>
        <div className="d-flex justify-content-between overflow-auto ">
          {cardDetailsOffense.map((detail: any, index: number) => {
            return (
              <div className="px-3">
                <SHTTeamStatsCard
                  key={index}
                  jersey_number={detail.jersey_number}
                  title={detail.title}
                  player_image={detail.player_image}
                  position={detail.position}
                  first_player_name={detail.first_player_name}
                  second_player_name={detail.second_player_name}
                  third_player_name={detail.third_player_name}
                />
              </div>
            );
          })}
        </div>
        <div className="sht_content_space">
          <div className="py-3">
            <SHTText size="h5" text="Top Defense" color="white" />
          </div>
          <div className="d-flex justify-content-between overflow-auto">
            {cardDetailsDefense.map((detail: any, index: number) => {
              return (
                <div className="px-3">
                  <SHTTeamStatsCard
                    key={index}
                    jersey_number={detail.jersey_number}
                    title={detail.title}
                    player_image={detail.player_image}
                    position={detail.position}
                    first_player_name={detail.first_player_name}
                    second_player_name={detail.second_player_name}
                    third_player_name={detail.third_player_name}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* Player Statistics */}
        <section className="sht_content_space">
          <SHTLineHeader headerText="Player Statistics" lineColor="white" />
          <div className="sht_container_space">
            <div className="py-4">
              <SHTLineHeader
                headerText="OFFENSE"
                lineColor="white"
                lineWidth="short"
              />
            </div>
            <div className="stats_table_container">
              <div className=" overflow-auto">
                <table>
                  <thead className="title_head">
                    <tr>
                      <th className="py-4 px-3 title_head">
                        <SHTText size="p" color="white" text="Player" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="ATT" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="CP" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="REC" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="DROPS" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="TDS" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="XTDS" />
                      </th>
                    </tr>
                  </thead>
                  {offenseStats.map((stat: offenseStatProp) => {
                    return (
                      <tr className="table_details">
                        <th className="py-4 px-3 d-flex align-items-center">
                          <img src={stat.player_image} alt="Player Image" />
                          <div className="mx-2">
                            {" "}
                            <SHTText
                              size="p"
                              color="white"
                              text="Okabonye Chukwuma"
                            />
                          </div>
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.att} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.cp} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.rec} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.drops} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.tds} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.xtds} />
                        </th>
                      </tr>
                    );
                  })}
                </table>
              </div>
              <div className="d-flex justify-content-center py-4 load_more">
                <SHTText size="p" color="blue" text="LOAD MORE" />
              </div>
            </div>
          </div>
          <div className="sht_container_space ">
            <div className="py-4">
              <SHTLineHeader
                headerText="DEFENSE"
                lineColor="white"
                lineWidth="short"
              />
            </div>
            <div className="stats_table_container">
              <div className=" overflow-auto">
                <table>
                  <thead className="title_head">
                    <tr>
                      <th className="py-4 px-3 title_head">
                        <SHTText size="p" color="white" text="Player" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="INT" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="FP" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="BD" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="SACK" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="BLK" />
                      </th>
                      <th className="py-4 px-3">
                        <SHTText size="p" color="gray" text="TDS" />
                      </th>
                    </tr>
                  </thead>
                  {defenseStats.map((stat: defenseStatProp) => {
                    return (
                      <tr className="table_details">
                        <th className="py-4 px-3 d-flex align-items-center">
                          <img src={stat.player_image} alt="Player Image" />
                          <div className="mx-2">
                            {" "}
                            <SHTText
                              size="p"
                              color="white"
                              text="Okabonye Chukwuma"
                            />
                          </div>
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.int} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.fp} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.bd} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.sack} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.blk} />
                        </th>
                        <th className="py-4 px-3">
                          <SHTText size="p" color="gray" text={stat.tds} />
                        </th>
                      </tr>
                    );
                  })}
                </table>
              </div>
              <div className="d-flex justify-content-center py-4 load_more">
                <SHTText size="p" color="blue" text="LOAD MORE" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
};
export default TeanInfoStatsDetails;
