import React from "react";
import Card from "react-bootstrap/Card";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/sht_division_style.css";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { Container } from "react-bootstrap";
import SHTNoResult from "../../components/SHTNoResult";

type tableProps = {
  id: string;
  teamName: string;
  pct: string;
  win: string;
  pf: string;
  pa: string;
  nets: string;
  strk: string;
};

const DivisionPage = ({
  DivisionTableValueTwo,
  DivisionTableValueOne,
  loading,
}: any) => {
  return (
    <Container className="d-lg-flex justify-content-between d-sm-block sht_container_space">
      <div className="sh_table_container col-lg-6 col-sm-12 ">
        <main className="sh_division_page_header_card d-flex justify-content-between align-items-center mb-2">
          <SHTText size="p" color="white" text="GROUP A STANDINGS" />
          {/* <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              2023
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </main>
        {DivisionTableValueOne?.length > 0 ? (
          <>
            <Table responsive="sm" borderless={true}>
              <thead>
                <tr>
                  <th>
                    <SHTText size="p" color="white" text="#" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="TEAM" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="P" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="W-L-D" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="PF" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="A" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="NETS PTS" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="STRK" />
                  </th>
                </tr>
              </thead>

              {DivisionTableValueOne.map((data: tableProps, index: number) => {
                return (
                  <tbody>
                    <tr>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={index} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.teamName} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.pct} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.win} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.pf} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.pa} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.nets} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.strk} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </>
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </div>
      <div className="sh_table_container col-lg-6 col-sm-12 sh_division_two_table">
        <main className="sh_division_page_header_card d-flex justify-content-between align-items-center mb-2">
          <SHTText size="p" color="white" text="GROUP B STANDINGS" />
          {/* <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              2023
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </main>

        {DivisionTableValueTwo?.length > 0 ? (
          <>
            <Table responsive="sm" borderless={true}>
              <thead>
                <tr>
                  <th>
                    <SHTText size="p" color="white" text="#" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="TEAM" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="PCT" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="W-L-D" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="PF" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="A" />
                  </th>
                  <th className="text-center py-3">
                    <SHTText size="p" color="white" text="NETS PTS" />
                  </th>
                  <th className="text-center py-3">
                    {" "}
                    <SHTText size="p" color="white" text="STRK" />
                  </th>
                </tr>
              </thead>

              {DivisionTableValueTwo.map((data: tableProps, index: number) => {
                return (
                  <tbody>
                    <tr>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={index} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.teamName} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.pct} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.win} />
                      </td>
                      <td className="text-center py-3">
                        {" "}
                        <SHTText size="p" color="white" text={data.pf} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.pa} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.nets} />
                      </td>
                      <td className="text-center py-3">
                        <SHTText size="p" color="white" text={data.strk} />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </>
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </div>
    </Container>
  );
};

export default DivisionPage;
