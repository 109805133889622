import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import "../../assets/styles/page/first_form_style.css";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import {
  EMAIL_INPUT_VALIDATION,
  PASSWORD_INPUT_VALIDATION,
} from "../../utils/validation";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";

interface FormInputs {
  password: string;
  confirm_password: string;
}

type MyParams = {
  token: string;
};

function ChangePassword({ resetPassword, verifyEmail }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams<MyParams>();

  const [loading, setLoading] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] =
    useState("verified");

  let registerInput: FormInputs = {
    password: "",
    confirm_password: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const handlverfiyEmail = async (data: any) => {
    setLoading(true);
    await verifyEmail(data)
      .then((response: RequestResponseInterface) => {
        
        if (response.status === 200) {
          setRegistrationSuccessful("verified");
        } else {
          setRegistrationSuccessful("unverified");
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setRegistrationSuccessful("unverified");
        setLoading(false);
      });
  };

  const handleSubmitData = async (data: any) => {
    if (data.password !== data.confirm_password) {
      alert("Password doesn't match");
      return;
    }

    const payload = {
      password: data.password,
      token: token,
    };
    setLoading(true);
    await resetPassword(payload)
      .then((response: RequestResponseInterface) => {
       
        if (response.status === 200) {
          setLoading(false);
          toast.success("Password reset successful");
          navigate("/login");
        } else {
          setRegistrationSuccessful("unverified");
          toast.error("Error changing password");
        }
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Error occurred");
        setLoading(false);
      });
  };

  return (
    <div>
      <section className="register_team_container">
        <Container>
          <section
            className="register_form_container"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <main className="register_form ">
              <section className="first_form_container">
                <div style={{ textAlign: "center", marginBottom: 50 }}>
                  <img
                    src={Logo}
                    style={{ width: 100, height: 60, marginBottom: 10 }}
                    alt="Logo"
                  />
                </div>
                {registrationSuccessful === "verifying" && (
                  <div>
                    <div
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        padding: 30,
                      }}
                    >
                      {/* @ts-ignore */}
                      <Spinner animation="border" variant="light" />
                    </div>
                  </div>
                )}

                {registrationSuccessful === "verified" && (
                  <div>
                    <div className="m-3" style={{ textAlign: "center" }}>
                      <SHTText
                        size="p"
                        text="Enter your email address you used in registering to change your password "
                        color="gray"
                      />
                    </div>
                    <div className="form_element">
                      <Controller
                        control={control}
                        name="password"
                        rules={PASSWORD_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="New Password"
                            placeholder=""
                            type="password"
                            value={value}
                            // editable={loading}
                            onChangeText={onChange}
                            error={errors.password && errors.password.message}
                          />
                        )}
                      />
                    </div>

                    <div className="form_element">
                      <Controller
                        control={control}
                        name="confirm_password"
                        rules={PASSWORD_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Confirm Password"
                            placeholder=""
                            type="password"
                            value={value}
                            // editable={loading}
                            onChangeText={onChange}
                            error={
                              errors.confirm_password &&
                              errors.confirm_password.message
                            }
                          />
                        )}
                      />
                    </div>

                    <section className="d-flex justify-content-between align-items-center">
                      <SHTButton
                        type="submit"
                        name="Submit"
                        loading={loading}
                        handleClick={handleSubmit(handleSubmitData)}
                      />
                    </section>
                  </div>
                )}
                {registrationSuccessful === "unverified" && (
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <SHTButton
                      type="submit"
                      name="Resend Activation"
                      loading={loading}
                      handleClick={() => navigate("/forgot_password")}
                    />
                  </div>
                )}
              </section>
            </main>
          </section>
        </Container>
      </section>
    </div>
  );
}

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(ChangePassword);
