import React from "react";
import AboutSection from "./AboutSection";
import FeatureCard from "./FeatureCard";
import Ball from "../../assets/images/svg/americanFootball 1.svg";
import ring from "../../assets/images/svg/ring.svg";
import trophy from "../../assets/images/svg/trophy.svg";
import medal from "../../assets/images/svg/medal.svg";
import Team from "./Team";
import team1 from "../../assets/images/teams/team1.png";
import team2 from "../../assets/images/teams/team2.png";
import team3 from "../../assets/images/teams/team3.png";
import team4 from "../../assets/images/teams/team4.png";
import team5 from "../../assets/images/teams/team5.png";
import team6 from "../../assets/images/teams/team6.png";
import team7 from "../../assets/images/teams/team7.png";
import team8 from "../../assets/images/teams/team8.png";
import SHTSpinner from "../../components/SHTSpinner";
import AboutHeader from "./AboutHeader";

const details = [
  {
    id: 0,
    image: Ball,
    head: "SHOWTIME CUP",
    title: "Winner of Showtime takes the cup ",
  },
  {
    id: 1,
    image: ring,
    head: "SHOWTIME RING",
    title: "Winner of Showtime takes the ring ",
  },
  {
    id: 2,
    image: trophy,
    head: "AWARDS",
    title: "Best players in the league get awards",
  },
  {
    id: 3,
    image: medal,
    head: "MEDALS",
    title: "Second runner up wins Gold medals",
  },
];

const teamDetails = [
  {
    id: 12,
    name: "Manal Nassar",
    post: "CEO",
    image: team7, // replace 'team' with the actual image source
  },
  {
    id: 12,
    name: "Esther",
    post: "PR &Comms",
    image: team8, // replace 'team2' with the actual image source
  },
  {
    id: 0,
    name: "Christiana Tantua",
    post: "HR",
    image: team6, // replace 'team' with the actual image source
  },
  {
    id: 1,
    name: "Leonard-James",
    post: "League Operations",
    image: team3, // replace 'team2' with the actual image source
  },
  {
    id: 2,
    name: "Adebare Adejumo",
    post: "League Commissioner",
    image: team1, // replace 'team3' with the actual image source
  },
  {
    id: 3,
    name: "Ivie Okuns",
    post: "Brand Manager",
    image: team4, // replace 'team4' with the actual image source
  },
  {
    id: 4,
    name: "Edgar Ayalogu",
    post: "Business Development Manager",
    image: team5, // replace 'team5' with the actual image source
  },
  {
    id: 5,
    name: "Chinedu Okoro",
    post: "Chief Financial Officer (CFO)",
    image: team2, // replace 'team6' with the actual image source
  },
];


const AboutPage = () => {
  return (
    <SHTSpinner loading={false}>
      <AboutHeader />
      <AboutSection />
      <FeatureCard trophyDetails={details} />
      <Team teamDetails={teamDetails} />
    </SHTSpinner>
  );
};

export default AboutPage;
