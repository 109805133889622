import React from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import "../assets/styles/components/sht_text_style.css";
import { ReactNode } from "react";

interface NTTextProps {
  size:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "strong"
    | "light"
    | "lighter"
    | "link"
    | "h1_light"
    | "h3_light"
    | "h2_light"
    | "small"
    | "cardP"
    | "cardLight";
  text: string | ReactNode;
  className?: any;
  link?: boolean;
  linkValue?: string;
  linkHref?: string;
  color?: any;
  icon?: any;
  handleClick?: any;
  newsReducer?: any;
}

const SHTText = ({
  size,
  text,
  className,
  link,
  linkValue,
  linkHref,
  color,
  icon,
  handleClick,
  newsReducer,
}: NTTextProps) => {
  switch (size) {
    case "h1":
      className = "sht_text_h1";
      break;
    case "h1_light":
      className = "sht_text_h1_light";
      break;

    case "h2":
      className = "sht_text_h2";
      break;
    case "h2_light":
      className = "sht_text_h2_light";
      break;
    case "h3":
      className = "sht_text_h3";
      break;
    case "h3_light":
      className = "sht_text_h3_light";
      break;

    case "h4":
      className = "sht_text_h4";
      break;

    case "h5":
      className = "sht_text_h5";
      break;

    case "h6":
      className = "sht_text_h6";
      break;

    case "p":
      className = "sht_text_p";
      break;

    case "strong":
      className = "sht_text_strong";
      break;

    case "light":
      className = "sht_text_light";
      break;
    case "lighter":
      className = "sht_text_lighter";
      break;
    case "link":
      className = "sht_text_link";
      break;
    case "cardP":
      className = "sht_text_card_p";
      break;
    case "cardLight":
      className = "sht_text_card_light";
      break;
  }

  switch (color) {
    case "primary":
      className += " sht_text_primary";
      break;

    case "danger":
      className += " sht_text_danger";
      break;
    case "yellow":
      className += " sht_text_yellow";
      break;

    case "green":
      className += " sht_text_green";
      break;

    case "warning":
      className += " sht_text_warning";
      break;

    case "active":
      className += " sht_text_active";
      break;

    case "white":
      className += " sht_text_white";
      break;

    case "white_important":
      className += " sht_text_white_important";
      break;

    case "gray":
      className += " sht_text_gray";
      break;

    case "gray_important":
      className += " sht_text_gray_important";
      break;
    case "blue":
      className += " sht_text_blue";
      break;
    case "small":
      className += " sht_text_small";
      break;

    default:
      className += " sht_text_primary";
      break;
  }
  return (
    <span onClick={handleClick} className={handleClick ? "sht_edit_btn" : ""}>
      <p
        className={
         className
          
        }
      >
        {icon && icon}
        {text}
        {link && (
          <a href={linkHref} className="sht_link">
            {linkValue}
          </a>
        )}
      </p>
    </span>
  );
};

SHTText.defaultProps = {
  text: "",
  link: false,
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTText);
