import React, { FC } from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "../../assets/styles/page/team_week_card_style.css";
import SHTText from "../../components/SHTText";
import SHTNoResult from "../../components/SHTNoResult";

type cardProps = {
  id?: number;
  profileImage?: string;
  name?: string;
  att?: string;
  comp?: string;
  tds?: string;
  xp?: string;
  int?: string;
  clubImage?: string;
  position?: string;
};

interface cardInterface {
  cardDetails: cardProps[];
  loading: any;
}

const TeamWeekCard = ({ cardDetails, loading }: cardInterface) => {
  return (
    <Container className="sht_container_space">
      {cardDetails.length > 0 ? (
        <div>
          {cardDetails.map((detail: cardProps, index: number) => {
            return (
              <section
                className="d-flex justify-content-between align-items-center team_card_wrapper my-3"
                key={index}
              >
                <img
                  src={detail.profileImage}
                  alt="Team Image"
                  className="player_card_image"
                />
                <SHTText size="h1" color="blue" text={detail.position} />
                <div className="attribute">
                  <SHTText size="h3" color="white" text={detail.name} />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex p-1">
                      <SHTText size="p" color="gray" text="ATT:" />
                      <SHTText size="p" color="gray" text={detail.att} />
                    </div>
                    <div className="d-flex p-1">
                      <SHTText size="p" color="gray" text="%COMP:" />
                      <SHTText size="p" color="gray" text={detail.comp} />
                    </div>
                    <div className="d-flex p-1">
                      <SHTText size="p" color="gray" text="TDS:" />
                      <SHTText size="p" color="gray" text={detail.tds} />
                    </div>
                    <div className="d-flex p-1">
                      <SHTText size="p" color="gray" text="XP:" />
                      <SHTText size="p" color="gray" text={detail.xp} />
                    </div>
                    <div className="d-flex p-1">
                      <SHTText size="p" color="gray" text="INT:" />
                      <SHTText size="p" color="gray" text={detail.int} />
                    </div>
                  </div>
                </div>
                <div className="px-2">
                  <img
                    src={detail.clubImage}
                    alt="Team Image"
                    className="team_card_image"
                  />
                </div>
              </section>
            );
          })}
        </div>
      ) : (
        <SHTNoResult loading={loading} />
      )}
    </Container>
  );
};

export default TeamWeekCard;
