import React, { FC } from "react";

import PlayButton from "../assets/images/svg/playButton2.svg";
import { Container } from "react-bootstrap";
import "../assets/styles/components/sht_game_card_style.css";
import DropDown from "../assets/images/svg/dropdown.svg";
import SHTText from "./SHTText";

interface CardInputFieldProps {
  id?: number;
  teamImage?: string;
  teamName?: string;
  time?: string;
  plays?: string;
  start?: string;
  children?: React.ReactNode;
  style?: any;
  alt?: string;
  handleOnClick?: any;
  handleOpenDetails: any;
  open: any;
  dropdownDetails: any;
}

const SHTGameCard: FC<CardInputFieldProps> = ({
  id,
  teamName,
  teamImage,
  start,
  open,
  handleOpenDetails,

  dropdownDetails,
}) => {
  return (
    <section className="game_card_container">
      <div
        className="game_card d-flex justify-content-between pointer"
        onClick={() => handleOpenDetails(id)}
      >
    
          <div className="d-flex align-items-center mt-3">
            <img
              src={teamImage}
              alt="Lagos Bull image"
              width="53px"
              className="card_team_image"
            />
            <SHTText size="strong" color="white" text={teamName} />
          </div>

          <div className="mx-3 align-items-center mt-4">
            <SHTText size="p" color="white" text={4} />
            <SHTText size="p" color="gray" text="Plays" />
          </div>
          <div className="mx-3 align-items-center mt-4">
            <SHTText size="p" color="white" text={start} />
            <SHTText size="p" color="gray" text="start" />
          </div>
          {/* <div>
          <SHTText size="p" color="white" text={time} />
          <SHTText size="p" color="gray" text="Total time" />
        </div> */}

          <div className="d-flex mx-3 align-items-center mt-3">
            <img src={PlayButton} alt="Play button image" />
            <img
              src={DropDown}
              alt="Drop down icon"
              className="dropdown_button"
            />
          </div>
 
      </div>
      {open && (
        <div className="open_game_card">
          {dropdownDetails.map((detail: any) => {
            return (
              <main className="game_sub_card">
                <>
                  <div>
                    <SHTText text={detail.team} size="cardP" color="white" />
                    <SHTText
                      text={detail.subCardTitle}
                      size="cardLight"
                      color="gray"
                    />
                    <div className="game_sub_down_text">
                      <SHTText
                        text={detail.downPosition}
                        size="cardLight"
                        color="gray"
                      />
                    </div>
                  </div>
                  {detail.video_link && (
                    <a href={detail.video_link}>
                      <img src={PlayButton} alt="Play button image" />
                    </a>
                  )}
                </>
              </main>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default SHTGameCard;
