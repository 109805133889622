import React from "react";
import "../../assets/styles/page/game_header_style.css";
import lagosRebel from "../../assets/images/svg/lagosRebels.svg";
import titansLogo from "../../assets/images/svg/titansLogo.svg";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";

type gameHeaderProps = {
  homeTeamImage: string;
  homeTeamName: string;
  homeTeamAltText: string;
  homeTeamPosition: string;
  homeTeamScore: string;
  awayTeamImage: string;
  awayTeamName: string;
  awayTeamAltText: string;
  awayTeamPosition: string;
  awayTeamScore: string;
};

interface cardInterface {
  gameHeader: any;
}

const GameHeader = ({ gameHeader }: cardInterface) => {
  return (
    <section>
      <div className="d-flex justify-content-between align-items-center game_header_container">
        <div
          className="game_header_left"
          style={{ backgroundColor: `${gameHeader.homeTeamColor}` }}
        ></div>
        <div
          className="game_header_right"
          style={{ backgroundColor: `${gameHeader.awayTeamColor}` }}
        ></div>

        <Container className="game_header_details">
          <div className="d-flex align-items-center">
            <div>
              <img
                src={gameHeader.homeTeamImage}
                alt={gameHeader.homeTeamAltText}
                className="team_logo"
              />
            </div>
            <div className="mx-5">
              <SHTText size="p" text={gameHeader.homeTeamName} color="gray" />
              {/* <SHTText size="p" text={gameHeader.homeTeamPosition} color="gray" /> */}
            </div>
            <div>
              <SHTText size="h4" text={gameHeader.homeTeamScore} color="gray" />
            </div>
          </div>
          <div>
            <SHTText size="h4" text="VS" color="white" />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <SHTText size="h4" text={gameHeader.awayTeamScore} color="gray" />
            </div>
            <div className="mx-5">
              <SHTText size="p" text={gameHeader.awayTeamName} color="gray" />
              {/* <SHTText size="p" text={gameHeader.awayTeamPosition} color="gray" /> */}
            </div>
            <div>
              <img
                src={gameHeader.awayTeamImage}
                alt={gameHeader.awayTeamAltText}
                className="team_logo"
              />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default GameHeader;
