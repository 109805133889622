import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { useParams } from "react-router-dom";
import TeamScheduleHeader from "./TeamScheduleHeader";
import TeamScheduleDetailFilter from "./TeamScheduleDetailFilter";
import TeamScheduleCardDetail from "./TeamScheduleCardDetails";
import SHTSpinner from "../../components/SHTSpinner";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ScoreFilter from "../score/ScoreFilter";
import { handleGetWinner } from "../../utils/helper";
interface TeamScheduleDetailsProps {
  fetchScheduleDetails: Function;
}

type MyParams = {
  id: string;
};

function TeamScheduleDetailsPage(props: TeamScheduleDetailsProps) {
  const navigate = useNavigate();
  const { fetchScheduleDetails } = props;
  const location = useLocation();
  const teamImageNav = location.state && location.state.image;

  const [loading, setLoading] = useState<boolean>(true);
  const [teamImage, setTeamImage] = useState<any>(null);

  const [schedule, setSchedule]: any = useState({});
  const { id } = useParams<MyParams>();

  useEffect(() => {
    handleTeamSchedule(id, null);
  }, []);

  const handleTeamSchedule = async (id: any, params: any) => {
    setSchedule([]);
    setLoading(true);

    await fetchScheduleDetails(id, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          if (response.data.results.length >= 1) {
            if (response.data.results[0]?.away_team?.team_id === id) {
              setTeamImage(
                response.data.results[0]?.away_team?.team_image[0]?.image
              );
            } else {
              setTeamImage(
                response.data.results[0]?.home_team?.team_image[0]?.image
              );
            }
          } else {
            setTeamImage(teamImageNav);
          }

          const newArray = response.data.results.map((obj: any) => {
            return {
              id: obj.id,
              week: obj.week.week_number,
              date: obj.date,
              teamName:
                obj.away_team.team_id === id
                  ? obj.home_team.team_name
                  : obj.away_team.team_name,
              time: obj.time,
              location: obj.location,
              score: obj.away_team.score + " - " + obj.home_team.score,
              winner: handleGetWinner(obj.winner, id),
              image:
                obj.away_team.team_id === id
                  ? obj?.home_team?.team_image[0]?.image
                  : obj?.away_team?.team_image[0]?.image,
            };
          });

          setSchedule(newArray);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const handleNavigate = (id: string) => {
    navigate(`/games/${id}`);
  };

  return (
    <SHTSpinner loading={false}>
      <TeamScheduleHeader teamImage={teamImage ? teamImage : teamImageNav} />
      <ScoreFilter
        subTitle="SHOWTIME SCHEDULE"
        title={"SCHEDULES"}
        handleFilter={(filter: string) => handleTeamSchedule(id, filter)}
      />

      <TeamScheduleCardDetail
        scheduleDetail={schedule}
        loading={loading}
        handleOnClick={(id: string) => handleNavigate(id)}
      />
    </SHTSpinner>
  );
}

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamScheduleDetailsPage);
