import React from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTButton from "../../components/SHTButton";
import { PHONE_NUMBER_INPUT_VALIDATION } from "../../utils/validation";
import useFormPersistence from "../../utils/helper/useFormPersist";

interface FormInputs {
  lead_owner: string;
  phone_number: string;
  co_owner1: string;
  co_owner1_phonenumber: string;
  co_owner2_phonenumber: string;
  co_owner2: string;
  team_coach: string;
  offensive_captain: string;
  defensive_captain: string;
}
const TeamManagement = ({ handleSubmitData }: any) => {
  let loginInitalValues: FormInputs = {
    lead_owner: "",
    phone_number: "",
    co_owner1: "",
    co_owner1_phonenumber: "",
    co_owner2_phonenumber: "",
    co_owner2: "",
    team_coach: "",
    offensive_captain: "",
    defensive_captain: "",
  };

  const handleSubmitForm = (data: any) => {
    const payload = {
      team_executive: [
        {
          name: data.lead_owner,
          phone_number: data.phone_number,
          role: "Owner",
        },
        {
          name: data.co_owner1,
          phone_number: data.co_owner1_phonenumber,
          role: "Co Owner 1",
        },
        {
          name: data.co_owner2,
          phone_number: data.co_owner2_phonenumber,
          role: "Co Owner 2",
        },
        {
          name: data.offensive_captain,
          role: "Offensive Captain",
        },
        {
          name: data.defensive_captain,
          role: "Defensive Captain",
        },
        {
          name: data.team_coach,
          role: "Team Coach",
        },
      ],
    };

    handleSubmitData(payload);
  };

  const {
    control,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });
  // Use the custom hook to handle form persistence
  useFormPersistence<FormInputs>("teamManagement", setValue, watch);

  const onSubmit = (data: any) => console.log(data);
  return (
    <section className="first_form_container team_management" style={{position: "relative"}}>
      <div className="pb-3">
        <SHTText size="h2" text="Team Management" color="gray" />
      </div>
      <div className="form_list">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb first_form">
            <Controller
              control={control}
              name="lead_owner"
              rules={{
                required: "Lead owner is needed",
              }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Lead Owner"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={errors.lead_owner && errors.lead_owner.message}
                />
              )}
            />
          </div>
          <div className="first_form ml-3">
            <Controller
              control={control}
              name="phone_number"
          
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Phone Number"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={errors.phone_number && errors.phone_number.message}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb first_form">
            <Controller
              control={control}
              name="co_owner1"
             
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Co-owner #1"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={errors.co_owner1 && errors.co_owner1.message}
                />
              )}
            />
          </div>
          <div className="first_form ml-3">
            <Controller
              control={control}
              name="co_owner1_phonenumber"
            
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Phone Number"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={
                    errors.co_owner1_phonenumber &&
                    errors.co_owner1_phonenumber.message
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb first_form">
            <Controller
              control={control}
              name="co_owner2"
             
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Co-owner #2"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={errors.co_owner2 && errors.co_owner2.message}
                />
              )}
            />
          </div>
          <div className="first_form ml-3">
            <Controller
              control={control}
              name="co_owner2_phonenumber"
           
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Phone Number"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={
                    errors.co_owner2_phonenumber &&
                    errors.co_owner2_phonenumber.message
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="">
          <Controller
            control={control}
            name="team_coach"
           
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Team Coach"
                placeholder=""
                type="messageText"
                value={value}
                // editable={loading}
                onChangeText={onChange}
                error={errors.team_coach && errors.team_coach.message}
              />
            )}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="mb first_form">
            <Controller
              control={control}
              name="offensive_captain"
             
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Offensive Captain"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={
                    errors.offensive_captain && errors.offensive_captain.message
                  }
                />
              )}
            />
          </div>
          <div className="first_form ml-3">
            <Controller
              control={control}
              name="defensive_captain"
             
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Defensive Captain"
                  placeholder=""
                  type="messageText"
                  value={value}
                  // editable={loading}
                  onChangeText={onChange}
                  error={
                    errors.defensive_captain && errors.defensive_captain.message
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="team_next">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(handleSubmitForm)}
        />
      </div>
    </section>
  );
};

export default TeamManagement;
