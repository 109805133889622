import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import NextMatch from "./NextMatch";
import WeeklyResult from "./WeeklyResult";
import DivisionPage from "./DivisionPage";
import SHTNewsSection from "../../components/SHTNewsSection";
import RegisterTeam from "./RegisterTeam";
import team from "../../assets/images/svg/team.svg";
import baseball from "../../assets/images/svg/baseball.svg";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import ThrowBack from "./ThrowBack";
import Highlight from "./Highlight";
import BuyTickets from "./BuyTickets";
import "../../assets/styles/page/sh_home_page_style.css";
import HomepageCarousel from "./HomepageCarousel";
import SHTSpinner from "../../components/SHTSpinner";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/helper";
import ScoreFilter from "../score/ScoreFilter";
const HomePage = ({
  fetchtrendingNews,
  fetchLatestHighlight,
  fetchlatestNews,
  fetchNextGames,
  fetchGames,
  teamStandings,
  newsReducer,
}: any) => {
  const navigate = useNavigate();

  const [highLightNews, setHighLightNews]: any = useState([]);
  const [trendingNews, setTrendingNews]: any = useState([]);
  const [highlights, setHighlights]: any = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [highlightWeek, setHighlightWeek] = useState(null);
  const [latesNewsLoading, setFetchLatestNewsLoading] = useState(true);
  const [latesNextMatchLoading, setFetchLatestNextMatchLoading] =
    useState(true);
  const [nextMatch, setNextMatch] = useState([]);
  const [highlightsLoading, setHighlightsLoading] = useState(true);
  const [trendingNewsLoading, setTrendingNewsLoading] = useState(true);
  const [gamesLoading, setGamesLoading] = useState(true);
  const [scoreDetails, setScoreDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [standingsData, setStandings] = useState([]);
  const [standingsData2, setStandings2] = useState([]);

  const carousel_values = [
    {
      id: 1,
      image: "../../assets/images/svg/carousel_background2.svg",
      title: "GET THE ULTIMATE EXPERIENCE BY WATCHING SHOWTIME!",
      subtitle:
        "Grab your tickets and join us every sunday to experience exciting games and watch wonderful skilled players while they entertain us in the world of flag football.",
      button: {
        name: "learn more",
        action: () => carouselButtonNavigation(),
      },
    },
    {
      id: 2,
      image: "https://via.placeholder.com/252x168",
      title: "GET THE ULTIMATE EXPERIENCE BY WATCHING SHOWTIME!",
      subtitle:
        "Grab your tickets and join us every sunday to experience exciting games and watch wonderful skilled players while they entertain us in the world of flag football.",
      button: {
        name: "learn more",
        action: () => carouselButtonNavigation(),
      },
    },
    {
      id: 3,
      image: "https://via.placeholder.com/252x168",
      title: "GET THE ULTIMATE EXPERIENCE BY WATCHING SHOWTIME!",
      subtitle:
        "Grab your tickets and join us every sunday to experience exciting games and watch wonderful skilled players while they entertain us in the world of flag football.",
      button: {
        name: "learn more",
        action: () => carouselButtonNavigation(),
      },
    },
  ];

  const carouselButtonNavigation = () => {};

  const throwBack = [
    {
      id: "1",
      header: "2023 Showtime Bowl Game",
      subText: "Here is a throwback for season x champions.",
    },
  ];

  const handleNavigate = (id: string) => {
    navigate(`/highlightdetails/${id}`);
  };

  const handleNavigateNews = (id: string) => {
    navigate(`/news/${id}`);
  };

  const handleNavigateToFixtures = () => {
    navigate(`/league/schedule`);
  };
  const handleNavigateToScoresPage = () => {
    navigate(`/scores`);
  };

  const handleThrowbackClick = () => {
    navigate("/highlightdetails/e4214081-98bf-4a80-ade9-a86b2ea23666");
  };
  const handleViewAllNews = () => {
    navigate(`/news`);
  };

  useEffect(() => {
    handleTrendingNewsFetch();
    handlePreviewNewsFetch();
    handleLatestNewsFetch();
    handleNextMatchFetch();
    handleFetchGames(null);
    handleGetStandings({
      division: newsReducer?.divisions[0]
        ? newsReducer?.divisions[0]
        : "DIVISION ONE",
    });
    handleGetStandings2({
      division: newsReducer?.divisions[1]
        ? newsReducer?.divisions[1]
        : "DIVISION TWO",
    });
  }, []);

  const handleGetStandings = async (division: any) => {
    setLoading(true);
    await teamStandings(division)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const standings = response.data.results.map((data: any) => {
            return {
              id: data.id,
              image: data?.team?.team_image[0]?.image,
              teamName: data.team.name,
              win: `${data.wins} ${data.losses} ${data.draws}`,
              loose: data.losses,
              draw: data.draws,
              pct: data.pct,
              pf: data.points_for,
              pa: data.points_against,
              nets: data.net_point,
              strk: data.strk,
              last: data.last5,
            };
          });

          setStandings(standings);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleGetStandings2 = async (division: any) => {
    setLoading(true);
    await teamStandings(division)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const standings = response.data.results.map((data: any) => {
            return {
              id: data.id,
              image: data?.team?.team_image[0]?.image,
              teamName: data.team.name,
              win: `${data.wins} ${data.losses} ${data.draws}`,
              loose: data.losses,
              draw: data.draws,
              pct: data.pct,
              pf: data.points_for,
              pa: data.points_against,
              nets: data.net_point,
              strk: data.strk,
              last: data.last5,
            };
          });

          setStandings2(standings);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleTrendingNewsFetch = async () => {
    setTrendingNewsLoading(true);
    await fetchtrendingNews()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTrendingNews(response.data.results);
          setTrendingNewsLoading(false);
        } else {
          setTrendingNewsLoading(false);
        }
      })
      .catch((err: any) => {
        setTrendingNewsLoading(false);
      });
  };
  const handleNextMatchFetch = async () => {
    setFetchLatestNextMatchLoading(true);
    await fetchNextGames()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const newArray = response.data.results.map((obj: any) => {
            return {
              homeTeamImage: obj.home_team.team_image[0]?.image,
              awayTeamImage: obj.away_team.team_image[0]?.image,
              date: formatDate(obj.date),
              time: formatTime(obj.time),
              address: obj.location,
            };
          });
          setNextMatch(newArray);
          setFetchLatestNextMatchLoading(false);
        } else {
          setFetchLatestNextMatchLoading(false);
        }
      })
      .catch((err: any) => {
        setFetchLatestNextMatchLoading(false);
      });
  };

  const handlePreviewNewsFetch = async () => {
    setHighlightsLoading(true);
    await fetchLatestHighlight()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setHighlightWeek(response.data.week_number);
          setHighlights(response.data.results);
          setHighlightsLoading(false);
        } else {
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };

  const handleLatestNewsFetch = async () => {
    setFetchLatestNewsLoading(true);
    await fetchlatestNews()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setLatestNews(response.data.results);
          setFetchLatestNewsLoading(false);
        } else {
          setFetchLatestNewsLoading(false);
        }
      })
      .catch((err: any) => {
        setFetchLatestNewsLoading(false);
      });
  };

  const handleFetchGames = async (filter: any) => {
    setScoreDetails([]);
    setGamesLoading(true);
    const params = {
      limit: 6,
    };

    const updatedParams = {
      ...params,
      ...filter,
    };

    await fetchGames(updatedParams)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const newArray = response.data.results.map((obj: any) => {
            return {
              week: obj.week.week_number,
              date: obj.date,
              time: obj.time,
              firstTeam: obj.home_team.team_name,
              secondTeam: obj.away_team.team_name,
              firstScore: obj.home_team.score,
              secondScore: obj.away_team.score,
            };
          });

          setScoreDetails(newArray);
          setGamesLoading(false);
        } else {
          setGamesLoading(false);
        }
      })
      .catch((err: any) => {
        setGamesLoading(false);
      });
  };

  const handleRegisterTeam = () => {
    navigate("/team-registration");
  };

  const handleRegisterPlayer = () => {
    navigate("/player-registration");
  };

  return (
    <SHTSpinner loading={false}>
      <HomepageCarousel HomepageCarouselValue={carousel_values} />

      <NextMatch
        loading={latesNextMatchLoading}
        nextMatchObject={nextMatch}
        handleNavigateToFixtures={handleNavigateToFixtures}
      />

      <WeeklyResult
        results={scoreDetails}
        handleClick={handleNavigateToScoresPage}
        loading={gamesLoading}
        handleFilter={(filter: string) => handleFetchGames(filter)}
      />
      <DivisionPage
        loading={loading}
        DivisionTableValueOne={standingsData}
        DivisionTableValueTwo={standingsData2}
      />
      <SHTNewsSection
        loading={latesNewsLoading}
        latestNews={latestNews}
        handleOnClick={handleNavigateNews}
        handleViewAllNews={handleViewAllNews}
      />
      <RegisterTeam
        registerTeam={handleRegisterTeam}
        registerPlayer={handleRegisterPlayer}
      />
      <ThrowBack
        ThrowbackValue={throwBack}
        handleClick={handleThrowbackClick}
      />
      <Highlight
        loadingHighlights={highlightsLoading}
        loadingTrendingNews={trendingNewsLoading}
        trendingNews={trendingNews}
        handleOnClick={handleNavigate}
        handleOnClickNews={handleNavigateNews}
        highlights={highlights}
        week={highlightWeek ? highlightWeek : "0"}
      />

      <BuyTickets />
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(HomePage);
