import React, { useRef, useEffect, useState } from "react";
import SHTText from "../components/SHTText";
// import Line from "../../assets/images/svg/Line 23.svg";
import Line from "../assets/images/svg/Line 23.svg";
import { newsInterface } from "../interfaces/axios.dto";
import SHTCard from "../components/SHTCard";
import { Container } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import SHTLineHeader from "../components/SHTLineHeader";
import SHTNoResult from "./SHTNoResult";
interface PropsInterface {
  trendingNews: newsInterface[];
  title: string;
  handleOnClick: any;
  loading: boolean;
}

const SHTTrendingNews = (props: PropsInterface) => {
  const { trendingNews, title, handleOnClick, loading } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleScrollClick = (scrollOffset: number) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += scrollOffset;
    }
  };
  return (
    <div className="horizontal-scroll-container">
      <Container className="sht_container_space">
        <div >
          <SHTLineHeader headerText={title} />
        </div>
        <div
          className="d-flex overflow-scroll sht_card_details"
          ref={containerRef}
        >
          {trendingNews?.length > 0 ? (
            <>
              {trendingNews.map((news: newsInterface) => (
                <div key={news.id} style={{ paddingRight: 40 }}>
                  <SHTCard
                    handleOnClick={handleOnClick}
                    id={news.id}
                    alt={news?.alt_text}
                    //@ts-ignore
                    image={news?.featured_image?.image}
                    title={news.title}
                    date={news.created_at}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="no_result_container">
              <SHTNoResult loading={loading} />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div onClick={() => handleScrollClick(-1280)}>
            <IoIosArrowBack className="text-white" />
          </div>{" "}
          <div onClick={() => handleScrollClick(1280)} className="text-white">
            <IoIosArrowForward />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SHTTrendingNews;
