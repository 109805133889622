import React from "react";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import { formatStat } from "../../utils/helper";

type statIntefaceProps = {
  id?: string;
  team?: string;
  pa?: string;
  rec?: string;
  cp?: string;
  drops?: string;
  tds?: string;
  xtds?: string;
  int?: string;
  image?: string;
};

interface statsDetails {
  statsDetails: statIntefaceProps[];
  handleClick: any;
}
const TeamOffenseStats = (props: statsDetails) => {
  const { statsDetails, handleClick } = props;
  return (
    <Container className="sht_container_space">
      <div className="stats_table_container">
        <table>
          <tr className="stats_table_header">
            <th className="py-4 px-3">
              <SHTText size="p" color="white" text="Team" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="PA" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="CP" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="REC" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="DROPS" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="TDS" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="XTDS" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="INT" />
            </th>
          </tr>
          {statsDetails.map((stat: statIntefaceProps, index: number) => {
            return (
              <tr
                key={index}
                className="stats_table_data"
                onClick={() => handleClick(stat?.id)}
              >
                <td className="py-3 ">
                  <div className="d-flex align-items-center">
                    <img
                      src={stat.image}
                      alt="Profile Image"
                      className="team_image_tiny"
                    />
                    <div className="mx-2">
                      <SHTText size="p" color="gray" text={stat.team} />
                    </div>
                  </div>
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.pa)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.cp)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.rec)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText
                    size="p"
                    color="gray"
                    text={formatStat(stat.drops)}
                  />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.tds)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.xtds)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.int)} />
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </Container>
  );
};

export default TeamOffenseStats;
