import React from "react";
import SHTScheduleCard from "../../components/SHTScheduleCard";
import { Container } from "react-bootstrap";
import SHTNoResult from "../../components/SHTNoResult";

interface propsInterface {
  scheduleDetail: any;
  loading: boolean;
  handleOnClick: Function;
}

const TeamScheduleCardDetail = (props: propsInterface) => {
  const { scheduleDetail, loading, handleOnClick } = props;
  return (
    <Container>
      {scheduleDetail?.length > 0 ? (
        <>
          {scheduleDetail.map((detail: any) => {
            return (
              <div key={detail.id} className="py-3">
                <SHTScheduleCard
                
                  week={detail.week}
                  date={detail.date}
                  stand={detail.winner}
                  score={detail.score}
                  image={detail.image}
                  teamName={detail.teamName}
                  time={detail.time}
                  location={detail.location}
                  handleOnClick={() => handleOnClick(detail.id)}
                />
              </div>
            );
          })}
        </>
      ) : (
        <SHTNoResult loading={loading} />
      )}
    </Container>
  );
};

export default TeamScheduleCardDetail;
