import Cookies from "js-cookie";
import axios from "../../utils/axios";

export const fetchTeams = (params: any) => async () => {
  return axios.get(`/teams/all-teams/`, { params });
};

export const fetchTeamsById = (id: any) => async () => {
  return axios.get(`/teams/all-teams/${id}/`);
};

export const editTeamInformation = (payload: any, id: string) => async () => {
  return axios.put(`/teams/team/edit-team/${id}/`, payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const approveEdit =
  (id: string, params: any, token: string) => async () => {
    return axios.get(`/teams/team/edit-team/${id}/`, { params });
  };

export const approveContractTermination =
  (id: string, params: any, token: string) => async () => {
    return axios.get(`/teams/team-contract/${id}/`, { params });
  };

export const fetchScheduleDetails = (id: any, params: any) => async () => {
  return axios.get(`/games/team-schedule/${id}/`, { params });
};
export const fetchTeamWeek = (params: any) => async () => {
  return axios.get(`/teams/team-of-the-week/`, { params });
};
export const fetchTeamExecutive = (id: any, params: any) => async () => {
  return axios.get(`/teams/team-executive/${id}/`, { params });
};
export const fetchPlayerOfTheWeek = (id: any, params: any) => async () => {
  return axios.get(`/teams/player-of-the-week/${id}/`, { params });
};
export const fetchTeamRoster = (params: any) => async () => {
  return axios.get(`teams/team-player/`, { params });
};

export const getPlayerStats = (params: any) => async () => {
  return axios.get(`teams/player-stats/`, { params });
};

export const fetchCareerStatsById = (params: any) => async () => {
  return axios.get(`teams/player-team-history/${params}/`);
};

export const fetchPlayerById = (id: any) => async () => {
  return axios.get(`teams/team-player/${id}/`);
};

export const getTeamStats = (params: any) => async () => {
  return axios.get(`games/team-passing/`, { params });
};
export const teamStandings = (params: any) => async () => {
  return axios.get(`division/division-standing/`, { params });
};

export const fetchPlayerCareerStatsById =
  (info: any, params: any) => async () => {
    return axios.get(
      `/teams/player-team-stat/${info.team_id}/${info.player_id}/`,
      { params }
    );
  };

export const teamRegistration = (payload: any) => async () => {
  return axios.post(
    `/teams/create-teams/`,
    payload /* , {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  } */
  );
};

export const teamImageSubmit = (payload: any) => async () => {

  const formData = new FormData();
  formData.append('image', payload.image);
  return axios.put(`/teams/create-teams/`, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const fetchTeamContracts = (payload: any) => async () => {
  return axios.get(`/teams/team-contract/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const fetchPlayers = (payload: any) => async () => {
  return axios.get(`/teams/all-players/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const fetchPlayersWithoutContract = (payload: any) => async () => {
  return axios.get(`/teams/team-players/no-contract/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const fetchTeamPlayers = (payload: any) => async () => {
  return axios.get(`/teams/team-player/?team_id=${payload}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const createContracts = (payload: any) => async () => {
  return axios.post(`/teams/team-contract/`, payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const sendContracts = (payload: any) => async () => {
  return axios.post(`/teams/team-contract/`, payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const updateContracts =
  (contract_id: string, payload: any) => async () => {
    return axios.put(`/teams/team-contract/${contract_id}/`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Cookies.get("auth_token")}`,
      },
    });
  };

export const playerRegistration = (payload: any) => async () => {
  return axios.post(`/teams/create-players/`, payload);
};
export const gamePosition = () => async () => {
  return axios.get(`/teams/all-position/`);
};
