import React from "react";
import { Container } from "react-bootstrap";
import '../../assets/styles/page/team_info_schedule_style.css'
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import SHTNoResult from "../../components/SHTNoResult";

type infoSchedule = {
    week?: string,
    date?: string,
    game?: string
    time?: string
}
interface InfoScheduleProps {
    infoSchedules: any
    gameDetaiils: any
    handleClick: any
    loading: any
}
const TeamInfoSchedule = (props: InfoScheduleProps) => {
    const { infoSchedules, gameDetaiils, handleClick, loading } = props
    return (
        <Container style={{ width: "100%" }} className="sht_container_space overflow-auto">
            <section className="team_info_schedule_container ">
                {gameDetaiils.map((details: any) => {
                    return (
                        <div>
                            <div className="p-4">
                                <SHTText size="h3" text='SCHEDULE' color='white' />
                                <SHTText size="p" text={`PREVIOUS GAME - ${details.date}`} color='gray' />
                            </div>
                            <div className="d-lg-flex align-items-center mx-4">
                                <div className="game_type_text">
                                    <SHTText size="p" text={details.type || 'CURRENT'} color='gray' />
                                </div>
                                <div className="d-flex justify-content-around align-items-center w-100">
                                    <div className="d-flex align-items-center ">
                                        <div className="text-center ">
                                            <img src={details.home_team_image} alt="titan logo" className="team_ifo_image" />
                                            <SHTText size="p" text={details.home_team_name} color='white' />
                                        </div>
                                        <div className="mx-5">
                                            <SHTText size="h4" text={details.home_team_score} color='white' />
                                        </div>

                                    </div>
                                    <div>
                                        <SHTText size="p" text='VS' color='gray' />

                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="mx-5">
                                            <SHTText size="h4" text={details.away_team_score} color='white' />
                                        </div>
                                        <div className="text-center">
                                            <img src={details.away_team_image} alt="titan logo" className="team_ifo_image" />
                                            <SHTText size="p" text={details.away_team_name} color='white' />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })}

                <div>
                    <div className="px-4 py-3 stats_table_text ">
                        <SHTText size="p" text='UPCOMING GAMES' color='gray' />

                    </div>
                    {infoSchedules.length > 0 ? <div className="team_info_container"> <table  >
                        <tr className="stats_table_header schedule_table_header">
                            <th className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="white" text="Week" /></th>
                            <th className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="white" text="Date" /></th>
                            <th className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="white" text="Game" /></th>
                            <th className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="white" text="Time" /></th>
                        </tr>
                        {
                            infoSchedules.map((schedules: any, index: number) => {
                                return (
                                    <tr className="stats_table_header">
                                        <td className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="gray" text={`REG ${schedules.week.week_number}`} /></td>
                                        <td className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="gray" text={schedules.date} /></td>
                                        <td className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="gray" text={schedules.away_team.team_name} /></td>
                                        <td className="p-lg-5 p-2 stats_table_cell schedule_table_cell"><SHTText size="p" color="gray" text={schedules.time} /></td>
                                    </tr>
                                )
                            })
                        }
                    </table> </div> : <SHTNoResult loading={loading} />
                    }
                </div>
                <div className="d-flex justify-content-center py-5">
                    <SHTButton type="submit" color="primary" name="FULL SCHEDULE" handleClick={handleClick} />
                </div>
            </section>
        </Container>
    )
}

export default TeamInfoSchedule