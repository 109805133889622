import React, { useEffect, useState } from "react";
import "../../assets/styles/page/latest_news_filter_style.css";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import SHTText from "../../components/SHTText";
import Dropdown from "react-bootstrap/Dropdown";
import SHTButton from "../../components/SHTButton";
import { RequestResponseInterface } from "../../interfaces/axios.dto";

const RoasterFilter = ({
  page,
  handleNavigate,
  name,
  getUserDetails,
  id,
}: any) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [showContract, setshowContract] = useState(false);

  useEffect(() => {
    handleFetchUser();
  }, []);

  const handleFetchUser = async () => {
    await getUserDetails()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          if (response.data.response_code === 3000) {
            if (response.data.data._id === id) {
              setshowContract(true);
            }
          }
        }
      })
      .catch((err: any) => {});
  };

  return (
    <section className="latest_news_filter">
      <Container>
        <div className="py-4">
          <div className="">
            <SHTText size="p" text="SHOWTIME TEAMS" color="gray" />
          </div>
          <div>
            <SHTText size="h2" text={name} color="white" />
          </div>
        </div>
        <div className="d-flex justify-content-between py-4 overflow-auto">
          <div className="d-flex">
            <SHTButton
              type="submit"
              size="md"
              name="INFO"
              color={page === "info" ? `stats_primary` : `stats_secondary`}
              handleClick={() => handleNavigate("info")}
            />
            <SHTButton
              type="submit"
              name="ROASTER"
              color={page === "roaster" ? `stats_primary` : `stats_secondary`}
              className="mx-3"
              handleClick={() => handleNavigate("roaster")}
            />
            {/* <SHTButton
              type="submit"
              name="STATS"
              color={page === "stats" ? `stats_primary` : `stats_secondary`}
              handleClick={() => handleNavigate("stats")}
            /> */}
            {showContract && (
              <SHTButton
                type="submit"
                name="CONTRACTS"
               
                color={
                  page === "contracts" ? `stats_primary` : `stats_secondary`
                }
                handleClick={() => handleNavigate("contracts")}
              />
            )}

            {showContract && (
              <SHTButton
                type="submit"
                name="EDIT TEAM"
                className="mx-3"
                color={
                  page === "contracts" ? `stats_primary` : `stats_secondary`
                }
                handleClick={() => handleNavigate("edit-team")}
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});
export default connect(mapStateToProps, actions)(RoasterFilter);
