import React, { useEffect, useState } from "react";
import GameHeader from "./GameHeader";
import GameDetails from "./GameDetails";
import lagosBull from "../../assets/images/svg/lagosBull.svg";
import SHTSpinner from "../../components/SHTSpinner";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

type MyParams = {
  id: string;
  team_id: string;
};

const GamePage = ({
  fetchGamesHighlights,
  fetchGameDetailByTeam,
  fetchGameDetail,
  fetchGameStat,
  fetchGameQuarter,
  fetchGameTeamStats,
}: any) => {
  const [highlightsLoading, setHighlightsLoading] = useState(true);
  const [openGameDetails, setOpenGameDetails] = useState(false);
  const [gameQuarterLoading, setGameQuarterLoading] = useState(false);
  const [gameDetailsLoading, setGameDetailsLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [gameDetails, setGameDetails] = useState([]);
  const [gameHeaderDetails, setGameHeaderDetails] = useState({});
  const [quaters, setQuaters] = useState({});
  const [type, setType] = useState(1);
  const { id, team_id } = useParams<MyParams>();
  const [PassingDetails, setPassingDetails] = useState([]);
  const [ReceivingDetail, setReceivingDetail] = useState([]);
  const [DefenseDetail, setDefenseDetail] = useState([]);
  const [RushDetail, setRushDetail] = useState([]);
  const [dropdownDetailsTouchdown, setDropDownDetailTouchdown] = useState([]);
  const [dropdownDetailsTurnover, setDropDownDetailTurnover] = useState([]);
  const [dropdownDetailsInterception, setDropDownDetailInterception] = useState(
    []
  );
  const [quarterInfo, SetQuarterInfo] = useState([]);
  const [touchdown, setTouchDown] = useState<any>(null);
  const [turnover, setTurnOver] = useState<any>(null);
  const [interception, setInterception] = useState<any>(null);
  const [teamDataInfo, setTeamDataInfo] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    handleFetchHighlight();
  }, []);

  useEffect(() => {
    handleFetchGameDetail();
  }, []);

  useEffect(() => {
    handleFetchGameStat(team_id);
  }, []);
  useEffect(() => {
    handleFetchGameDetailByTeamId(id);
  }, []);

  useEffect(() => {
    handleFetchGameQuarter();
  }, [type]);

  const handleFetchGameDetail = async () => {
    setGameDetailsLoading(true);
    await fetchGameDetail(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setGameDetailsLoading(false);

          setGameDetails(response.data);

          const headerDetails = {
            homeTeamImage: response.data.home_team.team_image[0].image,
            homeTeamName: response.data.home_team.team_name,
            homeTeamAltText: "Team 1",
            homeTeamPosition: "4-4",
            homeTeamColor: response.data.home_team.team_color,
            homeTeamScore: response.data.home_team.score,
            awayTeamImage: response.data.away_team.team_image[0].image,
            awayTeamName: response.data.away_team.team_name,
            awayTeamAltText: "Team 2",
            awayTeamPosition: "4-4",
            awayTeamScore: response.data.away_team.score,
            awayTeamColor: response.data.away_team.team_color,
          };
          setGameHeaderDetails(headerDetails);

          const quartersResult = {
            homeName: response.data.home_team.team_name
              .substring(0, 2)
              .toUpperCase(),
            awayName: response.data.away_team.team_name
              .substring(0, 2)
              .toUpperCase(),
            firstLr: response.data.quarters[1].home_team_score,
            secondLr: response.data.quarters[2].home_team_score,
            thirdLr: response.data.quarters[3].home_team_score,
            fourthLr: response.data.quarters[4].home_team_score,
            totalLr: response.data.home_team.score,
            firstTa: response.data.quarters[1].away_team_score,
            secondTa: response.data.quarters[2].away_team_score,
            thirdTa: response.data.quarters[3].away_team_score,
            fourthTa: response.data.quarters[4].away_team_score,
            totalTa: response.data.away_team.score,
          };
         
          setQuaters(quartersResult);
        } else {
          setGameDetailsLoading(false);
        }
      })
      .catch((err: any) => {
        setGameDetailsLoading(false);
      });
  };

  const handleFetchGameStat = async (team_id: any) => {
    setDetailsLoading(true);
    setDefenseDetail([]);
    setPassingDetails([]);
    setRushDetail([]);
    setReceivingDetail([]);
    await fetchGameStat(id, team_id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setDetailsLoading(false);
          const newDefenseArray = response.data.data.game_details.defense.map(
            (obj: any) => {
              return {
                name: obj.player.player_name,
                FP: obj.flag_pool,
                BD: obj.bat_down,
                INT: obj.interception,
                TD: obj.touch_down,
              };
            }
          );

          const newPassingArray = response.data.data.game_details.passing.map(
            (obj: any) => {
              return {
                name: obj.player.player_name,
                CPATT: obj.completed_passes,
                XTDS: obj.xtds,
                INT: obj.interception,
                TDS: obj.touch_down,
              };
            }
          );
          const newReceivingArray =
            response.data.data.game_details.receiving.map((obj: any) => {
              return {
                name: obj.player.player_name,
                D: obj.dropped,
                XTDS: obj.xtds,
                REC: obj.receiver,
                TDS: obj.touch_down,
              };
            });

          const newRushingArray = response.data.data.game_details.rushing.map(
            (obj: any) => {
              return {
                name: obj.player.player_name,
                SACKS: obj.sacks,
                BLK: obj.block,
                INT: obj.interception,
                TD: obj.touch_down,
              };
            }
          );

          setDefenseDetail(newDefenseArray);
          setPassingDetails(newPassingArray);
          setRushDetail(newRushingArray);
          setReceivingDetail(newReceivingArray);
        } else {
          setDetailsLoading(false);
        }
      })
      .catch((err: any) => {
        setDetailsLoading(false);
      });
  };
  
  const handleFetchGameQuarter = async () => {
    setGameQuarterLoading(true);
    setTouchDown([]);
    setTurnOver([]);
    setInterception([]);
    const params = {
      quarter_number: type,
    };
    await fetchGameQuarter(id, params)
      .then((response: RequestResponseInterface) => {
        setGameQuarterLoading(true);
        if (response.status === 200) {
          setGameQuarterLoading(false);
          const newTouchDownArray = {
            abbreviation: response.data.data[0].touchdowns[0].team.abbreviation,
            touchdownCounts: response.data.data[0].touchdowns.length || "0",
            teamImage:
              response.data.data[0].touchdowns[0].team.team_image[0].image,
          };
          const newTurnOverArray = {
            abbreviation: response.data.data[0].turnovers[0].team.abbreviation,
            turnOverCounts: response.data.data[0].turnovers.length || "0",
            teamImage:
              response.data.data[0].turnovers[0].team.team_image[0].image,
          };
          const newInterceptionArray = {
            abbreviation: response.data.data[0].turnovers[0].team.abbreviation,
            turnOverCounts: response.data.data[0].interceptions.length || "0",
            teamImage:
              response.data.data[0].touchdowns[0].team.team_image[0].image,
          };
          const newTouchdownSubCard = response.data.data[0].touchdowns.map(
            (cardDetail: any) => {
              return {
                subCardTitle: cardDetail.description,
                downPosition: cardDetail.down,
                team: cardDetail.team.name,
                video_link: cardDetail.video_link
              };
            }
          );
          const newTurnoverSubCard = response.data.data[0].turnovers.map(
            (cardDetail: any) => {
              return {
                subCardTitle: cardDetail.description,
                downPosition: cardDetail.down,
                team: cardDetail.team.name,
                video_link: cardDetail.video_link
              };
            }
          );
          const newInterceptionSubCard =
            response.data.data[0].interceptions.map((cardDetail: any) => {
              return {
                subCardTitle: cardDetail.description,
                downPosition: cardDetail.down,
                video_link: cardDetail.video_link,
                team: cardDetail?.player_name?.player_name
              };
            });

          setTouchDown(newTouchDownArray);
          setTurnOver(newTurnOverArray);
          setInterception(newInterceptionArray);
          setDropDownDetailTouchdown(newTouchdownSubCard);
          setDropDownDetailTurnover(newTurnoverSubCard);
          setDropDownDetailInterception(newInterceptionSubCard);
        } else {
          setGameQuarterLoading(false);
        }
      })
      .catch((err: any) => {
        setGameQuarterLoading(false);
      });
  };

  const handleSelectTeam = (team_id: string) => {
    handleFetchGameStat(team_id);
  };

  const handleFetchGameDetailByTeamId = async (id: any) => {
    await fetchGameTeamStats(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTeamDataInfo(response.data.data);
        } else {
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };

  const handleFetchGameTeamStat = async () => {
    await fetchGameTeamStats(id, team_id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
        } else {
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };

  const handleFetchHighlight = async () => {
    setHighlightsLoading(true);
    const params = {
      game_id: id,
    };
    await fetchGamesHighlights(params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setHighlights(response.data.results);
          setHighlightsLoading(false);
        } else {
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };

  const handleNavigate = (id: string) => {
    navigate(`/highlightdetails/${id}`);
  };

  const openDetails = (id: any) => {
    setOpenGameDetails(!openGameDetails);
  };

  const handleNavigateHighlights = () => {
    navigate(`/games/highlights/${id}`);
  };

  return (
    <SHTSpinner loading={gameDetailsLoading}>
      <section className="sh_background_dark">
        <GameHeader gameHeader={gameHeaderDetails} />
        <Container>
          <GameDetails
            detailsLoading={detailsLoading}
            dropdownTouchdown={dropdownDetailsTouchdown}
            dropdownInterception={dropdownDetailsInterception}
            dropdownTurnover={dropdownDetailsTurnover}
            loading={gameQuarterLoading}
            handleOpenDetails={(id: string) => openDetails(id)}
            open={openGameDetails}
            handleChange={(type: number) => setType(type)}
            gameInfo={gameDetails}
            handleNavigate={(id: string) => handleNavigate(id)}
            handleNavigateToHighlights={handleNavigateHighlights}
            highlights={highlights}
            highlightsLoading={highlightsLoading}
            teamData={teamDataInfo}
            gameDetails={quarterInfo}
            turnover={turnover}
            touchdown={touchdown}
            interception={interception}
            gameFirstTableDetails={quaters}
            passingDetail={PassingDetails}
            receivingDetails={ReceivingDetail}
            defenseDetail={DefenseDetail}
            rushDetail={RushDetail}
            handleSelectTeam={(team_id: string) => handleSelectTeam(team_id)}
          />
        </Container>
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(GamePage);
