// SHTRadio.tsx

import { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useController, Control } from "react-hook-form";
import "../assets/styles/components/sht_radio_style.css";

interface RadioFieldProps {
  legend?: string | ReactNode;
  options: { label: string; value: any }[];
  name: string;
  control?: any; // Use Control from react-hook-form
  rules?: any;
  inline?: boolean;
  onRadioChange?: (value: any) => void; // Callback for selected value
}

const SHTRadio: FC<RadioFieldProps> = ({
  legend,
  options,
  name,
  control,
  rules,
  inline,
  onRadioChange,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: "",
  });

  const handleRadioChange = (selectedValue: string) => {
    onChange(selectedValue);
    onBlur();
    if (onRadioChange) {
      onRadioChange(selectedValue);
    }
  };

  return (
    <fieldset>
      <Form.Group className="nt_radio_container_style">
        <Form.Label as="legend" className="sht_form_legend">
          {legend}
        </Form.Label>
        <div className="radio_button">
          {options.map((option) => (
            <Form.Check
              key={option.value}
              type="radio"
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              label={option.label}
              checked={value === option.value}
              onChange={() => handleRadioChange(option.value)}
              onBlur={onBlur}
              isInvalid={invalid}
              inline={inline}
              className="radio_button checkbox_options"
            />
          ))}
        </div>
        {invalid && (
          <Form.Control.Feedback type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </fieldset>
  );
};

SHTRadio.defaultProps = {
  inline: true,
};

export default SHTRadio;
