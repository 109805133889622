import React, { useState } from "react";
import "../assets/styles/components/sht_executive_style.css";
import avatar from "../assets/images/svg/Avatar.svg";
import { Container } from "react-bootstrap";
import SHTText from "./SHTText";
import SHTNoResult from "./SHTNoResult";

type cardProps = {
  image: string;
  name: string;
  post: string;
};

interface cardInterface {
  teamDetails: cardProps[];
  loading: any;
}

const SHTExecutives = (props: cardInterface) => {
  const { teamDetails, loading } = props;
  const [isOverArray, setIsOverArray] = useState(
    Array(teamDetails?.length).fill(false)
  );
  const handleMouseHover = (index: number) => {
    const newArray = isOverArray.slice();
    newArray[index] = true;
    setIsOverArray(newArray);
  };
  const handleMouseOut = (index: number) => {
    const newArray = isOverArray.slice();
    newArray[index] = false;
    setIsOverArray(newArray);
  };
  return (
    <section className="about_team_body sht_container_space">
      <Container>
        <div>
          <SHTText size="h3" color="white" text="EXECUTIVES" />
        </div>
        <div className=" team_card">
          {teamDetails?.length > 0 ? (
            teamDetails?.map((team: cardProps, index: number) => {
              return (
                <div
                  className="team_card_container  py-4"
                  onMouseOver={() => handleMouseHover(index)}
                  onMouseOut={() => handleMouseOut(index)}
                >
                  <div className="team_executive_image">
                    <img
                      src={team.image ? team.image : avatar}
                      alt="Our first Team"
                      className="executives_image"
                    />
                    {/* {isOverArray[index] && ( */}
                      <div className=" image-overlay">
                        <div className="team_executive_text">
                          <div className="">
                            <SHTText size="p" color="white" text={team.name} />
                          </div>
                          <div className="">
                            <SHTText size="p" color="gray" text={team.post} />
                          </div>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })
          ) : (
            <SHTNoResult loading={loading} />
          )}
        </div>
      </Container>
    </section>
  );
};

export default SHTExecutives;
