import React from "react";
import "../../assets/styles/page/latest_news_filter_style.css"
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import Dropdown from 'react-bootstrap/Dropdown';


const LatestNewsFilter = () => {
    return (
        <section className="latest_news_filter">
            <Container>
                <div className="d-flex justify-content-between py-5">
                    <div>
                        <SHTText size="h2" text="SHOW TIME NEWS" color="white" />
                    </div>

                    {/* <div className="d-flex">
                        <Dropdown className="sh_weekly_result_dropdown mx-2">
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="d-flex align-items-center justify-content-between sh_division_dropdown">
                                <SHTText text="WEEK 14" color="white" size="p" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="sh_weekly_result_dropdown ">
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="d-flex align-items-center justify-content-between sh_division_dropdown">
                                <SHTText text="WEEK 14" color="white" size="p" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> */}

                </div>
            </Container>
        </section>
    )
}

export default LatestNewsFilter
