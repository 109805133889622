import React from "react";
import "../../assets/styles/page/team_info_player_stats_style.css";

import SHTText from "../../components/SHTText";
interface PlayerStatsProps {
  playerStats: any;
  title: string
}
const TeamInfoPlayerStats = (props: PlayerStatsProps) => {
  const { playerStats, title } = props;

  return (
    <>
      <section className="team_info_player_stats_container">
        <div className="team_info_player_stats_left">
          <div className="team_info_player_stats_color">
            <div className="team_info_player_number">
              <div className="team_info_first_number">
                {playerStats?.player?.jersey_number}
              </div>
            </div>
          </div>
          <div className="team_info_player_image_container">
            <img
              src={playerStats?.player?.player_image}
              alt="player Image"
              className="team_info_player_image"
            />
          </div>
        </div>
        <div className="team_info_player_stats_right">
          <div className="">
            <SHTText
              size="h3"
              color="white"
              text={title}
            />
            <SHTText
              size="h4"
              color="yellow"
              text={playerStats?.player?.player_name}
            />
          </div>

          <table className="my-5 team_info_player_table">
            <tr >
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="white" text="RECEPTION" />
              </th>
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="gray" text={playerStats?.reception} />
              </th>
            </tr>
            <tr className="">
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="white" text="% RECEPTION" />
              </th>
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText
                  size="h6"
                  color="gray"
                  text={playerStats?.percent_reception}
                />
              </th>
            </tr>
            <tr className="">
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="white" text="TOUCHDOWNS" />
              </th>
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText
                  size="h6"
                  color="gray"
                  text={playerStats?.player?.statistics?.touchdowns}
                />
              </th>
            </tr>
            <tr className="">
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="white" text="EXTRA POINTS" />
              </th>
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText
                  size="h6"
                  color="gray"
                  text={playerStats?.player?.statistics?.extra_points}
                />
              </th>
            </tr>
            <tr className="">
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="white" text="DROPS" />
              </th>
              <th className="py-2 team_info_player_stats_cell ">
                <SHTText size="h6" color="gray" text={playerStats?.drops} />
              </th>
            </tr>
          </table>
        </div>
      </section>
    </>
  );
};

export default TeamInfoPlayerStats;
