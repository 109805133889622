import React from "react";
import { Container } from "react-bootstrap";
import FirstImage from "../../assets/images/svg/aboutFirst.svg";
import SecondImage from "../../assets/images/svg/aboutSecond.svg";
import ThirdImage from "../../assets/images/svg/aboutThird.svg";
import FourthImage from "../../assets/images/svg/aboutFourth.svg";
import FifthImage from "../../assets/images/svg/aboutFifth.svg";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/about_featured_style.css";
const AboutSection = () => {
  return (
    <Container>
      <section
        className="d-md-flex justify-content-between d-sm-block sht_container_space about_section_container "
        style={{ marginTop: 100, marginBottom: 100 }}
      >
        <div className="half_part">
          <div>
            <img
              src={FirstImage}
              alt="Image of a guy runing with a football"
              className="first_image"
            />
            <div className="my-5">
              <SHTText
                size="h1"
                text="About the Showtime Bowl Series"
                color="white"
              />
            </div>
            <div className="my-3 ">
              <SHTText
                size="p"
                text="The Showtime Bowl Series is the finale of the Showtime Flag football season, showcasing the best of both competitive sports and high-energy entertainment. This exciting series starts with a series of scrimmages, marking the beginning of a season packed with thrilling games and vibrant community events."
                color="gray"
              />
            </div>
            <div className="my-3 ">
              <SHTText
                size="p"
                text="Throughout the season, 12 teams engage in a partial round-robin format, each competing to secure their place in the top 8 teams for the playoffs. The playoff games are held in a home-and-away format, with outcomes based on point differentials, creating a thrilling atmosphere as teams vie for a spot in the finals."
                color="gray"
              />
            </div>
            <div className="my-3 ">
              <SHTText
                size="p"
                text="Teams that don't advance to the playoffs still have a chance to shine in the Community Cup, which offers a pathway to the next Showtime Bowl Series, ensuring every team has a significant role in the season’s final chapter."
                color="gray"
              />
            </div>

            <div className="my-3 ">
              <SHTText
                size="p"
                text="The climax of the series is the Showtime Bowl, scheduled for December 15th. This year's Showtime Bowl Series XI promises more than just top-tier flag football in Nigeria; it’s a celebration of community, culture, and entertainment. The grand finale will feature not only the ultimate showdown between the leading teams but also lively performances, music, and festivities that draw fans from across Nigeria and beyond. The Showtime Bowl embodies the essence of competition while embracing the joy and unity that comes with shared passions, making it a highly anticipated highlight of the season.

                "
                color="gray"
              />
            </div>
          </div>
        </div>
        <div className="half_part">
          <div>
            <SHTText size="h4" text="WHO WE ARE" color="white" />
            <SHTText size="h2" text="About Showtime Flag" color="white" />
          </div>
          <div className="py-3">
            <SHTText
              size="p"
              text="Showtime Flag is more than just a flag football league; it's a vibrant community-building initiative based in Lagos, Nigeria. Founded by Azeez Amida on July 7, 2023, as SFFL Showtime, the league quickly evolved and rebranded in 2024 to become Showtime Flag, a premier professional league. Our mission is to create a platform where people of all ages and backgrounds can come together to experience the excitement of flag football.

              "
              color="gray"
            />
          </div>
          <div className="d-flex justify-content-between my-4">
            <img
              src={SecondImage}
              alt="two people running"
              className="second_image"
            />
            <img
              src={ThirdImage}
              alt="a lady trying to gain the ball from a guy while running"
              className="third_image"
            />
          </div>

          <div className="my-5">
            {/* <SHTText size="h4" text="DIVISION ONE" color="white" /> */}
            <div className="my-3">
              <SHTText
                color="gray"
                text="At Showtime Flag, we're passionate about more than just the game. We are dedicated to fostering youth development, promoting sportsmanship, and building a sense of unity. Our league is designed to inspire and connect people from diverse backgrounds, celebrating our shared love of sports while transcending cultural, social, and generational differences. "
                size="p"
              />
            </div>
          </div>
          <div className="my-4">
            <img
              src={FourthImage}
              alt="Group of players shakig hands"
              width="100%"
            />
          </div>

          <div className="my-5">
            {/* <SHTText size="h4" text="DIVISION TWO" color="white" /> */}
            <div className="my-3">
              <SHTText
                color="gray"
                text="With 12 teams competing in a selective round-robin format, the top 8 teams advance to the playoffs, culminating in the thrilling Showtime Bowl. Beyond competitive play, we focus on making each game day an unforgettable experience, emphasizing community and engagement at every turn. Join us in celebrating the spirit of flag football and the power of community!  "
                size="p"
              />
            </div>
          </div>
          <div className="my-4">
            <img
              src={FifthImage}
              alt="Group of players training"
              width="100%"
            />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default AboutSection;
