import React, { useEffect } from "react";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
import "../../assets/styles/page/team_header_style.css";

interface TeamHeaderProps {
  handleNavigate: Function;
  type: string;
}

const TeamHeader = ({ handleNavigate, type }: TeamHeaderProps) => {
  useEffect(() => {
    console.log(type);
  }, [type]);

  return (
    <section className="all_teams_header_container">
      <div className="overlay"></div>
      <Container className="py-2">
        <div className="d-flex h-100 ">
          <div className="header_text">
            <SHTText size="h2" text="TEAMS" color="white" />
            <SHTText size="p" text="SHOWTIME ROASTER" color="gray" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeamHeader;
