import React from "react";
import SHTButton from "../../components/SHTButton";
import { MdOutlineHome } from "react-icons/md";
import SHTText from "../../components/SHTText";
import { Link } from "react-router-dom";

const CompletionReceived = ({ navigation }: any) => {
  return (
    <section className="first_form_container team_management">
      <div className="pb-4">
        <SHTText size="h3" text="Submission Received" color="gray" />
      </div>

      <SHTText
        size="p"
        text="Once you have completed the required forms or online fields with accurate information and then clicked on “submit”,  you await a response, which may confirm your registration or request further information. Upon receiving the response, you can proceed with any additional steps or actions as instructed."
        color="gray"
      />

      <Link to="/">
        <div className="d-flex  pointer py-5">
          <MdOutlineHome color="blue" size={20} />

          <SHTText text="Go back" color="blue" size="p" />
        </div>
      </Link>
    </section>
  );
};

export default CompletionReceived;
