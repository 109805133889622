import React from "react";
import "../../assets/styles/page/roaster_header_style.css";
import { Container } from "react-bootstrap";
interface propsInterface {
  bannerImage: any;
  bannerColor: any;
}

const RoasterHeader = (props: propsInterface) => {
  const { bannerImage, bannerColor } = props;
  return (
    <section style={{ backgroundColor: `${bannerColor}` }}>
      <Container>
        <img src={bannerImage} alt="Team Logo" className="roaster_team_image" />
        
      </Container>
    </section>
  );
};
export default RoasterHeader;
