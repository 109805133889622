import React from "react";
import { newsInterface } from "../../interfaces/axios.dto";
import SHTCard from "../../components/SHTCard";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import SHTLineHeader from "../../components/SHTLineHeader";
import SHTButton from "../../components/SHTButton";
import SHTPagination from "../../components/SHTPagination";
import SHTNoResult from "../../components/SHTNoResult";
interface propsInterface {
  latestNews: newsInterface[];
  handleOnClick: any;
  pagination: any;
  handlePageChange: any;
}

const NewsComponent = (props: any) => {
  const {
    latestNews,
    handleOnClick,
    handlePageChange,
    totalPages,
    currentPage,
    loading,
  } = props;
  return (
    <Container>
      <div className="">
        <SHTLineHeader headerText="LATEST NEWS" />
      </div>
      {latestNews?.length > 0 ? (
        <>
          <div className="d-flex flex-wrap justify-content-between">
            {latestNews.map((news: newsInterface) => (
              <div key={news.id} className="py-3">
                <SHTCard
                  handleOnClick={handleOnClick}
                  id={news.id}
                  //@ts-ignore
                  image={news?.featured_image?.image}
                  alt={news?.alt_text}
                  title={news.title}
                  date={news.created_at}
                  subText={news?.excerpt}
                />
              </div>
            ))}
          </div>
          <SHTPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <div className="no_result_container">
          <SHTNoResult loading={loading} />
        </div>
      )}
    </Container>
  );
};
export default NewsComponent;
