import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import authReducer from './reducer/auth_reducer';
import newsReducer from './reducer/news_reducer';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['authReducer', 'newsReducer'],
};

const customMiddleWare = () => (next:any) => (action:any) => {
  next(action);
};

const reducers = combineReducers({
  authReducer,
  newsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

// Define the compose function for Redux DevTools Extension
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Check if Redux DevTools Extension is installed, otherwise use a regular compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, customMiddleWare))
);

export const persistor = persistStore(store);

export default store;
