import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import SHTSpinner from "../../components/SHTSpinner";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import RoasterHeader from "../roaster/RoasterHeader";
import RoasterFilter from "../roaster/RoasterFilter";
import SHTPreviewNews from "../../components/SHTPreviewNews";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useParams } from "react-router-dom";
import SHTExecutives from "../../components/SHTExecutives";
import TeamInfoSchedule from "./TeamInfoSchedule";
import TeamInfoPlayerStats from "./TeamInfoPlayerStats";
import TeamSocials from "./TeamSocials";
import SHTEditTeamInfoModal from "../../components/SHTEditTeamInfoModal";
import toast, { Toaster } from "react-hot-toast";

type MyParams = {
  id: string;
  name: string;
};
interface ScheduleItem {
  id: number;
  home_team_image: string;
  home_team_name: string;
  away_team_image: string;
  away_team_name: string;
  home_team_score: number;
  away_team_score: number;
  date: string;
}
const TeamInfoPage = ({
  fetchGamesHighlights,
  fetchScheduleDetails,
  fetchFixtures,
  fetchTeamExecutive,
  fetchPlayerOfTheWeek,
  fetchTeamsById,
  editTeamInformation
}: any) => {
  const navigate = useNavigate();
  const { id, name } = useParams<MyParams>();
  const [highlightsLoading, setHighlightsLoading] = useState(true);
  const [highlights, setHighlights]: any = useState([]);
  const [loading, setLoading] = useState(true);
  //@ts-ignore
  const [schedule, setSchedule]: any = useState([]);
  const [infoSchedules, setInfoScheduled] = useState([]);
  const [playerofTheWeek, setPlayerofTheWeek] = useState(null);
  const [playerOfTheWeekDefensive, setPlayerOfTheWeekDefensivee] =
    useState(null);
  const [teamExecutive, setTeamExecutive] = useState([]);
  const [teamExecutiveLoading, setTeamExecutiveLoading] = useState(true);
  const [teamInfoImage, setTeamInfoImage] = useState();
  const [teamColor, setTeamColor] = useState();
  const [teamLoading, setTeamLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);


  useEffect(() => {
    handlePreviewNewsFetch();
    handleTeamSchedule(id);
    handleFectchNextMatches();
    handleFetchTeamExecutives(id);
    handleFetchPlayerOfTheWeek(id);
    handleFetchPlayerOfWeekDefensive(id);
    handleFetchTeamInfo(id);
  }, []);

  const handlePreviewNewsFetch = async () => {
    const params = {
      team_id: id,
    };
    setHighlightsLoading(true);
    await fetchGamesHighlights(params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setHighlights(response.data.results);
          setHighlightsLoading(false);
        } else {
          console.log("error fetching skills");
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };
  const handleFetchTeamExecutives = async (id: any) => {
    setTeamExecutiveLoading(true);
    await fetchTeamExecutive(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTeamExecutive(response.data.data);
          setTeamExecutiveLoading(false);
        } else {
          console.log("error fetching skills");
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamExecutiveLoading(false);
      });
  };

  const handleFetchPlayerOfTheWeek = async (id: any) => {
    const params = {
      position: "offensive",
    };
    setTeamExecutiveLoading(true);
    await fetchPlayerOfTheWeek(id, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setPlayerofTheWeek(response.data.data);
          setTeamExecutiveLoading(false);
        } else {
          console.log("error fetching skills");
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamExecutiveLoading(false);
      });
  };

  const handleFetchPlayerOfWeekDefensive = async (id: any) => {
    const params = {
      position: "defensive",
    };
    setTeamExecutiveLoading(true);
    await fetchPlayerOfTheWeek(id, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setPlayerOfTheWeekDefensivee(response.data.data);
          setTeamExecutiveLoading(false);
        } else {
          console.log("error fetching skills");
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamExecutiveLoading(false);
      });
  };

  const handleFectchNextMatches = async () => {
    const params = {
      team_id: id,
    };
    setHighlightsLoading(true);
    await fetchFixtures(params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setInfoScheduled(response.data.results);
          setHighlightsLoading(false);
        } else {
          console.log("error fetching skills");
          setHighlightsLoading(false);
        }
      })
      .catch((err: any) => {
        setHighlightsLoading(false);
      });
  };

  const handleTeamSchedule = async (id: any) => {
    const params = {
      limit: 1,
    };
    setLoading(true);
    await fetchScheduleDetails(id, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          const newArray: ScheduleItem[] = response.data.results.map(
            (obj: any) => {
              return {
                id: obj.id,
                home_team_image: obj.home_team.team_image[0].image,
                home_team_name: obj.home_team.team_name,
                away_team_image: obj.away_team.team_image[0].image,
                away_team_name: obj.away_team.team_name,
                home_team_score: obj.home_team.score,
                away_team_score: obj.away_team.score,
                date: obj.date,
              };
            }
          );
          setSchedule(newArray);

          setLoading(false);
        } else {
          setLoading(false);
          console.log("error fetching skills");
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleFetchTeamInfo = async (id: any) => {
    setTeamLoading(true);
    await fetchTeamsById(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          console.log(response.data.data)
          setTeamLoading(false);
          setTeamInfoImage(response.data.data.team_image[0].image);
          setTeamColor(response.data.data.team_color);
          setTeamInfo(response.data.data)
        } else {
          console.log("error fetching skills");
          setTeamLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamLoading(false);
      });
  };
  const handleNavigate = (id: string) => {
    navigate(`/highlightdetails/${id}`);
  };

  const handleNavigateSchedule = () => {
    navigate(`/teams/schedule/${id}`);
  };

  const handleNavigatePage = (page: string) => {
    if (page === "roaster") {
      navigate(`/roaster/${id}`);
    } else if (page === "info") {
      navigate(`/team-info/${id}`);
    } else if (page === "contracts") {
      navigate(`/contracts/${id}`);
    }  else if (page === "edit-team") {
      setShowEditModal(true);
    } else {
      navigate(`/contracts/${id}`);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleTeamEdit = async (data: any) => {
  
    toast.promise(
      editTeamInformation(data, id).then((response: any) => {
        if (response.status === 200) {
          setShowEditModal(false);
          return response;
        } else {
          throw new Error("Failed to accept the request");
        }
      }),
      {
        loading: "Sending request to edit team...",
        success: "Your request have been sent.",
        error: (err: any) => err.message || "Error sending request",
      }
    );
  };
  
  return (
    <SHTSpinner loading={teamLoading}>
          <Toaster />
      <section>
        <SHTEditTeamInfoModal
          show={showEditModal}
          handleClose={handleCloseEditModal}
          handleSubmitData={handleTeamEdit}
          initialData={teamInfo}
        />

        <RoasterHeader bannerImage={teamInfoImage} bannerColor={teamColor} />
        <RoasterFilter
          page="info"
          handleNavigate={(page: string) => handleNavigatePage(page)}
          name={name}
          id={id}
        />
        <SHTPreviewNews
          title={`HIGHLIGHTS`}
          highlights={highlights}
          handleOnClick={handleNavigate}
          loading={highlightsLoading}
        />
        <TeamInfoSchedule
          infoSchedules={infoSchedules}
          gameDetaiils={schedule}
          loading={loading}
          handleClick={() => handleNavigateSchedule()}
        />
        {playerofTheWeek !== null && (
          <Carousel controls={false} pause="hover">
            <Carousel.Item>
              <TeamInfoPlayerStats
                playerStats={playerofTheWeek}
                title="OFFENSIVE PLAYER OF THE WEEK"
              />
            </Carousel.Item>
            <Carousel.Item>
              <TeamInfoPlayerStats
                playerStats={playerOfTheWeekDefensive}
                title="DEFENSIVE PLAYER OF THE WEEK"
              />
            </Carousel.Item>
          </Carousel>
        )}

        <SHTExecutives
          teamDetails={teamExecutive}
          loading={teamExecutiveLoading}
        />

        <TeamSocials socials={teamInfo} />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamInfoPage);
