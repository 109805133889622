import React from "react";
import SHTText from "./SHTText";
import "../assets/styles/components/sht_weekly_result_box.css";
import SHTButton from "./SHTButton";
import SHTNoResult from "./SHTNoResult";

function SHTWeeklyResultBox({ results, handleClick, loading }: any) {
  return (
    <>
      {
        results?.length > 0 ?
          (<div>{results.map((data: any) => (
            <div key={data.id} className="weekly_result_box_container">
              <div className="left_results">
                <div className="left_results_info">
                  <SHTText size="p" color="white" text={data.date} />
                  <SHTText size="p" color="white" text={data.time} />
                </div>
                <div className="team_text">
                  <SHTText size="p" color="blue" text={data.firstTeam} />
                  <SHTText size="p" color="white" text={data.secondTeam} />
                </div>
              </div>
              <div className="right_results">
                <div>
                  <SHTText size="p" color="white" text={data.firstScore} />
                  <SHTText size="p" color="white" text={data.secondScore} />
                </div>
                <div className="">
                  <SHTButton
                    name="VIEW DETAILS"
                    handleClick={handleClick}
                    size="sm"
                    type="submit"
                    color="secondary"
                    className="weekly_result_button"
                  />
                </div>
              </div>
            </div>
          ))}
          </div>
          ) : (
            <SHTNoResult loading={loading} />
          )
      }

    </>
  );
}

export default SHTWeeklyResultBox;
