import React from "react";
import "../../assets/styles/page/roaster_table_style.css";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import SHTLineHeader from "../../components/SHTLineHeader";
import { convertToAge, getSeason } from "../../utils/helper";

type CardInputFieldProps = {
  roasterDetails: any[];
  handleClick: any;
};

const RoasterTable = (props: CardInputFieldProps) => {
  const { roasterDetails, handleClick } = props;
  return (
    <section className="sht_container_space">
      <Container>
        <div className="">
          <SHTLineHeader
            lineColor="white"
            lineWidth="short"
            headerText={`ROASTER`}
          />
        </div>
        <div className="roaster_table_container">
          <div className="roaster_table overflow-auto">
            <table className="py-5">
              <thead className="roaster_table_main ">
                <tr>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="Player" />
                  </th>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="No" />
                  </th>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="Pos" />
                  </th>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="Weight" />
                  </th>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="Height" />
                  </th>
                  <th className="p-4">
                    <SHTText size="p" color="white" text="Age" />
                  </th>
                  <th className="p-4 last_roaster_info">
                    <SHTText size="p" color="white" text="Seasons" />
                  </th>
                </tr>
              </thead>
              {roasterDetails.map((roasterInfo: any, index: number) => {
                return (
                  <tbody className="roaster_table_main" key={index}>
                    <tr
                      className="roaster_single_detail"
                      style={{
                        borderBottom: index === roasterDetails.length - 1 ? 'none' : '1px solid #dbdbdb',
                      }}
                      onClick={() => handleClick(roasterInfo?.id)}
                    >
                      <td className="roaster_table_team_image_container p-4">
                        <img
                          src={roasterInfo?.player_image}
                          alt="Team Player Image"
                          className="roaster_table_team_image"
                        />
                        <div className="mx-3 ">
                          <SHTText size="p" text={roasterInfo?.player_name} color="blue" />
                        </div>
                      </td>
                      <td className="p-4">
                        <SHTText size="p" color="gray" text={roasterInfo?.jersey_number} />
                      </td>
                      <td className="p-4">
                        <SHTText size="p" color="gray" text={roasterInfo?.position?.position_abbrevation} />
                      </td>
                      <td className="p-4">
                        <SHTText
                          size="p"
                          color="gray"
                          text={roasterInfo?.weight}
                        />
                      </td>
                      <td className="p-4">
                        <SHTText
                          size="p"
                          color="gray"
                          text={roasterInfo?.height}
                        />
                      </td>
                      <td className="p-4">
                        <SHTText size="p" color="gray" text={convertToAge(roasterInfo?.age)} />
                      </td>
                      <td className="p-4 last_roaster_info">
                        <SHTText
                          size="p"
                          color="gray"
                          text={getSeason(roasterInfo?.drafted_year)}
                        />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RoasterTable;
