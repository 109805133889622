import React from "react";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/stats_table_style.css";
import { formatImage, formatStat } from "../../utils/helper";
import SHTPagination from "../../components/SHTPagination";
type statIntefaceProps = {
  name: string;
  player?: string;
  image: string;
  score?: string;
  pa?: string;
  cp?: string;
  percentcp?: string;
  tds?: string;
  int?: string;
  rec?: string;
  percentrec?: string;
  drops?: string;
  xtds?: string;
  fp?: string;
  bd?: string;
  picks?: string;
  sacks?: string;
  blk?: string;
  id?: string;
  team_id?: string;
};
interface statsDetails {
  statsDetails: any;
  handleLoadMore?: any;
  handlePageChange: any;
  totalPages: any;
  currentPage: any;
  handleClick: any;
}

const StatsPassingDetails = (props: statsDetails) => {
  const {
    statsDetails,
    handleLoadMore,
    totalPages,
    currentPage,
    handlePageChange,
    handleClick,
  } = props;

  return (
    <Container className="sht_container_space">
      <div className="stats_table_container overflowscroll">
        <table>
          <tr className="stats_table_header ">
            <th className="py-4 px-3">
              <SHTText size="p" color="white" text="Player" />
            </th>

            {/* <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="QB Score" />
            </th>
             */}
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="PA" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="CP" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="%CP" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="TDS" />
            </th>
            <th className="py-4 px-3">
              <SHTText size="p" color="gray" text="INT" />
            </th>
          </tr>
          {statsDetails.map((stat: statIntefaceProps, index: number) => {
            return (
              <tr
                key={index}
                className="stats_table_data"
                onClick={() => handleClick(stat?.id, stat?.team_id)}
              >
                <td className="py-3 px-3 ">
                  <div className="d-flex align-items-center">
                    <img
                      src={formatImage(stat.image)}
                      alt="Profile Image"
                      className="profile_image"
                    />
                    <div className="mx-3">
                      <SHTText size="p" color="blue" text={stat.player} />
                    </div>
                  </div>
                </td>
                {/* <td className="py-3 px-3">
                  <SHTText
                    size="p"
                    color="gray"
                    text={formatStat(stat.score)}
                  />
                </td>
                */}
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.pa)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.cp)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText
                    size="p"
                    color="gray"
                    text={formatStat(stat.percentcp)}
                  />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.tds)} />
                </td>
                <td className="py-3 px-3">
                  <SHTText size="p" color="gray" text={formatStat(stat.int)} />
                </td>
              </tr>
            );
          })}
        </table>
        {/* <div
          className="d-flex justify-content-center stats_button"
          onClick={handleLoadMore}
        >
          <SHTText size="p" color="blue" text="Load More" />
        </div> */}
        <SHTPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default StatsPassingDetails;
