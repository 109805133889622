import React from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTButton from "../../components/SHTButton";
import { PLAYER_NO_INPUT_VALIDATION } from "../../utils/validation";
import useFormPersistence from "../../utils/helper/useFormPersist";

interface FormInputs {
  no_of_player: any;
  player_payment_disclosure: string;
}
const PlayerArrangement = ({ handleSubmitData }: any) => {
  let loginInitalValues: FormInputs = {
    no_of_player: "",
    player_payment_disclosure: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: loginInitalValues,
  });

  const onSubmit = (data: FormInputs) => {
    handleSubmitData(data);
  };

  useFormPersistence<FormInputs>("playerArrangement", setValue, watch);
  return (
    <section className="first_form_container team_management">
      <div className="pb-4">
        <SHTText size="h2" text="Player Arrangement" color="gray" />
      </div>
      <div className="form_list">
        <SHTText
          size="p"
          text=<p className="labe_text">
            Maximum number of players you expect to have on your team <br />{" "}
            <b>(Must be at least 25 players)</b>
          </p>
        />
        <Controller
          control={control}
          name="no_of_player"
          rules={PLAYER_NO_INPUT_VALIDATION}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label=""
              placeholder=""
              type="messageText"
              value={value}
              error={errors.no_of_player && errors.no_of_player.message}
              onChangeText={onChange}
            />
          )}
        />

        <div className="my-5">
          <Controller
            control={control}
            name="player_payment_disclosure"
            rules={{
              required: "Player disclosure is needed",
            }}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Player Payment Disclosure"
                placeholder=""
                type="messageText"
                value={value}
                // editable={loading}
                error={
                  errors.player_payment_disclosure &&
                  errors.player_payment_disclosure.message
                }
                onChangeText={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default PlayerArrangement;
