import React from "react";
import { Container } from "react-bootstrap";
import SHTNoResult from "../../components/SHTNoResult";
import SHTScoreCard from "../../components/SHTScoreCard";
import { scoreCardInterface } from "../../interfaces/axios.dto";
import SHTPagination from "../../components/SHTPagination";

interface propsInterface {
  scoreCardDetails: scoreCardInterface[];
  loading: boolean;
  handleClick: Function;
  handleViewGameDetails: Function;
  //pagination implementation
  handlePageChange: Function;
  totalPages: any;
  currentPage: any;
  //--------------
}
const ScoreDetails = (props: propsInterface) => {
  const {
    scoreCardDetails,
    loading,
    handleClick,
    handleViewGameDetails,
    //pagination implementation
    handlePageChange,
    totalPages,
    currentPage,
    //--------------
  } = props;

  return (
    <section>
      <Container>
        {scoreCardDetails?.length > 0 ? (
          <>
            {scoreCardDetails.map((cardDetails: scoreCardInterface) => {
              return (
                <div key={cardDetails.id} className="py-3">
                  <SHTScoreCard
                    firstImage={cardDetails.firstImage}
                    secondImage={cardDetails.secondImage}
                    date={cardDetails.date}
                    time={cardDetails.time}
                    firstFormation={cardDetails.firstFormation}
                    secondFormation={cardDetails.secondFormation}
                    firstScore={cardDetails.firstScore}
                    secondScore={cardDetails.secondScore}
                    firstTeam={cardDetails.firstTeam}
                    secondTeam={cardDetails.secondTeam}
                    handleOnClick={() => handleClick(cardDetails.id)}
                    handleViewGameDetails={() =>
                      handleViewGameDetails(cardDetails.id, cardDetails.team1Id)
                    }
                  />
                </div>
              );
            })}

            <SHTPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <div className="no_result_container">
            <SHTNoResult loading={loading} />
          </div>
        )}
      </Container>
    </section>
  );
};

export default ScoreDetails;
