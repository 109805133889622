import React from "react";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/highlight_sidebar_style.css";
import Highlight from "../homepage/Highlight";
import SHTNoResult from "../../components/SHTNoResult";
import ReactPlayer from "react-player";

function Highlights({ highlights, loading, handleOnClick }: any) {
  return (
    <div className="highlight_main_container">
      <div className="game_highlights_header">
        <div className="game_highlights_header_text">
          <SHTText color="white" text="HIGHLIGHTS" size="h6" />
        </div>
      </div>
      <div className="main_video">
        { highlights &&
          <ReactPlayer
            url={highlights[0]?.featured_video}
            width="100%"
            height="176px"
            controls={false}
          />
        }
      </div>
      <div className="highlight_container_section">
        {highlights?.length > 0 ? (
          <>
            {highlights.map((data: any, index: number) => (
              <div key={index} className="highlight_container">
                <div onClick={() => handleOnClick(data.id)}>
                  <div className="game_highlights">
                    <SHTText text="GAME HIGHLIGHTS" size="lighter" />
                  </div>

                  <div>
                    <SHTText text={data.title} size="p" color="white" />
                  </div>
                  <div>
                    <SHTText
                      text={`Highlights | Week ${data.week_number}`}
                      size="p"
                      color="white"
                    />
                  </div>
                </div>

                <div className="">
                  <ReactPlayer
                    url={data.featured_video}
                    width="125px"
                    height="92px"
                    controls={false}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="no_result_container">
            <SHTNoResult loading={loading} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Highlights;
