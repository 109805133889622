import React, { FC, useState } from "react";
import SHTText from "./SHTText";
import SHTButton from "./SHTButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import SHTNoResult from "./SHTNoResult";

interface CardInputFieldProps {
  id?: string;
  CPATT?: string;
  name?: string;
  TDS?: string;
  XTDS?: string;
  INT?: string;
  REC?: string;
  D?: string;
  FP?: string;
  TD?: string;
  BD?: string;
  SACKS?: string;
  BLK?: string;
  children?: React.ReactNode;
  style?: any;
  PassingDetails: any;
  ReceivingDetails: any;
  DefenseDetails: any;
  RushindDetails: any;
  teamDetail: any;
  gameInfo: any;
  handleButtonClick: Function;
  detailsLoading: any;
}

type teamDetailProps = {
  id: string;
  title: string;
  ta: number;
  lr: number;
  homeTeam: string;
  awayTeam: string
};

const SHTGameStatsCard: FC<CardInputFieldProps> = ({
  PassingDetails,
  ReceivingDetails,
  DefenseDetails,
  RushindDetails,
  teamDetail,
  gameInfo,
  handleButtonClick,
  detailsLoading,
}) => {
  const [selectedTeam, setSelectedTeam] = useState("individual1");
  const handleButtonSelectTeam = (team_id: string | null, type: string) => {
    if (team_id !== null) {
      handleButtonClick(team_id);
    }

    setSelectedTeam(type);
  };
  return (
    <section className="game_stats_card_wrapper">
      <div className="mx-3 py-3">
        <SHTText size="p" color="white" text="Stats" />
      </div>
      <div className="horizontal-line my-3"></div>
      <div className="d-flex ">
        <SHTButton
          type="submit"
          name={gameInfo?.home_team?.team_name}
          color={`${selectedTeam === "individual1"
            ? "game_filter_primary"
            : "game_filter_secondary"
            }`}
          className={`w-50`}
          handleClick={() =>
            handleButtonSelectTeam(gameInfo?.home_team?.team_id, "individual1")
          }
        />
        <SHTButton
          type="submit"
          name={gameInfo?.away_team?.team_name}
          color={`${selectedTeam === "individual2"
            ? "game_filter_primary"
            : "game_filter_secondary"
            }`}
          className={`w-50`}
          handleClick={() =>
            handleButtonSelectTeam(gameInfo?.away_team?.team_id, "individual2")
          }
        />
        <SHTButton
          type="submit"
          name="Team"
          color={`${selectedTeam === "Team"
            ? "game_filter_primary"
            : "game_filter_secondary"
            }`}
          className={`w-50`}
          handleClick={() => handleButtonSelectTeam(null, "Team")}
        />
      </div>

      {!detailsLoading ? (
        <div>
          {selectedTeam === "individual1" && (
            <>
              <div className="my-4 mx-3">
                <SHTText size="p" text="PASSING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="CP/ATT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="TDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="XTDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>

                  {PassingDetails.map((passingDetail: any) => {
                    return (
                      <tbody key={passingDetail.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3 ">
                            <SHTText
                              text={passingDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={passingDetail.CPATT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.TDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.XTDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="RECEIVING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="REC" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="TDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="XTDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="D" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {ReceivingDetails.map((receivingDetails: any) => {
                    return (
                      <tbody key={receivingDetails.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className="py-3 ">
                            <SHTText
                              text={receivingDetails.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={receivingDetails.REC}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.TDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.XTDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.D}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="DEFENSE" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="FP" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="BD" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="TD" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {DefenseDetails.map((defenseDetail: any, index: number) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3">
                            <SHTText
                              text={defenseDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={defenseDetail.FP}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.BD}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.TD}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="RUSHING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="SACKS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="BLK" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="TD" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {RushindDetails.map((rushingDetail: any) => {
                    return (
                      <tbody key={rushingDetail.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3">
                            <SHTText
                              text={rushingDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={rushingDetail.SACKS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.BLK}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.TD}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </>
          )}
          {selectedTeam === "individual2" && (
            <>
              <div className="my-4 mx-3">
                <SHTText size="p" text="PASSING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="CP/ATT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="TDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="XTDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>

                  {PassingDetails.map((passingDetail: any) => {
                    return (
                      <tbody key={passingDetail.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3 ">
                            <SHTText
                              text={passingDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={passingDetail.CPATT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.TDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.XTDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={passingDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="RECEIVING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="REC" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="TDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="XTDS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="D" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {ReceivingDetails.map((receivingDetails: any) => {
                    return (
                      <tbody key={receivingDetails.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className="py-3 ">
                            <SHTText
                              text={receivingDetails.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={receivingDetails.REC}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.TDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.XTDS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={receivingDetails.D}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="DEFENSE" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="FP" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="BD" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="TD" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {DefenseDetails.map((defenseDetail: any) => {
                    return (
                      <tbody key={defenseDetail.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3">
                            <SHTText
                              text={defenseDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={defenseDetail.FP}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.BD}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={defenseDetail.TD}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="my-3 mx-3">
                <SHTText size="p" text="RUSHING" color="white" />
                <table className="">
                  <thead className="mx-3">
                    <tr>
                      <th className=" table_detail_header_box w-50">
                        <SHTText text="Player" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="SACKS" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="BLK" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box px-2">
                        <SHTText text="INT" color="white" size="p" />
                      </th>
                      <th className="text-center table_detail_header_box">
                        <SHTText text="TD" color="white" size="p" />
                      </th>
                    </tr>
                  </thead>
                  {RushindDetails.map((rushingDetail: any) => {
                    return (
                      <tbody key={rushingDetail.id}>
                        <tr>
                          <td colSpan={5} className="horizontal-line"></td>
                        </tr>
                        <tr>
                          <td className=" py-3">
                            <SHTText
                              text={rushingDetail.name}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center px-2 py-3">
                            <SHTText
                              text={rushingDetail.SACKS}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.BLK}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.INT}
                              color="white"
                              size="p"
                            />
                          </td>
                          <td className="text-center py-3">
                            <SHTText
                              text={rushingDetail.TD}
                              color="white"
                              size="p"
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </>
          )}
          {selectedTeam === "Team" && (
            <section>
              <div className="mx-3 my-3">
                <SHTText size="p" color="white" text="Team Stats" />
              </div>
              <div className="horizontal-line my-3"></div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Total Completed Passes'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].total_completed_passes} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].total_completed_passes} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].total_completed_passes || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].total_completed_passes || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Drops'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].drops || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].drops || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].drops || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].drops || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Points scored'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].drops || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].drops || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].drops || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].drops || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Flag Pull'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].flag_pool || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].flag_pool || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].flag_pool || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].flag_pool || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Bat downs'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].bat_down || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].bat_down || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].bat_down || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].bat_down || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Sacks Allowed'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].sacks || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].sacks || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].sacks || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].sacks || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Interception Thrown'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].interceptions_thrown || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].interceptions_thrown || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Time of Possession'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].interceptions_thrown || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].interceptions_thrown || 0} color="gray" />
                </div>
              </div>
              <div>
                <div className="mx-3 my-1">
                  <SHTText size="p" color="white" text={'Penalties'} />
                </div>

                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[0].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[0].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[0].interceptions_thrown || 0} color="gray" />
                </div>
                <div className="d-flex justify-content-between align-items-center mx-3">
                  <SHTText size="p" text={teamDetail[1].team_abbr} color="gray" />
                  <div className="w-75 progress_bar align-items-center pb-4">
                    <ProgressBar now={teamDetail[1].interceptions_thrown || 0} max={20} />
                  </div>
                  <SHTText size="p" text={teamDetail[1].interceptions_thrown || 0} color="gray" />
                </div>
              </div>
            </section>
          )}
        </div>
      ) : (
        <SHTNoResult loading={detailsLoading} />
      )}
    </section>
  );
};

export default SHTGameStatsCard;
