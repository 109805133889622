import React from "react";
import "../../assets/styles/page/about_head_style.css";
import SHTText from "../../components/SHTText";
const AboutHeader = () => {
  return (
    <section className="about_head">
      <div className="d-flex text-center justify-content-center align-items-center h-100 py-5 ">
        <div className="overlay"></div>
        <div className="header_text">
          <SHTText size="h2" text="ABOUT US" color="white_important" />
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
