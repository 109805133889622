import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import StatsHeader from "./StatsHeader";
import StatsFilter from "./StatsFilter";
import SHTSpinner from "../../components/SHTSpinner";
import StatsPassingDetails from "./StatsPassingDetails";
import ProfileImage from "../../assets/images/svg/statsProfile.svg";
import StatsReceivingDetails from "./StatsReceivingDetails";
import StatsDefenseDetails from "./StatsDefenseDetails";
import StatsRushingDetails from "./StatsRushingDetails";
import TeamStatsFilter from "./TeamStatsFilter";
import TeamOffenseStats from "./TeamOffenseStats";
import titanLogo from "../../assets/images/svg/titansLogo.svg";
import TeamStatsFilterComp from "./TeamStatFilter";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import TeamDefenseStats from "./TeamDefenseStats";
import SHTNoResult from "../../components/SHTNoResult";
import { useNavigate } from "react-router-dom";

const StatsPage = ({ getPlayerStats, getTeamStats }: any) => {
  const navigate = useNavigate();
  const [content, setContent] = useState("PASSING");
  const [teamBanner, setTeamBanner] = useState("OFFENSE");
  const [type, setType] = useState<any>("player");
  const [loading, setLoading] = useState(true);
  const [loadingTeam, setLoadingTeam] = useState(true);

  const [playerStatsPassing, setPlayerStatsPassing]: any = useState([]);
  const [playerStatsRecieving, setPlayerStatsRecieving]: any = useState([]);
  const [playerStatsDefense, setPlayerStatsDefense]: any = useState([]);
  const [playerStatsRushing, setPlayerStatsRushing]: any = useState([]);
  const [teamStatsDefense, setTeamStatsDefense]: any = useState([]);
  const [teamStatsOffense, setTeamStatsOffense]: any = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSelectType = (type: any) => {
    setCurrentPage(1);
    setContent(type);
    handleGetPlayerStats(type, null);
  };

  const handleSelectTeam = (type: any) => {
    setCurrentPage(1);
    setContent(type);
    handleGetTeamStats(type, null);
  };

  useEffect(() => {
    handleGetPlayerStats(content, null);
    handleGetTeamStats("OFFENSE", null);
  }, [currentPage]);

  const handleGetPlayerStats = async (type: any, param: any) => {
    const filter = {
      type,
    };

    const params = {
      page: currentPage,
    };

    const updatedParams = {
      ...params,
      ...filter,
    };

    setPlayerStatsRecieving([]);
    setPlayerStatsDefense([]);
    setPlayerStatsRushing([]);
    setPlayerStatsPassing([]);
    setLoading(true);
    await getPlayerStats(updatedParams)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTotalPages(response.data.total_pages);
          if (type === "PASSING") {
            const newArray = response.data.results.map((data: any) => {
              return {
                id: data.player.id,
                player: data.player.player_name,
                score: null,
                pa: data.total_stats.passing_attempt,
                cp: data.total_stats.completed_passes,
                percentcp: data.total_stats.completed_passes / 100,
                tds: data.total_stats.touch_down,
                int: data.total_stats.interception,
                image: data.player.player_image,
                team_id: data?.player?.team?.id,
              };
            });

            setPlayerStatsPassing(newArray);
            setLoading(false);
          }
          if (type === "RECEIVING") {
            const newArray = response.data.results.map((data: any) => {
              return {
                id: data.player.id,
                player: data.player.player_name,
                rec: data.total_stats.receiver,
                percentrec: data.total_stats.receiver / 100,
                drops: data.total_stats.dropped,
                tds: data.total_stats.touch_down,
                xtds: data.total_stats.xtds,
                image: data.player.player_image,
                team_id: data?.player?.team?.id,
              };
            });
            setPlayerStatsRecieving(newArray);
            setLoading(false);
          }
          if (type === "DEFENSE") {
            const newArray = response.data.results.map((data: any) => {
              return {
                id: data.player.id,
                player: data.player.player_name,
                picks: data.total_stats.dropped,
                fp: data.total_stats.flag_pool,
                bd: data.total_stats.bat_down,
                tds: data.total_stats.touch_down,
                xtds: data.total_stats.interception,
                image: data.player.player_image,
                team_id: data?.player?.team?.id,
              };
            });
            setPlayerStatsDefense(newArray);
            setLoading(false);
          }
          if (type === "RUSHING") {
            const newArray = response.data.results.map((data: any) => {
              return {
                id: data.player.id,
                player: data.player.player_name,
                sacks: data.total_stats.sacks,
                blk: data.total_stats.blk,
                tds: data.total_stats.tds,
                xtds: data.total_stats.xtds,
                image: data.player.player_image,
                team_id: data?.player?.team?.id,
              };
            });
            setPlayerStatsRushing(newArray);

            setLoading(false);
          }
        } else {
          console.log("error fetching skills");
          //setTeamRosterLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const PaginationChange = (page: any) => {
    setCurrentPage(page);
  };

  const handleGetTeamStats = async (type: any, param: any) => {
    const params = {
      type,
    };
    setLoadingTeam(true);
    await getTeamStats(params)
      .then((response: RequestResponseInterface) => {
        console.log(response.data);
        if (response.status === 200) {
          if (type === "OFFENSE") {
            const newArray = response.data.map((data: any) => {
              return {
                id: data?.id,
                image: data?.team_image[0]?.image,
                pa: data?.passing_attempt,
                cp: data?.total_completed_passes,
                rec: data?.receiver,
                drops: data?.dropped,
                tds: data?.total_touch_down,
                xtds: data?.total_xtds,
                int: data?.total_interception,
                team: data?.name,
              };
            });
            setTeamStatsOffense(newArray);
          }
          if (type === "DEFENSE") {
            const newArray = response.data.map((data: any) => {
              return {
                id: data?.id,
                image: data?.team_image[0]?.image,
                team: data?.name,
                fp: data?.passing_attempt,
                bd: data?.passing_attempt,
                pick: data?.passing_attempt,
                sack: data?.passing_attempt,
                blk: data?.passing_attempt,
                tds: data?.total_touch_down,
                xtds: data?.total_xtds,
              };
            });
            setTeamStatsDefense(newArray);
          }

          setLoadingTeam(false);
        } else {
          console.log("error fetching skills");
          //setTeamRosterLoading(false);
        }
      })
      .catch((err: any) => {
        setLoadingTeam(false);
      });
  };

  const handleNavigatePlayerRoaster = (player_id: string, team_id: string) => {
    navigate(`/players/roaster/${player_id}/${team_id}`);
  };

  const handleNavigateToTeam = (team_id: string) => {
    navigate(`/team-info/${team_id}`);
  };

  const handleFetchStat = (content: string) => {
    switch (content) {
      case "PASSING":
        return (
          <div>
            {playerStatsPassing?.length > 0 ? (
              <StatsPassingDetails
                handleClick={(id: string, team_id: string) =>
                  handleNavigatePlayerRoaster(id, team_id)
                }
                statsDetails={playerStatsPassing}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={PaginationChange}
              />
            ) : (
              <SHTNoResult loading={loading} />
            )}
          </div>
        );

        break;
      case "RECEIVING":
        return (
          <div>
            {playerStatsRecieving?.length > 0 ? (
              <StatsReceivingDetails
                handleClick={(id: string, team_id: string) =>
                  handleNavigatePlayerRoaster(id, team_id)
                }
                statsDetails={playerStatsRecieving}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={PaginationChange}
              />
            ) : (
              <SHTNoResult loading={loading} />
            )}
          </div>
        );
        break;
      case "DEFENSE":
        return (
          <div>
            {playerStatsDefense?.length > 0 ? (
              <StatsDefenseDetails
                handleClick={(id: string, team_id: string) =>
                  handleNavigatePlayerRoaster(id, team_id)
                }
                statsDetails={playerStatsDefense}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={PaginationChange}
              />
            ) : (
              <SHTNoResult loading={loading} />
            )}
          </div>
        );
        break;
      case "RUSHING":
        return (
          <div>
            {playerStatsRushing?.length > 0 ? (
              <StatsRushingDetails
                handleClick={(id: string, team_id: string) =>
                  handleNavigatePlayerRoaster(id, team_id)
                }
                statsDetails={playerStatsRushing}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={PaginationChange}
              />
            ) : (
              <SHTNoResult loading={loading} />
            )}
          </div>
        );
        break;
      default:
        return (
          <div>
            {playerStatsPassing?.length > 0 ? (
              <StatsPassingDetails
                handleClick={(id: string, team_id: string) =>
                  handleNavigatePlayerRoaster(id, team_id)
                }
                statsDetails={playerStatsPassing}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={PaginationChange}
              />
            ) : (
              <SHTNoResult loading={loading} />
            )}
          </div>
        );
    }
  };

  const handleTeamBanner = (content: string) => {
    switch (content) {
      case "OFFENSE":
        return (
          <div>
            {teamStatsOffense?.length > 0 ? (
              <TeamOffenseStats
                statsDetails={teamStatsOffense}
                handleClick={(id: string) => handleNavigateToTeam(id)}
              />
            ) : (
              <SHTNoResult loading={loadingTeam} />
            )}
          </div>
        );
        break;
      case "DEFENSE":
        return (
          <div>
            {teamStatsDefense?.length > 0 ? (
              <TeamDefenseStats
                statsDetails={teamStatsDefense}
                handleClick={(id: string) => handleNavigateToTeam(id)}
              />
            ) : (
              <SHTNoResult loading={loadingTeam} />
            )}
          </div>
        );
        break;

      default:
        return (
          <TeamOffenseStats
            statsDetails={teamStatsOffense}
            handleClick={(id: string) => handleNavigateToTeam(id)}
          />
        );
    }
  };

  const handleFetchStatsCategory = (type: string) => {
    setType(type);

    if (type === "player") {
      handleGetTeamStats(content, null);
    }
    handleGetTeamStats(content, null);
  };

  return (
    <SHTSpinner loading={false}>
      <section>
        <StatsHeader
          handleFetchStatsCategory={handleFetchStatsCategory}
          type={type}
        />
        {type === "player" && (
          <>
            <StatsFilter
              handleFetchStat={(type: string) => handleSelectType(type)}
            />
            {loading ? (
              <div className="no_result_container">
                <SHTNoResult loading={loading} />
              </div>
            ) : (
              handleFetchStat(content)
            )}
          </>
        )}
        {type === "team" && (
          <>
            <TeamStatsFilterComp
              handleFetchStat={(type: string) => handleSelectTeam(type)}
            />
            {handleTeamBanner(content)}
          </>
        )}
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});
export default connect(mapStateToProps, actions)(StatsPage);
