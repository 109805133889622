import React, { useState } from "react";
import SHTLineHeader from "../../components/SHTLineHeader";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import "../../assets/styles/page/roaster_info_stats_style.css";
import { formatStat } from "../../utils/helper";
import SHTNoResult from "../../components/SHTNoResult";
const RoasterInfoStats = ({
  playerStat,
  statLoading,
  playerStatInSeason,
  playerStatPreSeason,
  playerStatCareer,
  playerStatCareerLoading,
}: any) => {
  const [activeButton, setActiveButton] = useState("SUMMARY");

  const handleButtonClick = (content: string) => {
    setActiveButton(content);
  };
  return (
    <Container>
      <div className="d-flex  d-flex justify-content-end my-4 ml-auto sht_container_space">
        <SHTButton
          type="submit"
          name="SUMMARY"
          color={
            activeButton === "SUMMARY"
              ? "game_filter_secondary"
              : "game_filter_primary"
          }
          handleClick={() => handleButtonClick("SUMMARY")}
        />
        {/* <SHTButton
          type="submit"
          name="CAREER"
          color={
            activeButton === "CAREER"
              ? "game_filter_secondary"
              : "game_filter_primary"
          }
          handleClick={() => handleButtonClick("CAREER")}
        /> */}
        {/* <SHTButton
          type="submit"
          name="LOGS"
          color={
            activeButton === "LOGS"
              ? "game_filter_secondary"
              : "game_filter_primary"
          }
          handleClick={() => handleButtonClick("LOGS")}
        /> */}
        
      </div>

      {activeButton === "SUMMARY" && (
        <div>
          <SHTLineHeader
            headerText="RECENT GAMES"
            lineColor="white"
            lineWidth="small"
          />
          <>
            {playerStat?.length > 0 ? (
              <div className="roaster_table_container">
                <div className="overflow-scroll scroll-container stat_table">
                  <thead className="overflow-scroll">
                    <tr className="">
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="WEEK" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="OPP" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="RESULT" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="PA" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="CP" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="REC" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="DROPS" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="TDS" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="XTDS" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="INT" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="FP" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="BD" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="PICK" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="DTDS" />
                      </th>
                      <th className="p-4  roaster_info_table_head">
                        <SHTText size="p" color="white" text="SACK" />
                      </th>
                      <th className="p-4  roaster_info_table_head last_roaster_info">
                        <SHTText size="p" color="white" text="BLK" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {playerStat.map((playerStat: any, index: number) => (
                      <tr key={index}>
                        <th className="p-4">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.week)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.opp)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(`${playerStat.result}`)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.pa)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.cp)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.rec)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.drops)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.tds)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.xtds)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.int)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.fp)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.bd)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.pick)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.dtds)}
                          />
                        </th>
                        <th className="p-4 ">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.sack)}
                          />
                        </th>
                        <th className="p-4  last_roaster_info">
                          <SHTText
                            size="small"
                            color="gray"
                            text={formatStat(playerStat.blk)}
                          />
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </div>
              </div>
            ) : (
              <SHTNoResult loading={statLoading} />
            )}
          </>

          <div className="sht_container_space">
            <SHTLineHeader
              headerText="CAREER STATS"
              lineColor="white"
              lineWidth="small"
            />
            <>
              {playerStatCareer?.length > 0 ? (
                <div className="roaster_table_container">
                  <div className="overflow-scroll scroll-container stat_table">
                    <thead className="overflow-scroll">
                      <tr>
                        {/* <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="SEASON" />
                        </th> */}
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="TEAM" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="APP" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="PA" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="CP" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="REC" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="DROPS" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="TDS" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="XTDS" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="INT" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="FP" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="BD" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="PICK" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="DTDS" />
                        </th>
                        <th className="p-4 roaster_info_table_head ">
                          <SHTText size="p" color="white" text="SACK" />
                        </th>
                        <th className="p-4 roaster_info_table_head  last_roaster_info">
                          <SHTText size="p" color="white" text="BLK" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {playerStatCareer.map(
                        (playerStat: any, index: number) => (
                          <tr key={index}>
                            {/* <th className="p-4 ">
                              <SHTText size="small" color="gray" text={formatStat(playerStat.dtds)}  />
                            </th> */}
                            <th className="p-4 ">
                              <SHTText
                                size="small"
                                color="gray"
                                text={formatStat(playerStat.team)} 
                              />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray" text={formatStat(playerStat.app)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.pa)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.cp)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.rec)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.drops)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.tds)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.xtds)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.int)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray" text={formatStat(playerStat.fp)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.bd)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray" text={formatStat(playerStat.pick)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.dtds)} />
                            </th>
                            <th className="p-4 ">
                              <SHTText size="small" color="gray"  text={formatStat(playerStat.sack)} />
                            </th>
                            <th className="p-4  last_roaster_info">
                              <SHTText size="small" color="gray" text={formatStat(playerStat.blk)} />
                            </th>
                          </tr>
                        )
                      )}
                    </tbody>
                  </div>
                </div>
              ) : (
                <SHTNoResult loading={playerStatCareerLoading} />
              )}
            </>
          </div>
        </div>
      )}
      {activeButton === "CAREER" && (
        <div className="">
          <div className="roaster_table_container">
            <div className="stat_table ">
              <div className="p-4">
                <SHTText size="p" color="gray" text="Passing" />
              </div>
              <div className="overflow-scroll scroll-container">
                <table className="overflow-scroll">
                  <tr>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                      <SHTText size="p" color="white" text="SEASON" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="TEAM" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="APP" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="PA" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="CP" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="REC" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="TDS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="XTDS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="INT" />
                    </th>
                  </tr>
                  <tr>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="18" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="10/10/20024" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="20" />
                    </th>
                    <th className="p-4 ">
                      <SHTText size="small" color="gray" text="W 13-12" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="12" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="12" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="12" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="12" />
                    </th>
                    <th className="p-4">
                      <SHTText size="small" color="gray" text="12" />
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="">
            <div className="roaster_table_container">
              <div className="stat_table ">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Receiving" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="SEASON" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TEAM" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="APP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="REC" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DROPS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="XTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="%REC" />
                      </th>
                    </tr>
                    <tr>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="18" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="10/10/20024" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="20" />
                      </th>
                      <th className="p-4 ">
                        <SHTText size="small" color="gray" text="W 13-12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="roaster_table_container">
              <div className="stat_table ">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Rushing" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="SEASON" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TEAM" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="APP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="SACKS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BLK" />
                      </th>
                    </tr>
                    <tr>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="18" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="10/10/20024" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="20" />
                      </th>
                      <th className="p-4 ">
                        <SHTText size="small" color="gray" text="W 13-12" />
                      </th>
                      <th className="p-4 ">
                        <SHTText size="small" color="gray" text="W 13-12" />
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="roaster_table_container">
              <div className="stat_table ">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Defense" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="SEASON" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TEAM" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="APP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="FP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BD" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PICK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DXTDS" />
                      </th>
                    </tr>
                    <tr>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="18" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="10/10/20024" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="20" />
                      </th>
                      <th className="p-4 ">
                        <SHTText size="small" color="gray" text="W 13-12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                      <th className="p-4">
                        <SHTText size="small" color="gray" text="12" />
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeButton === "LOGS" && (
        <div className="">
          <div className="roaster_table_container">
            <div className="stat_table ">
              <div className="p-4">
                <SHTText size="p" color="gray" text="Group A" />
              </div>
              <div className="overflow-scroll scroll-container">
                <table className="overflow-scroll">
                  <tr>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                      <SHTText size="p" color="white" text="WEEK" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="GAME DATE" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="OPP" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="RESULT" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="PA" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="CP" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="REC" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="DROPS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="TDS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="XTDS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="INT" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="FP" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="BD" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="PICK" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="DTDS" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="SACK" />
                    </th>
                    <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                      <SHTText size="p" color="white" text="BLK" />
                    </th>
                  </tr>

                  {playerStatInSeason.map((playerStat: any, index: number) => (
                    <tr key={index}>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.week)}
                        />
                      </th>
                      <th className="p-4">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.date)}
                        />
                      </th>

                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.opp)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(`${playerStat.result}`)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.pa)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.cp)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.rec)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.drops)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.tds)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.xtds)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.int)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.fp)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.bd)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.pick)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.dtds)}
                        />
                      </th>
                      <th className="p-4 ">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.sack)}
                        />
                      </th>
                      <th className="p-4  last_roaster_info">
                        <SHTText
                          size="small"
                          color="gray"
                          text={formatStat(playerStat.blk)}
                        />
                      </th>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <div className="">
            <div className="roaster_table_container">
              <div className="stat_table py-4">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Group B" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="WEEK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="GAME DATE" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="OPP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="RESULT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PA" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="CP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="REC" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DROPS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="XTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="INT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="FP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BD" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PICK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="SACK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BLK" />
                      </th>
                    </tr>
                    {playerStatPreSeason.map(
                      (playerStat: any, index: number) => (
                        <tr key={index}>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.week)}
                            />
                          </th>
                          <th className="p-4">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.date)}
                            />
                          </th>

                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.opp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(`${playerStat.result}`)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pa)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.cp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.rec)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.drops)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.tds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.xtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.int)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.fp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.bd)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pick)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.dtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.sack)}
                            />
                          </th>
                          <th className="p-4  last_roaster_info">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.blk)}
                            />
                          </th>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="">
            <div className="roaster_table_container">
              <div className="stat_table">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Division one" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="WEEK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="GAME DATE" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="OPP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="RESULT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PA" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="CP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="REC" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DROPS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="XTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="INT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="FP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BD" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PICK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="SACK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BLK" />
                      </th>
                    </tr>
                    {playerStatDivision1.map(
                      (playerStat: any, index: number) => (
                        <tr key={index}>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.week)}
                            />
                          </th>
                          <th className="p-4">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.date)}
                            />
                          </th>

                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.opp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(`${playerStat.result}`)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pa)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.cp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.rec)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.drops)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.tds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.xtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.int)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.fp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.bd)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pick)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.dtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.sack)}
                            />
                          </th>
                          <th className="p-4  last_roaster_info">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.blk)}
                            />
                          </th>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="roaster_table_container">
              <div className="stat_table">
                <div className="p-4">
                  <SHTText size="p" color="gray" text="Division two" />
                </div>
                <div className="overflow-scroll scroll-container">
                  <table className="overflow-scroll">
                    <tr>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3 ">
                        <SHTText size="p" color="white" text="WEEK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="GAME DATE" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="OPP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="RESULT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PA" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="CP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="REC" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DROPS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="TDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="XTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="INT" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="FP" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BD" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="PICK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="DTDS" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="SACK" />
                      </th>
                      <th className="px-4 roaster_info_table_head roaster_info_table_top py-3">
                        <SHTText size="p" color="white" text="BLK" />
                      </th>
                    </tr>
                    {playerStatDivision2.map(
                      (playerStat: any, index: number) => (
                        <tr key={index}>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.week)}
                            />
                          </th>
                          <th className="p-4">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.date)}
                            />
                          </th>

                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.opp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(`${playerStat.result}`)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pa)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.cp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.rec)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.drops)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.tds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.xtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.int)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.fp)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.bd)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.pick)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.dtds)}
                            />
                          </th>
                          <th className="p-4 ">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.sack)}
                            />
                          </th>
                          <th className="p-4  last_roaster_info">
                            <SHTText
                              size="small"
                              color="gray"
                              text={formatStat(playerStat.blk)}
                            />
                          </th>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </Container>
  );
};

export default RoasterInfoStats;
