import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import "../../assets/styles/page/first_form_style.css";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import {
  EMAIL_INPUT_VALIDATION,
  PASSWORD_INPUT_VALIDATION,
  PHONE_NUMBER_INPUT_VALIDATION,
} from "../../utils/validation";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";

interface FormInputs {
  email: string;
}

type MyParams = {
  token: string;
};

function VerifyEmail({ verifyEmail, resendVerfication }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const confirmation_token: string | null = searchParams.get("token");
  const { token } = useParams<MyParams>();

  useEffect(() => {
    if (token) {
      handlverfiyEmail(token);
    }
  }, [token]);

  const [loading, setLoading] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] =
    useState("verifying");

  let registerInput: FormInputs = {
    email: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const handlverfiyEmail = async (data: any) => {
    setLoading(true);
    await verifyEmail(data)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          if (response.data.response_code === 3000) {
            setRegistrationSuccessful("verified");
          }
          else{
            setRegistrationSuccessful("unverified");
          }
          setLoading(false);
          return
        } else {
          setRegistrationSuccessful("unverified");
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setRegistrationSuccessful("unverified");
        setLoading(false);
      });
  };

  const handleSubmitData = async (data: any) => {
    setLoading(true);
    await resendVerfication(data)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Reactivation email sent successful");
          setRegistrationSuccessful("sent");
        } else {
          toast.error("This email has been doesnt exist");
        }
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error("Error requesting a reactivation");
        setLoading(false);
      });
  };

  return (
    <div>
      <section className="register_team_container">
        <Container>
          <section
            className="register_form_container"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <main className="register_form ">
              <section className="first_form_container">
                <div style={{ textAlign: "center" }}>
                  <img
                    src={Logo}
                    style={{ width: 100, height: 60, marginBottom: 10 }}
                    alt="Logo"
                  />
                  <div className="pb-4">
                    <SHTText size="h3" text="User Registration" color="gray" />
                  </div>
                </div>

                {registrationSuccessful === "verifying" && (
                  <div>
                    <div
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      {/* @ts-ignore */}
                      <Spinner animation="border" variant="light" />
                    </div>
                  </div>
                )}

                {registrationSuccessful === "verified" && (
                  <div style={{ textAlign: "center" }}>
                    <SHTText
                      size="p"
                      text="You have successfully verified your email"
                      color="gray"
                    />

                    <section
                      className="m-3 "
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <SHTButton
                        type="submit"
                        name="Login"
                        loading={loading}
                        handleClick={() => navigate("/login")}
                      />
                    </section>
                  </div>
                )}

                {registrationSuccessful === "sent" && (
                  <div style={{ textAlign: "center" }}>
                    <SHTText
                      size="p"
                      text="Please check your email inbox to verify your email"
                      color="gray"
                    />
                  </div>
                )}

                {registrationSuccessful === "unverified" && (
                  <div>
                    <div className="m-3" style={{ textAlign: "center" }}>
                      <SHTText
                        size="p"
                        text="We are unable to verify your account, please request a new activation"
                        color="gray"
                      />
                    </div>
                    <div className="form_element">
                      <Controller
                        control={control}
                        name="email"
                        rules={EMAIL_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Email"
                            placeholder=""
                            type="messageText"
                            value={value}
                            // editable={loading}
                            onChangeText={onChange}
                            error={errors.email && errors.email.message}
                          />
                        )}
                      />
                    </div>

                    <section className="d-flex justify-content-between align-items-center">
                      <SHTButton
                        type="submit"
                        name="Resend Activation"
                        loading={loading}
                        handleClick={handleSubmit(handleSubmitData)}
                      />
                    </section>
                  </div>
                )}
              </section>
            </main>
          </section>
        </Container>
      </section>
    </div>
  );
}

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(VerifyEmail);
