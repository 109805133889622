import React, { useState } from "react";
import { Container } from "react-bootstrap";
import SHTTeamCard from "../../components/SHTTeamCard";
import teamImage from "../../assets/images/svg/teamImage.svg";
import SHTNoResult from "../../components/SHTNoResult";

interface propsInterface {
  cardDetails: any;
  actionName: string;
  handleClick?: any;
  loading: boolean;
}
const LeagueDetails = (props: propsInterface) => {
  const { cardDetails, actionName, handleClick, loading } = props;
  const [playerName, setPlayerName] = useState<string>("");
  return (
    <div className="sht_container_space">
      <Container>
        <div className="league_card_container">
          {cardDetails?.length > 0 ? (
            <>
              {cardDetails.map((detail: any) => {
                return (
                  <div  key={detail.id}>
                    {/* { setPlayerName(detail.name)} */}
                    <SHTTeamCard
                      image={detail.team_image[0]?.image || teamImage}
                      name={actionName}
                      handleOnClick={() =>
                        handleClick(
                          detail.id,
                          detail.team_image[0]?.image || teamImage
                        )
                      }
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <SHTNoResult loading={loading} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default LeagueDetails;
