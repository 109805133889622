import React from "react";
import "../../assets/styles/page/team_schedule_header.style.css";
import SHTText from "../../components/SHTText";

const TeamScheduleHeader = ({ teamImage, bannerColor }: any) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <section className="schedule_head" style={{ backgroundColor: `${bannerColor}` }}>
      <div className="d-flex text-center justify-content-center align-items-center h-100 py-5 ">
        <div className="overlay"></div>
        <div className="header_text">
          <SHTText size="h2" text={`${currentYear} SCHEDULE`} color="white" />
          <img src={teamImage} alt="Team Image" className="team_logo" />
        </div>
      </div>
    </section>
  );
};

export default TeamScheduleHeader;
