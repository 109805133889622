import React, { FC } from "react";
import SHTText from "./SHTText";
import "../assets/styles/components/sht_schedule_card.css";
import titansLogo from "../assets/images/svg/titansLogo.svg";
import SHTButton from "./SHTButton";

interface CardInputFieldProps {
  id?: string;
  image?: string;
  date?: string;
  week?: string;
  stand?: string;
  score?: string;
  teamName?: string;
  time?: string;
  location?: string;
  children?: React.ReactNode;
  style?: any;
  alt?: string;
  handleOnClick?: any;
}
const SHTScheduleCard: FC<CardInputFieldProps> = ({
  id,
  image,
  date,
  week,
  stand,
  score,
  teamName,
  time,
  location,
  style,
  handleOnClick,
  alt,
}) => {
  return (
    <section className="schedule_card_container">
      <div className="d-flex justify-content-between schedule_card_header p-3">
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <SHTText size="h5" text={`WEEK ${week}`} color="white" />
          </div>
          <div className="mx-2">
            <SHTText size="p" text={date} color="gray" />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {stand ? (
            <>
              <div className="mx-2">
                <SHTText size="h5" text={stand} color="white" />
              </div>
              <div>
                <SHTText size="p" text={score} color="gray" />
              </div>
            </>
          ) : (
            <SHTText size="p" text="UPCOMING" color="gray" />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between p-3">
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <img src={image} alt="team_logo" className="team_logo" />
          </div>
          <div className="mx-2">
            <SHTText size="h5" text={teamName} color="white" />
          </div>
        </div>
        <div className="align-items-end">
          <div className="mx-2 align_right">
            <SHTText size="p" text={time} color="gray" />
          </div>
          <div className="mx-2 align_right">
            <SHTText size="p" text={location} color="gray" />
          </div>
          {/* 
          <div className="mx-2 align_right">
            {stand && (
              <SHTButton
                name="View game"
                type="submit"
                color="secondary"
                className="my-4 py-2 px-3"
                handleClick={handleOnClick}
              />
            )}
          </div> */}
        </div> 
      </div>
    </section>
  );
};

export default SHTScheduleCard;
