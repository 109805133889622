import axios from "../../utils/axios";

export const fetchFixtures = (params: any) => async () => {
  return axios.get(`/games/all-upcoming-games/`, { params });
};

export const fetchGames = (params: any) => async () => {
  return axios.get(`/games/all-played-game/`, { params });
};

export const fetchNextGames = (params: any) => async () => {
  return axios.get(`games/latest-match/`);
};

export const fetchGameDetailByTeam =
  (id: string, team_id: string) => async () => {
    return axios.get(`games/game-details/${id}/${team_id}/`);
  };

export const fetchGameDetail = (id: string) => async () => {
  return axios.get(`games/game-detail/${id}/`);
};

export const fetchGameStat = (game_id: string, team_id: string) => async () => {
  return axios.get(`games/game-details/${game_id}/${team_id}/`);
};

// export const fetchGameStat = (game_id: string, team_id: string) => async () => {
//   return axios.get(`games/game-details/${game_id}/${team_id}/`);
// };

export const fetchGameQuarter = (game_id: string, params: any) => async () => {
  return axios.get(`games/game-quarter/${game_id}/`, { params });
};

export const fetchTournaments = () => async () => {
  return axios.get(`/division/get-division/`);
};

export const fetchGameTeamStats = (game_id: string, params: any) => async () => {
  return axios.get(`/games/game-team-stat/${game_id}/`, { params });
};
