import React from "react";
import { Container } from "react-bootstrap";
import '../../assets/styles/page/team_socials_style.css'
import instagram from "../../assets/images/svg/instagram 3.svg";
import tiktok from "../../assets/images/svg/tik-tok 6.svg";
import twitter from "../../assets/images/svg/twitter 4.svg";
import SHTText from "../../components/SHTText";

type socials = {
    team_twitter?: string,
    team_instagram?: string,
    team_tiktok?: string
}

interface socialsProps {
    socials: any
}
const TeamSocials = (props: socialsProps) => {
    const { socials } = props
    return (
        <section className="team_social_container overflow-auto">
            <Container className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <img src={instagram} alt={'ada'} />
                    <div className="mx-4">
                        <SHTText size="p" text={socials.team_instagram} color='gray' />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <img src={tiktok} alt={'ada'} />
                    <div className="mx-4">
                        <SHTText size="p" text={socials.team_tiktok} color='gray' />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <img src={twitter} alt={'ada'} />
                    <div className="mx-4">
                        <SHTText size="p" text={socials.team_twitter} color='gray' />
                    </div>
                </div>



            </Container>
        </section>
    )
}

export default TeamSocials