import React from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTRadio from "../../components/SHTRadio";
import SHTButton from "../../components/SHTButton";
import useFormPersistence from "../../utils/helper/useFormPersist";

interface FormInputs {
  sponsorship_disclosure: string;
  sustainability_plan: string;
  team_sponsor: any;
}
const SponsoeshipInformation = ({ handleSubmitData }: any) => {
  let loginInitalValues: FormInputs = {
    sponsorship_disclosure: "",
    sustainability_plan: "",
    team_sponsor: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: loginInitalValues,
  });
  const RadiOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const onSubmit = (data: FormInputs) => {
    // Access the selected radio value from data.payment_status
    console.log("Selected Radio Value:", data.team_sponsor);

    // Call the external submit handler
    handleSubmitData(data);
  };

  // Use the custom hook to handle form persistence
  useFormPersistence<FormInputs>("sponsorshipInformation", setValue, watch);

  return (
    <section className="first_form_container team_management">
      <div className="pb-4">
        <SHTText size="h2" text="Sponsorship Information" color="gray" />
      </div>
      <div className="form_list">
        <div className="my-4">
          <SHTRadio
            name="team_sponsor"
            options={RadiOptions}
            control={control}
            onRadioChange={(value) => setValue("team_sponsor", value)}
            legend=<p className="radio_text">Do you have a sponsor?</p>
          />
        </div>
        <SHTText
          size="p"
          text=<p className="labe_text">
            {" "}
            Sponsorship Disclosure:{" "}
            <b>
              {" "}
              (Kindly provide any sponsorship <br /> disclosures if applicable?)
            </b>
          </p>
        />
        <Controller
          control={control}
          name="sponsorship_disclosure"
          rules={{
            required: false,
          }}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label=""
              placeholder=""
              type="messageText"
              value={value}
              // editable={loading}
              onChangeText={onChange}
            />
          )}
        />
        <div className="my-5">
          <SHTText
            size="p"
            text=<p className="labe_text">
              {" "}
              Sustainability Plan ( Mandatory):{" "}
              <b> Please provide a 5 season sustainability plan</b>
            </p>
          />
          <Controller
            control={control}
            name="sustainability_plan"
            rules={{
              required: "Sustainable plan is needed",
            }}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label=""
                placeholder=""
                type="messageText"
                value={value}
                // editable={loading}
                onChangeText={onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default SponsoeshipInformation;
