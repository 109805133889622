import axios from "../../utils/axios";

export const fetchlatestNews = (params: any) => async () => {
  return axios.get(`/news/all-news/`, { params });
};

export const fetchtrendingNews = (params: any) => async () => {
  return axios.get(`/news/trending/`);
};

export const fetchLatestHighlight = (params: any) => async () => {
  return axios.get(`/highlight/all-highlight/`, { params });
};

export const fetchNewsDetails = (params: any) => async () => {
  return axios.get(`/news/all-news/${params}/`);
};

export const fetchHighlightDetails = (params: any) => async () => {
  return axios.get(`/highlight/all-highlight/${params}/`);
};

export const fetchGamesHighlights = (params: any) => async () => {
  return axios.get(`/highlight/get-highlight/`, { params });
};
