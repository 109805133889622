import React, { useState } from "react";
import SHTText from "../../components/SHTText";
import { Controller, useForm } from "react-hook-form";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import SHTRadio from "../../components/SHTRadio";
import SHTNoResult from "../../components/SHTNoResult";

interface FormInputs {
  position_id: string[];
  alergies: string;
}
const FourthForm = ({ handleSubmitData, position, loading }: any) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  let registerInput: FormInputs = {
    position_id: selectedOptions,
    alergies: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const onSubmit = (data: FormInputs) => {
    // Call the external submit handler
    handleSubmitData(data);
  };

  return (
    <section className="first_form_container">
      <form >
        <div className="pb-4">
          <SHTText size="h3" text="Player Information" color="gray" />
        </div>
        <SHTText
          size="h6"
          text="What position do you play? Kindly state."
          color="gray"
        />
        <div className="mt-4 position_checkbox_container">
          {!loading ? (
            <SHTRadio
              options={position}
              control={control}
              name="position_id"
              onRadioChange={(value) => setValue("position_id", value)}
            />
          ) : (
            <SHTNoResult loading={loading} />
          )}
        </div>
        <div className="mt-4">
          <Controller
            control={control}
            name="alergies"
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Do you have any health issues or allergies? If Yes, Kindly state."
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
              />
            )}
          />
        </div>
      </form>
      <div className="team_next">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default FourthForm;
