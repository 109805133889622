import React, { useState } from "react";
import "../../assets/styles/page/latest_news_filter_style.css";
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";

const RoasterInfoFilter = ({ handleNavigate, playerInfo }: any) => {
  const currentDate = new Date();
  const [activeButton, setActiveButton] = useState("info");

  const handleButtonClick = (type: string) => {
    setActiveButton(type);
    handleNavigate(type);
  };

  return (
    <section className="latest_news_filter">
      <Container>
        <div className="py-4">
          <div>
            <SHTText size="p" text="SHOWTIME TEAMS" color="gray" />
          </div>
          <div>
            <SHTText size="h2" text={playerInfo?.team?.name} color="white" />
          </div>
        </div>
        <div className="d-flex justify-content-between py-4">
          <div className="d-flex">
            <SHTButton
              type="submit"
              name="INFO"
              color={
                activeButton === "info" ? "stats_primary" : "stats_secondary"
              }
              handleClick={() => handleButtonClick("info")}
            />
            <SHTButton
              type="submit"
              className="mx-3"
              name="STATS"
              color={
                activeButton === "stat" ? "stats_primary" : "stats_secondary"
              }
              handleClick={() => handleButtonClick("stat")}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RoasterInfoFilter;
