import React, { useState } from "react";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import PlayButton from "../../assets/images/svg/playButton.svg";
import "../../assets/styles/page/game_filter_style.css";

const GameFilter = ({ handleChange , handleNavigateToHighlights}: any) => {
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (type: number) => {
    setActiveButton(type);
    handleChange(type);
  };

  return (
    <section>
      <div className="d-flex justify-content-between sht_container_space">
        <SHTText size="h5" color="gray" text="Drive Summary" />
        <div /* className="d-flex" */>
          <div className="mx-4">
            <SHTButton
              type="submit"
              color="game_filter_secondary"
              name="ALL DRIVES"
            />
          </div>
{/* 
          <SHTButton
            type="submit"
            color="game_filter_primary"
            name="SCORING DRIVES"
          /> */}
        </div>
      </div>

      <div className="game_filter_text d-flex justify-content-between align-items-center ">
        <div className="w-75">
          <SHTText size="h5" color="gray" text="Showtime Film" />
          <SHTText
            size="p"
            color="gray"
            text="Watch the four quarters footage for every play of the game."
          />
          <SHTText
            size="p"
            color="gray"
            text="Available immediately after the game ends"
          />
        </div>
        <div className="pointer" onClick={handleNavigateToHighlights}>
          <img src={PlayButton} alt="Play Button" />
        </div>
      </div>

      <div className="d-flex  d-flex justify-content quarter_filter_button">
        <SHTButton
          type="submit"
          size="sm"
          name="1ST QUARTER"
          color={
            activeButton === 1
              ? `game_filter_secondary`
              : `game_filter_primary`
          }
          handleClick={() => handleButtonClick(1)}
          className="first_button"
        />
        <SHTButton
          type="submit"
          size="sm"
          name="2ND QUARTER"
          color={
            activeButton === 2
              ? `game_filter_secondary`
              : `game_filter_primary`
          }
          handleClick={() => handleButtonClick(2)}
        />
        <SHTButton
          type="submit"
          size="sm"
          name="3RD QUARTER"
          color={
            activeButton === 3
              ? `game_filter_secondary`
              : `game_filter_primary`
          }
          handleClick={() => handleButtonClick(3)}
        />
        <SHTButton
          type="submit"
          size="sm"
          name="4TH QUARTER"
          color={
            activeButton === 4
              ? `game_filter_secondary`
              : `game_filter_primary`
          }
          handleClick={() => handleButtonClick(4)}
          className="fourth_button"
        />
      </div>
    </section>
  );
};
export default GameFilter;
