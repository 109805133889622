// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
//import '../assets/styles/components/404_styles';

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <p>Sorry, the page you're looking for doesn't exist.</p>
      <Link to="/" className="home-link">Go Back to Home</Link>
    </div>
  );
};

export default NotFound;

