import React from "react";
import Card from "react-bootstrap/Card";
import "../../assets/styles/page/sh_register_team_style.css";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import SHTButton from "../../components/SHTButton";

interface team {
  registerTeam: any;
  registerPlayer: any;
}

const RegisterTeam = (props: team) => {
  const { registerTeam, registerPlayer } = props;
  return (
    <Container className="sh_team_cards sht_container_space ">
      <section className="resgister_team_first_card">
        <div className=" register_text">
          <SHTText size="h3" text="REGISTER YOUR TEAM TODAY!" color="white_important" />
        </div>
        <div className=" register_sub_text register_text">
          <SHTText
            size="p"
            text="Are you ready to take your football career to the next level?  Look no further than the sffl showtime league! Register your all-star team and be part of the excitement that our league brings."
            color="white_important"
          />
        </div>
        <div className=" register_button register_text">
          <SHTButton
            name="REGISTER TEAM"
            type="submit"
            handleClick={registerTeam}
          />
        </div>
        <div className="overlay overflow-hidden"></div>
      </section>
      <section className="resgister_team_second_card">
        <div className=" register_text">
          <SHTText size="h3" text="UNLEASH YOUR POTENTIAL!" color="white_important" />
        </div>
        <div className=" register_sub_text register_text">
          <SHTText
            size="p"
            text="Are you a talented football player seeking a competitive and vibrant community to showcase your skills? Our league welcomes individual players who are ready to take on the challenge!"
            color="white_important"
          />
        </div>
        <div className=" register_button register_text">
          <SHTButton
            name="JOIN A TEAM"
            type="submit"
            handleClick={registerPlayer}
          />
        </div>
        <div className="overlay overflow-hidden"></div>
      </section>
    </Container>
  );
};

export default RegisterTeam;
