import React, { useEffect, useState } from "react";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import SHTLineHeader from "../../components/SHTLineHeader";
import SHTSpinner from "../../components/SHTSpinner";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/helper";
import SHTPreviewNews from "../../components/SHTPreviewNews";
import ReactPlayer from "react-player";

type detailProps = {
  video: string;
  title: string;
  text: string;
  date: string;
  id: number;
};

interface NewsDetailsProps {
  fetchHighlightDetails: Function;
}

type MyParams = {
  id: string;
};

const HighlightDetails = (props: NewsDetailsProps) => {
  const { fetchHighlightDetails } = props;
  const [relatedNews, setRelatedNews]: any = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [news, setNews]: any = useState({});
  const { id } = useParams<MyParams>();

  const navigate = useNavigate();

  useEffect(() => {
    handleFetchNews(id);
  }, []);

  const handleFetchNews = async (id: any) => {
    setLoading(true);
    await fetchHighlightDetails(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setNews(response.data.data);
          setRelatedNews(response.data.data.related_highlights);

          setLoading(false);
        } else {
        }
      })
      .catch((err: any) => {});
  };

  const handleNavigate = (id: string) => {
    navigate(`/highlightdetails/${id}`);
    handleFetchNews(id);
  };

  return (
    <SHTSpinner loading={loading}>
      <div key={news.id}>
        <ReactPlayer
          url={news?.featured_video}
          width="100%"
          height="500px"
          controls={true}
        />

        <Container className="sht_container_space">
          <SHTText size="p" text={formatDate(news.created_at)} color="gray" />
          <SHTText size="h1" text={news.title} color="white" />
          <div
            className="dark_mode"
            dangerouslySetInnerHTML={{ __html: news.body }}
          />

          <div className="sht_container_space">
            <SHTPreviewNews
              title="GAME HIGHLIGHTS"
              highlights={relatedNews}
              handleOnClick={(id: string) => handleNavigate(id)}
              loading={loading}
            />
          </div>
        </Container>
      </div>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(HighlightDetails);
