import React from "react";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/latest_news_header_style.css";
const LatestNewsHeader = () => {
  return (
    <section className="latest_news_header">
      <div className="d-flex text-center justify-content-center align-items-center h-100 py-5 ">
        <div className="overlay"></div>
        <div className="header_text">
          <SHTText size="h2" text="LATEST NEWS" color="white" />
        </div>
      </div>
    </section>
  );
};

export default LatestNewsHeader;
