import React from "react";
import SHTText from "../../components/SHTText";
import "../../assets/styles/page/scores_header_style.css";
import { Container } from "react-bootstrap";
const ScoresHeader = () => {
  return (
    <section className="scores_header">
      <div className="overlay"></div>
      <Container className="py-2">
        <div className="d-flex h-100 ">
          <div className="header_text">
            <SHTText size="h2" text="SCORES" color="white_important" />
            <SHTText size="p" text="SHOWTIME GAMES" color="gray_important" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ScoresHeader;
