import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

type MyParams = {
  id: string;
};

const TeamProfileEditConfirmation = ({ approveContractTermination }: any) => {
  const { id } = useParams<MyParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Extract token from the query string
  const token = new URLSearchParams(location.search).get("token");

  const handleAcceptEdit = async () => {
    if (!token) {
      toast.error("Invalid request: Token is missing.");
      return;
    }

    setLoading(true);

    const params = { token };

    toast
      .promise(
        approveContractTermination(id, params, token).then((response: any) => {
          if (response.status === 200) {
            setLoading(false);
            return response;
          } else {
            throw new Error("Failed to accept the request");
          }
        }),
        {
          loading: "Approving request...",
          success: "The request to terminate this contract has been accepted.",
          error: (err: any) => err.message || "Error sending request",
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="register_team_container">
      <Toaster />
      <Container>
        <section
          className="register_form_container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <main
            className="form_container"
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <section className="first_form_container">
              <div style={{ textAlign: "center" }}>
                <img
                  src={Logo}
                  style={{ width: 100, height: 60, marginBottom: 10 }}
                  alt="Logo"
                />
                <div className="pb-4">
                  <SHTText size="h3" text="Contract Terminate" color="gray" />
                </div>
              </div>
              <main className="edit_form_container">
                <section>
                  <div className="pb-4">
                    <SHTText
                      size="p"
                      text="A request has been sent to terminate your contract"
                      color="gray"
                    />
                  </div>
                </section>
                <section className="align-items-center mt-4">
                  <SHTButton
                    type="submit"
                    name="Accept Termination"
                    loading={loading}
                    handleClick={handleAcceptEdit}
                  />
                </section>
              </main>
            </section>
          </main>
        </section>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(TeamProfileEditConfirmation);
