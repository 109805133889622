import React, { useEffect, useState } from "react";
import SHTSpinner from "../../components/SHTSpinner";
import RoasterPlayerInfoHeader from "./RoasterPlayerInfoHeader";
import RoasterInfoFilter from "./RoasterInfoFilter";
import RoasterInfoPlayer from "./RoasterInfoPlayer";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import SHTText from "../../components/SHTText";
import { useNavigate, useParams } from "react-router-dom";
import RoasterInfoStats from "./RoasterInfoStats";
import { formatDate, handleGetWinner } from "../../utils/helper";
import { Alert, Container } from "react-bootstrap";
import SHTButton from "../../components/SHTButton";
import Cookies from "js-cookie";
import SHTNoResult from "../../components/SHTNoResult";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const RoasterPlayerInfo = ({
  authReducer,
  newsReducer,
  getUserDetails,
  updateContracts,
  fetchPlayerById,
  fetchlatestNews,
  fetchTeamContracts,
  fetchCareerStatsById,
  fetchPlayerCareerStatsById,
  fetchPlayerCareerStatsByIdSpecificStats,
}: any) => {
  const navigate = useNavigate();

  const savedUserInfo = newsReducer.user.user_data;
  const { id, team_id } = useParams();

  const auth_token = Cookies.get("auth_token");

  const [latestNews, setLatestNews] = useState([]);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [playerStat, setPlayerStat] = useState([]);
  const [playerStatInSeason, setPlayerStatInSeason] = useState([]);
  const [playerStatPreSeason, setPlayerStatPreSeason] = useState([]);
  const [type, setType] = useState("");
  const [latesNewsLoading, setFetchLatestNewsLoading] = useState(true);
  const [playerDetailsLoading, setPlayerDetailsLoading] = useState(true);
  const [playerStatDivision1, setPlayerStatDivision1] = useState([]);
  const [playerStatDivision2, setPlayerStatDivision2] = useState([]);
  const [playerStatCareer, setPlayerStatCareer] = useState<any>();
  const [playerStatLoading, setPlayerStatLoading] = useState(true);
  const [playerStatCareerLoading, setPlayerStatCareerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userContracts, setUserContracts] = useState([]);
  const [userInfo, setUserInfo] = useState<any>();
  const [contractLoading, setContractLoading] = useState<any>(true);
  const [playerLoadingStates, setPlayerLoadingStates]: any = useState({});
  const [aContractApproved, setAContractApproved] = useState<boolean>(false);

  useEffect(() => {
    handleLatestNewsFetch();
    handlePlayerFetch(id);
    handlePlayerInSeasonFetch();
    handlePlayerPreSeasonFetch();
    handleFetchUser();
    handleFetchContracts();
  }, []);

  const handleFetchUser = async () => {
    await getUserDetails()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          if (response.data.response_code === 3000) {
            setUserInfo(response.data.data);
            setLoading(false);
          }
        }
        if (response.status === 400) {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleFetchContracts = async () => {
    setContractLoading(true);
    await fetchTeamContracts()
      .then((response: RequestResponseInterface) => {
        if (response.data.response_code === "3000") {
          setUserContracts(response.data.results);
          setContractLoading(false);

          response.data.results.filter((data: any) => {
            if (data.contract_status === "APPROVED") {
              setAContractApproved(true);
            }
          });
        }

        if (response.status === 400) {
          setContractLoading(false);
        }
      })
      .catch((err: any) => {
        setContractLoading(false);
      });
  };

  const handleLatestNewsFetch = async () => {
    setFetchLatestNewsLoading(true);
    await fetchlatestNews()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setLatestNews(response.data.results);
          setFetchLatestNewsLoading(false);
        } else {
          setFetchLatestNewsLoading(false);
        }
      })
      .catch((err: any) => {
        setFetchLatestNewsLoading(false);
      });
  };

  const handlePlayerFetch = async (id: any) => {
    setPlayerDetailsLoading(true);
    await fetchPlayerById(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setPlayerDetails(response.data);
          setPlayerDetailsLoading(false);
        } else {
          setPlayerDetailsLoading(false);
        }
      })
      .catch((err: any) => {
        setPlayerDetailsLoading(false);
      });
  };

  const handlePlayerCareerFetch = async () => {
    setPlayerStatLoading(true);
    const info = {
      team_id: team_id,
      player_id: id,
    };

    await fetchPlayerCareerStatsById(info)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          //please fix
          const newArray = response.data.results.map((obj: any) => {
            return {
              week: obj?.game?.week?.week_number,
              opp:
                obj?.game?.home_team?.team_id === team_id
                  ? obj?.game?.away_team?.team_name
                  : obj?.game?.home_team?.team_name,
              result: `${handleGetWinner(obj?.game?.winner, team_id)}  ${
                obj?.game?.home_team?.score
              } - ${obj?.game?.away_team?.score}`,
              pa: obj?.player_passing?.passing_attempt,
              cp: obj?.player_passing?.completed_passes,
              rec: obj?.player_receiving?.receiver,
              drops: obj?.player_receiving?.dropped,
              tds:
                obj?.player_passing?.touch_down +
                obj?.player_rushing?.touch_down +
                obj?.player_receiving?.touch_down +
                obj?.player_defense?.touch_down,
              xtds: obj?.player_passing?.xtds + obj?.player_receiving?.xtds,
              int:
                obj?.player_passing?.interception +
                obj?.player_rushing?.interception +
                obj?.player_defense?.interception,
              fp: obj?.player_defense?.flag_pool,
              bd: obj?.player_defense?.bat_down,
              pick: obj?.player_defense?.pick,
              dtds: obj?.player_defense?.dtds,
              sack: obj?.player_rushing?.sacks,
              blk: obj?.player_rushing?.block,
            };
          });
          setPlayerStat(newArray);
          setPlayerStatLoading(false);
        } else {
          setPlayerStatLoading(false);
        }
      })
      .catch((err: any) => {
        setPlayerStatLoading(false);
      });
  };

  // const handlePlayerCareerFetchBySpecificStats = async () => {
  //   const info = {
  //     team_id: team_id,
  //     player_id: id,
  //   };

  //   await fetchPlayerCareerStatsByIdSpecificStats(info)
  //     .then((response: RequestResponseInterface) => {
  //       if (response.status === 200) {
  //       } else {
  //         console.log("error fetching player stats");
  //       }
  //     })
  //     .catch((err: any) => {});
  // };

  const handleFetchCareerStat = async () => {
    setPlayerStatCareerLoading(true);

    //need fix -> player career stat
    await fetchCareerStatsById(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          //please fix

          const newArray = response.data.data.map((obj: any) => {
            console.log(obj);
            return {
              team: obj?.game?.week?.week_number,
              app:
                obj?.game?.home_team?.team_id === team_id
                  ? obj?.game?.away_team?.team_name
                  : obj?.game?.home_team?.team_name,
              result: `${handleGetWinner(obj?.game?.winner, team_id)}  ${
                obj?.game?.home_team?.score
              } - ${obj?.game?.away_team?.score}`,
              pa: obj?.player_passing?.passing_attempt,
              cp: obj?.player_passing?.completed_passes,
              rec: obj?.player_receiving?.receiver,
              drops: obj?.player_receiving?.dropped,
              tds:
                obj?.player_passing?.touch_down +
                obj?.player_rushing?.touch_down +
                obj?.player_receiving?.touch_down +
                obj?.player_defense?.touch_down,
              xtds: obj?.player_passing?.xtds + obj?.player_receiving?.xtds,
              int:
                obj?.player_passing?.interception +
                obj?.player_rushing?.interception +
                obj?.player_defense?.interception,
              fp: obj?.player_defense?.flag_pool,
              bd: obj?.player_defense?.bat_down,
              pick: obj?.player_defense?.pick,
              dtds: obj?.player_defense?.dtds,
              sack: obj?.player_rushing?.sacks,
              blk: obj?.player_rushing?.block,
            };
          });

          setPlayerStatCareer(newArray);
          setPlayerStatCareerLoading(false);
        } else {
          setPlayerStatCareerLoading(false);
        }
      })
      .catch((err: any) => {
        setPlayerStatCareerLoading(false);
      });
  };

  const handlePlayerInSeasonFetch = async () => {
    const info = {
      team_id: team_id,
      player_id: id,
    };

    const params = {
      division: "group a",
    };
    await fetchPlayerCareerStatsById(info, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          //please fix
          const newArray = response.data.results.map((obj: any) => {
            return {
              week: obj?.game?.week?.week_number,
              opp:
                obj?.game?.home_team?.team_id === team_id
                  ? obj?.game?.away_team?.team_name
                  : obj?.game?.home_team?.team_name,
              result: `${handleGetWinner(obj?.game?.winner, team_id)}  ${
                obj?.game?.home_team?.score
              } - ${obj?.game?.away_team?.score}`,
              pa: obj?.player_passing?.pa,
              cp: obj?.player_passing?.completed_passes,
              rec: obj?.player_receiving?.receiver,
              drops: obj?.player_receiving?.dropped,
              tds:
                obj?.player_passing?.touch_down +
                obj?.player_rushing?.touch_down +
                obj?.player_receiving?.touch_down +
                obj?.player_defense?.touch_down,
              xtds: obj?.player_passing?.xtds + obj?.player_receiving?.xtds,
              int:
                obj?.player_passing?.interception +
                obj?.player_rushing?.interception +
                obj?.player_defense?.interception,
              fp: obj?.player_defense?.flag_pool,
              bd: obj?.player_defense?.bat_down,
              pick: obj?.player_defense?.pick,
              dtds: obj?.player_defense?.dtds,
              sack: obj?.player_rushing?.sacks,
              blk: obj?.player_rushing?.block,
              date: obj?.game?.date,
            };
          });
          setPlayerStatInSeason(newArray);
        } else {
        }
      })
      .catch((err: any) => {});
  };

  const handlePlayerPreSeasonFetch = async () => {
    setPlayerStatLoading(true);
    const info = {
      team_id: team_id,
      player_id: id,
    };

    const params = {
      division: "group b",
    };
    await fetchPlayerCareerStatsById(info, params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          console.log(response.data.results);
          //please fix
          const newArray = response.data.results.map((obj: any) => {
            return {
              week: obj?.game?.week?.week_number,
              opp:
                obj?.game?.home_team?.team_id === team_id
                  ? obj?.game?.away_team?.team_name
                  : obj?.game?.home_team?.team_name,
              result: `${handleGetWinner(obj?.game?.winner, team_id)}  ${
                obj?.game?.home_team?.score
              } - ${obj?.game?.away_team?.score}`,
              pa: obj?.player_passing?.pa,
              cp: obj?.player_passing?.completed_passes,
              rec: obj?.player_receiving?.receiver,
              drops: obj?.player_receiving?.dropped,
              tds:
                obj?.player_passing?.touch_down +
                obj?.player_rushing?.touch_down +
                obj?.player_receiving?.touch_down +
                obj?.player_defense?.touch_down,
              xtds: obj?.player_passing?.xtds + obj?.player_receiving?.xtds,
              int:
                obj?.player_passing?.interception +
                obj?.player_rushing?.interception +
                obj?.player_defense?.interception,
              fp: obj?.player_defense?.flag_pool,
              bd: obj?.player_defense?.bat_down,
              pick: obj?.player_defense?.pick,
              dtds: obj?.player_defense?.dtds,
              sack: obj?.player_rushing?.sacks,
              blk: obj?.player_rushing?.block,
              date: obj?.game?.date,
            };
          });
          setPlayerStatPreSeason(newArray);
        } else {
          console.log("error fetching player stats");
        }
      })
      .catch((err: any) => {});
  };

  const handleNavigateNews = (id: string) => {
    navigate(`/news/${id}`);
  };

  const handleSelectType = (type: string) => {
    setType(type);
    if (type === "stat") {
      handlePlayerCareerFetch();
      handleFetchCareerStat();
    }
  };

  const handleAcceptContract = async (
    team_id: string,
    player_id: string,
    contract_id: string,
    contract_status: string
  ) => {
    Swal.fire({
      text: "Are you sure you want to update this contract?",
      icon: "warning",
      confirmButtonText: "Update",
      showCancelButton: true,
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setPlayerLoadingStates((prevState: any) => ({
          ...prevState,
          [player_id]: true,
        }));
        const payload = { team_id, player_id, contract_status };
        await updateContracts(contract_id, payload)
          .then((response: RequestResponseInterface) => {
            if (response.status === 200) {
              handleFetchContracts();
              toast.success("Contract successfully sent to this user.");
              setPlayerLoadingStates((prevState: any) => ({
                ...prevState,
                [player_id]: false,
              }));
            } else {
              toast.error("Error accepting contract");
              setPlayerLoadingStates((prevState: any) => ({
                ...prevState,
                [player_id]: false,
              }));
            }
          })
          .catch((err: any) => {
            toast.error("Error accepting contract");
            setPlayerLoadingStates((prevState: any) => ({
              ...prevState,
              [player_id]: false,
            }));
          });
      }
    });
  };

  return (
    <SHTSpinner loading={playerDetailsLoading}>
      <Toaster />
      <RoasterPlayerInfoHeader
        playerDetails={playerDetails}
        savedUserInfo={savedUserInfo}
      />
      {userInfo?._id === id && (
        <Container style={{ marginTop: 30, marginBottom: 30 }}>
          <div>
            <SHTText size="h2" text={`All Contracts`} color="white" />
          </div>

          {aContractApproved && (
            <Alert variant="warning">
              <i className="bi bi-exclamation-triangle-fill me-2"></i>
              You cant accept a contract if your current contract is not expired
              or terminated
            </Alert>
          )}

          <ol className="list-group list-group-numbered">
            {userContracts?.length > 0 ? (
              <>
                {userContracts.map((data: any) => (
                  <li className="list-group-item d-flex justify-content-between align-items-start contract_container">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <SHTText
                          size="h5"
                          color="white"
                          text={data.team.name}
                        />
                      </div>
                    </div>

                    <th style={{ marginTop: 5, marginRight: 20 }}>
                      <SHTText
                        size="p"
                        color="white"
                        text={formatDate(data.created_at)}
                      />
                    </th>
                    <span
                      style={{}}
                      className={`${
                        data.contract_status === "APPROVED"
                          ? "text-bg-primary"
                          : data.contract_status === "PENDING"
                          ? "text-bg-warning"
                          : "text-bg-danger"
                      } badge rounded-pill`}
                    >
                      {data.contract_status}
                    </span>

                    {data.contract_status === "PENDING" && (
                      <section>
                        <span style={{ marginLeft: 20 }}>
                          <SHTButton
                            type="submit"
                            name="Accept"
                            color="stats_secondary"
                            disabled={aContractApproved}
                            loading={playerLoadingStates[data.id]}
                            handleClick={() =>
                              handleAcceptContract(
                                data.team.id,
                                data.player.id,
                                data.id,
                                "APPROVED"
                              )
                            }
                          />
                        </span>
                        <span style={{ marginLeft: 20 }}>
                          <SHTButton
                            type="submit"
                            name="Reject"
                            color="stats_secondary"
                            loading={playerLoadingStates[data.id]}
                            handleClick={() =>
                              handleAcceptContract(
                                data.team.id,
                                data.player.id,
                                data.id,
                                "REJECTED"
                              )
                            }
                          />
                        </span>
                      </section>
                    )}
                  </li>
                ))}
              </>
            ) : (
              <SHTNoResult loading={contractLoading} />
            )}
          </ol>
        </Container>
      )}

      {!loading && (
        <section>
          <RoasterInfoFilter
            handleNavigate={(type: string) => handleSelectType(type)}
            playerInfo={playerDetails}
          />

          {type === "stat" ? (
            <RoasterInfoStats
              playerStat={playerStat}
              statLoading={playerStatLoading}
              playerStatInSeason={playerStatInSeason}
              playerStatPreSeason={playerStatPreSeason}
              playerStatCareerLoading={playerStatCareerLoading}
              playerStatCareer={playerStatCareer}
            />
          ) : (
            <>
              <RoasterInfoPlayer
                latestNews={latestNews}
                latesNewsLoading={latesNewsLoading}
                handleOnClick={(id: string) => handleNavigateNews(id)}
                tableInfo={playerDetails}
                title="RELATED CONTENT - ARTICLES"
              />
            </>
          )}
        </section>
      )}
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer, authReducer }: any) => ({
  newsReducer,
  authReducer,
});

export default connect(mapStateToProps, actions)(RoasterPlayerInfo);
