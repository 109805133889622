import React, { useEffect, useState } from "react";
import RoasterHeader from "./RoasterHeader";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import RoasterFilter from "./RoasterFilter";
import RoasterTable from "./RoasterTable";
import SHTSpinner from "../../components/SHTSpinner";
import TeamImage from "../../assets/images/svg/teamcardImage.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RequestResponseInterface } from "../../interfaces/axios.dto";

type MyParams = {
  id: string;
};

const RoasterPage = ({ fetchTeamRoster, fetchTeamsById }: any) => {
  const navigate = useNavigate();
  const [teamRoaster, setTeamRoaster] = useState([]);
  const [teamInfoImage, setTeamInfoImage] = useState();
  const [teamColor, setTeamColor] = useState();
  const [teamRoasterLoading, setTeamRosterLoading] = useState(true);
  const [teamLoading, setTeamLoading] = useState(true);
  const { id } = useParams<MyParams>();

  useEffect(() => {
    handleFetchTeamRoaster();
    handleFetchTeamInfo(id);
  }, []);

  const handleFetchTeamRoaster = async () => {
    const params = {
      team_id: id,
    };
    setTeamRosterLoading(true);
    await fetchTeamRoster(params)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTeamRoaster(response.data.data);
          setTeamRosterLoading(false);
        } else {
          setTeamRosterLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamRosterLoading(false);
      });
  };
  const handleFetchTeamInfo = async (id: any) => {
    setTeamLoading(true);
    await fetchTeamsById(id)
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          setTeamLoading(false);
          setTeamInfoImage(response.data.data.team_image[0].image);
          setTeamColor(response.data.data.team_color);
        } else {
          setTeamLoading(false);
        }
      })
      .catch((err: any) => {
        setTeamLoading(false);
      });
  };

  const handleNavigate = (page: string) => {
    if (page === "roaster") {
      navigate(`/roaster/${id}`);
    } else if (page === "info") {
      navigate(`/team-info/${id}`);
    } else if (page === "contracts") {
      navigate(`/contracts/${id}`);
    } else {
      navigate(`/contracts/${id}`);
    }
  };
  const handleNavigatePlayerRoaster = (player_id: string) => {
    navigate(`/players/roaster/${player_id}/${id}`);
  };

  return (
    <SHTSpinner loading={teamLoading}>
      <section>
        <RoasterHeader bannerImage={teamInfoImage} bannerColor={teamColor} />
        <RoasterFilter
          page="roaster"
          handleNavigate={(page: string) => handleNavigate(page)}
          id={id}
        />
        <RoasterTable
          roasterDetails={teamRoaster}
          handleClick={(id: string) => handleNavigatePlayerRoaster(id)}
        />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});
export default connect(mapStateToProps, actions)(RoasterPage);
