import axios from "../../utils/axios";
import Cookies from "js-cookie";

export const fetchBestMatchesJobs = (params: any) => async () => {
  return axios.get(`/posts`);
};

export const logout = () => async () => {
  return axios.post(`/account/logout/`, "", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};

export const playerLogin = (payload: any) => async () => {
  return axios.post(`/account/login/`, payload);
};

export const userRegistration = (payload: any) => async () => {
  return axios.post(`/account/register/`, payload);
};

export const forgotPassword = (payload: any) => async () => {
  return axios.post(`/account/forgot-password/`, payload);
};

export const resetPassword = (payload: any) => async () => {
  return axios.post(`/account/reset-password/`, payload);
};

export const verifyEmail = (payload: any) => async () => {
  return axios.get(`/activate/${payload}/`);
};

export const resendVerfication = (payload: any) => async () => {
  return axios.post(`/account/resend-confirmation/`, payload);
};

export const sendSubscribedEmail = (payload: any) => async () => {
  return axios.post(`/subscribe/`, payload);
};

export const getUserDetails = () => async () => {
  return axios.get("/account/user-data/", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
    },
  });
};
