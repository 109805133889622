import React from "react";
import RoasterHeader from "../roaster/RoasterHeader";
import SHTSpinner from "../../components/SHTSpinner";
import RoasterFilter from "../roaster/RoasterFilter";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import TeamOpponents from "./TeamOpponents";
import PlayerImage from "../../assets/images/svg/statsinfoimage.svg";
import TeanInfoStatsDetails from "./TeamInfoStatsDetails";
import titanlogo from "../../assets/images/svg/titansLogo.svg";
import TeamContract from "../contracts/TeamContract";

type MyParams = {
  id: string;
};

const TeamInfoStat = () => {
  const { id } = useParams<MyParams>();
  const navigate = useNavigate();
  const handleNavigatePage = (page: string) => {
    if (page === "roaster") {
      navigate(`/roaster/${id}`);
    } else if (page === "info") {
      navigate(`/team-info/${id}`);
    } else if (page === "contracts") {
      navigate(`/contracts/${id}`);
    } else {
      navigate(`/contracts/${id}`);
    }
  };

  const cardDetail = [
    {
      id: 0,
      jersey_number: "30",
      title: "COMPLETED PASSING",
      player_image: PlayerImage,
      position: "ATT",
      first_player_name: "KENNY NWAKWO",
      second_player_name: "Okabonye Chukwuma",
      third_player_name: "Inidara Uwa",
    },
    {
      id: 0,
      jersey_number: "30",
      title: "RECEPTION",
      player_image: PlayerImage,
      position: "ATT",
      first_player_name: "KENNY NWAKWO",
      second_player_name: "Okabonye Chukwuma",
      third_player_name: "Inidara Uwa",
    },
    {
      id: 0,
      jersey_number: "30",
      title: "TOUCHDOWNS",
      player_image: PlayerImage,
      position: "ATT",
      first_player_name: "KENNY NWAKWO",
      second_player_name: "Okabonye Chukwuma",
      third_player_name: "Inidara Uwa",
    },
  ];

  const TeamStats = {
    team_logo: titanlogo,
    team_name: "Team Athletics",
    statisctics: [
      {
        id: 0,
        home_team_score: "300",
        tag: "TOTAL FIRST DOWN",
        away_team_score: "280",
      },
      {
        id: 1,
        home_team_score: "300",
        tag: "TOTAL FIRST DOWN",
        away_team_score: "280",
      },
      {
        id: 2,
        home_team_score: "300",
        tag: "TOTAL FIRST DOWN",
        away_team_score: "280",
      },
      {
        id: 3,
        home_team_score: "300",
        tag: "TOTAL FIRST DOWN",
        away_team_score: "280",
      },
    ],
  };

  const OffenseDetails = [
    {
      id: 0,
      player_image: PlayerImage,
      att: "10",
      cp: "2",
      rec: "4",
      drops: "13",
      tds: "2",
      xtds: 3,
    },
    {
      id: 1,
      player_image: PlayerImage,
      att: "10",
      cp: "2",
      rec: "4",
      drops: "13",
      tds: "2",
      xtds: 3,
    },
    {
      id: 2,
      player_image: PlayerImage,
      att: "10",
      cp: "2",
      rec: "4",
      drops: "13",
      tds: "2",
      xtds: 3,
    },
  ];
  const DefenseDetails = [
    {
      id: 0,
      player_image: PlayerImage,
      int: "10",
      fp: "2",
      bd: "4",
      sack: "13",
      tds: "2",
      blk: 3,
    },
    {
      id: 1,
      player_image: PlayerImage,
      int: "10",
      fp: "2",
      bd: "4",
      sack: "13",
      tds: "2",
      blk: 3,
    },
    {
      id: 2,
      player_image: PlayerImage,
      int: "10",
      fp: "2",
      bd: "4",
      sack: "13",
      tds: "2",
      blk: 3,
    },
  ];

  return (
    <SHTSpinner loading={false}>
      <section>
        {/* <RoasterHeader /> */}
        <RoasterFilter
          page="stats"
          handleNavigate={(page: string) => handleNavigatePage(page)}
          id={id}
        />
        {/* <TeamOpponents /> */}
        <TeanInfoStatsDetails
          cardDetailsOffense={cardDetail}
          cardDetailsDefense={cardDetail}
          teamStats={TeamStats}
          offenseStats={OffenseDetails}
          defenseStats={DefenseDetails}
        />

        <TeamContract defenseStats={DefenseDetails} />
      </section>
    </SHTSpinner>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});
export default connect(mapStateToProps, actions)(TeamInfoStat);
