import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import "../../assets/styles/page/first_form_style.css";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import {
  EMAIL_INPUT_VALIDATION,
  PASSWORD_INPUT_VALIDATION,
  PHONE_NUMBER_INPUT_VALIDATION,
} from "../../utils/validation";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { couldStartTrivia } from "typescript";

interface FormInputs {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
}

function RegisterPage({ userRegistration }: any) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  let registerInput: FormInputs = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });

  const handleSubmitData = async (data: any) => {
    setLoading(true);
    await userRegistration(data)
      .then((response: RequestResponseInterface) => {
        setLoading(false);

        if (response.status === 201) {
          setLoading(false);
          toast.success("Registration successful");
          setRegistrationSuccessful(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        if (err.response?.status === 422) {
          toast.error("Validation error occurred, check that this email has not been used before");
          return;
        }
        toast.error("Sign up failed");
      });
  };

  return (
    <div>
      <section className="register_team_container">
        <Toaster />
        <Container>
          <section
            className="register_form_container"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <main className="form_container ">
              <section className="first_form_container">
                <div style={{ textAlign: "center" }}>
                  <img
                    src={Logo}
                    style={{ width: 100, height: 60, marginBottom: 10 }}
                    alt="Logo"
                  />
                  <div className="pb-4">
                    <SHTText size="h3" text="User Registration" color="gray" />
                  </div>
                </div>

                {registrationSuccessful ? (
                  <div style={{ textAlign: "center" }}>
                    <SHTText
                      size="p"
                      text="You have successfully registered, please check your email inbox to verify your email"
                      color="gray"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="form_element">
                      <Controller
                        control={control}
                        name="email"
                        rules={EMAIL_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Email"
                            placeholder=""
                            type="messageText"
                            value={value}
                            // editable={loading}
                            onChangeText={onChange}
                            error={errors.email && errors.email.message}
                          />
                        )}
                      />
                    </div>

                    <div className="form_element">
                      <Controller
                        control={control}
                        name="first_name"
                        rules={{
                          required: "First name is needed",
                        }}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="First Name"
                            placeholder=""
                            type="messageText"
                            value={value}
                            onChangeText={onChange}
                            error={
                              errors.first_name && errors.first_name.message
                            }
                          />
                        )}
                      />
                    </div>

                    <div className="form_element">
                      <Controller
                        control={control}
                        name="last_name"
                        rules={{
                          required: "Last name is needed",
                        }}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Last Name"
                            placeholder=""
                            type="messageText"
                            value={value}
                            onChangeText={onChange}
                            error={errors.last_name && errors.last_name.message}
                          />
                        )}
                      />
                    </div>
                    <div className="form_element">
                      <Controller
                        control={control}
                        name="phone_number"
                        rules={PHONE_NUMBER_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Phone Number"
                            placeholder=""
                            type="messageText"
                            value={value}
                            onChangeText={onChange}
                            error={
                              errors.phone_number && errors.phone_number.message
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="form_element">
                      <Controller
                        control={control}
                        name="password"
                        rules={PASSWORD_INPUT_VALIDATION}
                        render={({ field: { value, onChange } }) => (
                          <SHTInput
                            label="Password"
                            placeholder=""
                            type="password"
                            value={value}
                            onChangeText={onChange}
                            error={errors.password && errors.password.message}
                          />
                        )}
                      />
                    </div>

                    <section className="d-flex justify-content-between align-items-center">
                      <SHTButton
                        type="submit"
                        name="Register"
                        loading={loading}
                        handleClick={handleSubmit(handleSubmitData)}
                      />
                    </section>
                  </div>
                )}
              </section>
            </main>
          </section>
        </Container>
      </section>
    </div>
  );
}

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(RegisterPage);
