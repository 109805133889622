import React from "react";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import "../../assets/styles/page/standing_division_style.css";
import SHTNoResult from "../../components/SHTNoResult";

type TableProps = {
  id: string;
  image?: string;
  team?: string;
  win?: String;
  loose?: string;
  draw?: string;
  pct?: string;
  pf?: string;
  pa?: string;
  point?: string;
  strike?: string;
  last?: string;
};

interface standingDivisonProps {
  divisionOne: any;
  loading: boolean;
  //division: string;
  //divisionTwo: any
}
const StandingDivision = (props: standingDivisonProps) => {
  const { divisionOne, loading /* division */ /* divisionTwo */ } = props;
  return (
    <Container className="sht_container_space">
      {divisionOne?.length > 0 ? (
        <>
          <div className="stats_table_container ">
            <table>
              <div className="px-2 py-4">
                {/* <SHTText
                  size="p"
                  color="gray"
                  text={division !== "" ? division : "STANDINGS"}
                /> */}
              </div>
              <tr className="stats_table_header">
                <th className="py-4 px-3">
                  <SHTText size="p" color="white" text="Team" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="W" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="L" />
                </th>
                <th className="py-4 px-3 standing_border_right_cell">
                  <SHTText size="p" color="gray" text="D" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="PCT" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="PF" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="PA" />
                </th>
                <th className="py-4 px-3 standing_border_right_cell">
                  <SHTText size="p" color="gray" text="NET PTS" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="STRK" />
                </th>
                <th className="py-4 px-3">
                  <SHTText size="p" color="gray" text="LAST 5" />
                </th>
              </tr>

              {divisionOne.map((division: TableProps) => {
                return (
                  <tr className="stats_table_header">
                    <td className="py-4  d-flex align-items-center">
                      <img
                        src={division.image}
                        alt="titanslogo"
                        className="team_logo_standings"
                      />
                      <SHTText size="p" color="white" text={division.team} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.win} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.loose} />
                    </td>
                    <td className="py-4 px-3 standing_border_right_cell">
                      <SHTText size="p" color="gray" text={division.draw} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.pct} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.pf} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.pa} />
                    </td>
                    <td className="py-4 px-3  standing_border_right_cell">
                      <SHTText size="p" color="gray" text={division.point} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.strike} />
                    </td>
                    <td className="py-4 px-3">
                      <SHTText size="p" color="gray" text={division.last} />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </>
      ) : (
        <SHTNoResult loading={loading} />
      )}

      {/* <div className="stats_table_container second_table">

                <table>
                    <div className="px-2 py-4">
                        <SHTText size="p" color='gray' text='DIVISION TWO' />
                    </div>
                    <tr className="stats_table_header">
                        <th className="py-4 px-3">
                            <SHTText size="p" color="white" text="Team" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="W" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="L" />
                        </th>
                        <th className="py-4 px-3 standing_border_right_cell">
                            <SHTText size="p" color="gray" text="D" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="PCT" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="PF" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="PA" />
                        </th>
                        <th className="py-4 px-3 standing_border_right_cell">
                            <SHTText size="p" color="gray" text="NET PTS" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="STRK" />
                        </th>
                        <th className="py-4 px-3">
                            <SHTText size="p" color="gray" text="LAST 5" />
                        </th>
                    </tr>
                    {
                        divisionTwo.map((division: TableProps) => {
                            return (
                                <tr className="stats_table_header">
                                    <td className="py-4  d-flex align-items-center">
                                        <img src={division.image} alt="titanslogo" />
                                        <SHTText size="p" color="white" text={division.team} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.win} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.loose} />
                                    </td>
                                    <td className="py-4 px-3 standing_border_right_cell">
                                        <SHTText size="p" color="gray" text={division.draw} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.pct} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.pf} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.pa} />
                                    </td>
                                    <td className="py-4 px-3  standing_border_right_cell">
                                        <SHTText size="p" color="gray" text={division.point} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.strike} />
                                    </td>
                                    <td className="py-4 px-3">
                                        <SHTText size="p" color="gray" text={division.last} />
                                    </td>
                                </tr>
                            )
                        })
                    }

                </table>

            </div> */}
    </Container>
  );
};

export default StandingDivision;
