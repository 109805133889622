import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTFileUpload from "../../components/SHTFileUpload";
import FileUpload from "../../assets/images/svg/file.svg";
import SHTButton from "../../components/SHTButton";
import { CompactPicker } from "react-color";
import {
  COLOR_INPUT_VALIDATION,
  EMAIL_INPUT_VALIDATION,
} from "../../utils/validation";
import useFormPersistence from "../../utils/helper/useFormPersist";

type FormInputs = {
  name: string;
  team_email: string;
  team_color: string;
  team_image: any;
  abbreviation: string;
};

const TeamInformation = ({ onUpdateFormData, handleSubmitData }: any) => {
  const [uploadedFile, setUploadedFile] = useState();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  let loginInitalValues: FormInputs = {
    name: "",
    team_email: "",
    abbreviation: "",
    team_color: "",
    team_image: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });

  // Use the custom hook to handle form persistence
  useFormPersistence<FormInputs>("teamInformation", setValue, watch);

  const handleLogo = (acceptedFiles: any, rejectedFiles: any[]) => {
    const file = acceptedFiles[0];
    console.log(file);
    setUploadedFile(file);

    // Generate a preview URL
    const preview = URL.createObjectURL(file);
    setPreviewUrl(preview);

    setValue("team_image", {
      image: file,
      alt_text: "team_logo",
      banner: false,
    });
    return;
  };

  const handleColorChange = (color: any) => {
    setValue("team_color", color.hex);
  };

  return (
    <section className="first_form_container">
      <div className="pb-4">
        <SHTText size="h2" text="Team Information" color="gray" />
      </div>
      <form className="form_list">
        <div className="form_element">
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Team Name is needed",
            }}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Team Name"
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
                error={errors.name && errors.name.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="abbreviation"
            rules={{
              required: "Team Abbreviation is needed",
            }}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Team Abbreviation"
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
                error={errors.abbreviation && errors.abbreviation.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="team_email"
            rules={EMAIL_INPUT_VALIDATION}
            render={({ field: { value, onChange } }) => (
              <SHTInput
                label="Team Email"
                placeholder=""
                type="messageText"
                value={value}
                onChangeText={onChange}
                error={errors.team_email && errors.team_email.message}
              />
            )}
          />
        </div>
        <div className="form_element">
          <Controller
            control={control}
            name="team_color"
            rules={COLOR_INPUT_VALIDATION}
            render={({ field: { value, onChange } }) => (
              <div>
                <SHTText text="Team Color" size="h6" color="gray" />
                <CompactPicker color={value} onChangeComplete={handleColorChange} />
                {errors.team_color && <p className="error">{errors.team_color.message}</p>}
              </div>
            )}
          />
        </div>
        <div className="form_element">
          <div className="upload_label">
            <SHTText text="Team Logo" size="h6" color="gray" />
          </div>
          <SHTFileUpload type="file" name="Team Logo" onDrop={handleLogo}>
            <div className="upload_container">
              {previewUrl ? (
                <>
                  <SHTText text="Image:" size="p" color="gray" />
                  <img src={previewUrl} alt="Team Logo Preview" className="image_preview" />
                </>
              ) : (
                <>
                  <img src={FileUpload} alt="Upload Icon" width="20px" />
                  <div className="upload_text">
                    <SHTText text="Add File" size="p" color="gray" />
                  </div>
                </>
              )}
            </div>
          </SHTFileUpload>
        </div>
      </form>

      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(handleSubmitData)}
        />
      </div>
    </section>
  );
};

export default TeamInformation;
