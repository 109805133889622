import React, { ReactNode } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";

import { Spinner } from "react-bootstrap";
import SHTFooter from "./SHTFooter";
import SHTHeader from "./SHTHeader";
import SHTMobileNav from "./SHTMobileNav";

interface SpinnerProps {
  loading: boolean;
  children: ReactNode;
  newsReducer?: any;
}

const SHTSpinner = (props: SpinnerProps) => {
  const { loading, children, newsReducer } = props;


  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            zIndex: 100,
          }}
        >
          {/* @ts-ignore */}
          <Spinner animation="border" variant="dark" size={200} />
        </div>
      ) : (
        <main
          className={
            newsReducer.mode == "light"
              ? "sh_background_light"
              : "sh_background_dark"
          }
        >
          <SHTHeader />

          {children}
          <SHTFooter />
        </main>
      )}
    </>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(SHTSpinner);
