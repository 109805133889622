import React from "react";
import "../../assets/styles/page/roaster_player_info_header_style.css";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import TeamImage from "../../assets/images/svg/azeez.svg";
import TicketImage from "../../assets/images/svg/ticketImage.svg";

type playerInfo = {
  playerDetails: any;
  savedUserInfo: any;
};
const RoasterPlayerInfoHeader = (props: playerInfo) => {
  const { playerDetails, savedUserInfo } = props;
  return (
    <div className="roaster_info_clip_container">
      <Container className=" roaster_details_container">
        <div>
          <SHTText
            size="h5"
            text={
              playerDetails?.player_name
                ? playerDetails?.player_name
                : savedUserInfo?.first_name
            }
            color="white"
          />
          <div className="d-flex items-align-center">
            <SHTText size="h4" text="S." color="white" />
            <div className="mx-2">
              <SHTText
                size="h4"
                text={
                  playerDetails?.drafted_year
                    ? playerDetails?.drafted_year
                    : "N/A"
                }
                color="white"
              />
            </div>
          </div>
          <SHTText
            size="h6"
            text={playerDetails.team?.name ? playerDetails.team?.name : "None"}
            color="blue"
          />
          <div className="my-5">
            <SHTText
              size="h6"
              text={playerDetails.isActive || "ACTIVE"}
              color="active"
            />
          </div>
        </div>
        <div>
          <img
            src={
              playerDetails.player_image
                ? playerDetails.player_image
                : TicketImage
            }
            alt={playerDetails?.player_name}
            className="roaster_player_image"
          />
        </div>
      </Container>
    </div>
  );
};

export default RoasterPlayerInfoHeader;
