import React, { useEffect } from "react";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
import "../../assets/styles/page/standing_header_style.css";


type CardProps = {
    handleFetchStandingCategory: Function
};

const StandingHeader = ({ handleFetchStandingCategory }: CardProps ) => {

    return (
        <section className="standing_header_container">
            <div className="overlay"></div>
            <Container className="py-2">
                <div className="d-flex h-100 ">
                    <div className="header_text">
                        <SHTText size="h2" text="STANDINGS" color="white" />
                        <SHTText size="p" text="SHOWTIME STANDINGS" color="gray" />
                        <div className="d-flex my-3">
                            {/* <SHTButton name="DIVISION" color="primary" type="submit" handleClick={() => handleFetchStandingCategory("division")} />
                            <div className="mx-3">
                                    <SHTButton name="LEAGUE" color="dark" type="submit" handleClick={() => handleFetchStandingCategory("league")} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default StandingHeader;
