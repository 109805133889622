import React, { FC } from "react";
import Card from "react-bootstrap/Card";
import "../assets/styles/page/league_card_style.css";
import SHTText from "./SHTText";

interface CardInputFieldProps {
  id?: string;
  imageTeam1?: string;
  imageTeam2?: string;
  date?: string;
  time?: string;
  location?: string;
  children?: React.ReactNode;
  team1?: string;
  team2?: string;
  handleOnClick?: any;
}
const SHTLeagueCard: FC<CardInputFieldProps> = ({
  id,
  imageTeam1,
  imageTeam2,
  date,
  time,
  location,
  team1,
  team2,
  handleOnClick,
  
}) => {
  return (
    <Card >
      <Card.Body className="sh_background_dark fixtures_card_body">
        <div className=" league_card_body">
          <div className="league_card_upper"></div>
          <div className="d-flex align-items-center  p-3 league_schedule_card">
            <div className="text-center team_details">
              <img
                src={imageTeam1}
                alt="First Image Team"
                className="team_logo_image"
              />
              <SHTText size="h5" text={team1} color="gray" />
            </div>
            <div className="mx-2">
              <SHTText size="h5" text="VS" color="gray" />
            </div>
            <div className="text-center team_details">
              <img
                src={imageTeam2}
                alt="First Image Team"
                className="team_logo_image"
              />
              <SHTText size="h5" text={team2} color="gray" />
            </div>
          </div>
          <div className="league_card_lower"></div>
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex">
                <SHTText size="p" text={date} color="blue" /> &nbsp;
                <SHTText size="p" text={time} color="gray" />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center ">
              <div className="d-flex">
                <SHTText size="p" text="Location: " color="gray" /> &nbsp;
                <SHTText size="p" text={location} color="blue" />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SHTLeagueCard;
