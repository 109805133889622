import React from "react";
import { Container } from "react-bootstrap";
import SHTLeagueCard from "../../components/SHTLeagueCard";
import SHTNoResult from "../../components/SHTNoResult";
import SHTPagination from "../../components/SHTPagination";
import "../../assets/styles/page/league_card_style.css";

interface propsInterface {
  cardDetails: any;
  loading: boolean;
  pagination: any
  handlePageChange: any
}
const LeagueDetails = (props: propsInterface) => {
  const { cardDetails, loading, pagination, handlePageChange } = props;
  return (
    <Container>
      <div className="league_multiple_card">
        {cardDetails?.length > 0 ? (
          <>
            {cardDetails.map((detail: any) => {
              return (
                <SHTLeagueCard
                  id={detail.id}
                  imageTeam1={detail.home_team?.team_image[0]?.image}
                  imageTeam2={detail.away_team?.team_image[0]?.image}
                  team1={detail.home_team.abbreviation}
                  team2={detail.away_team.abbreviation}
                  date={detail.date}
                  time={detail.time}
                  location={detail.location}
                />
              );
            })}

            <SHTPagination currentPage={pagination?.page} totalPages={pagination?.total_pages} onPageChange={handlePageChange} />
          </>
        ) : (
          <SHTNoResult loading={loading} />
        )}
      </div>


    </Container>
  );
};

export default LeagueDetails;
