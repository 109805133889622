import React from "react";
import "../../assets/styles/page/roaster_player_info_style.css";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import SHTNewsSection from "../../components/SHTNewsSection";
import SHTCard from "../../components/SHTCard";
import SHTLineHeader from "../../components/SHTLineHeader";
import { convertToAge, getSeason } from "../../utils/helper";
type playerInfo = {
  tableInfo: any;
  handleOnClick: any;
  latestNews: any;
  latesNewsLoading: boolean;
  title?: any;
};
type tablePropsType = {
  height?: string;
  weight?: string;
  age?: string;
  season?: string;
  jerseryNumber?: string;
  position?: string;
};
interface propsInterface {}

const RoasterInfoPlayer = (props: playerInfo) => {
  const { tableInfo, latestNews, latesNewsLoading, handleOnClick, title } =
    props;
  return (
    <Container className="sht_container_space">
      <div className="roaster_player_info_container ">
        <div className="px-4 py-2">
          <SHTText size="p" text="PLAYER INFO" color="white" />
        </div>
        <div className="overflow-auto">
          <table>
            <thead>
              <tr>
                <th className="px-4 py-2 roaster_player_box">
                  <SHTText text="Height" size="p" color="gray" />
                  <SHTText text={tableInfo.height} size="p" color="white" />
                </th>
                <th className="px-4 py-2 roaster_player_box">
                  <SHTText text="Weight" size="p" color="gray" />
                  <SHTText text={tableInfo.weight} size="p" color="white" />
                </th>
                <th className="px-4 py-2 roaster_player_box">
                  <SHTText text="Age" size="p" color="gray" />
                  <SHTText
                    text={convertToAge(tableInfo.age)}
                    size="p"
                    color="white"
                  />
                </th>
                <th className="px-4 py-2 roaster_player_box">
                  <SHTText text="Seasons" size="p" color="gray" />
                  <SHTText
                    text={getSeason(tableInfo.drafted_year)}
                    size="p"
                    color="white"
                  />
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="roaster_info_footer">
          <div className="roaster_info_footer_line"></div>
          <div className="py-2">
            <th className="px-4 py-2 roaster_player_box">
              <SHTText text="Jersey Number" size="p" color="gray" />
              <SHTText text={tableInfo.jersey_number} size="p" color="white" />
            </th>
            <th className="px-5 py-2 roaster_player_box">
              <SHTText text="Position" size="p" color="gray" />
              <SHTText
                text={tableInfo.position?.position_name}
                size="p"
                color="white"
              />
            </th>
          </div>
        </div>
      </div>
      <div className="sht_container_space ">
        <div className="">
          <SHTLineHeader headerText={title} lineColor="white" />
        </div>
        <div className="sh_news_container">
          {latestNews.map((news: any) => {
            return (
              <div key={news.id}>
                <SHTCard
                  handleOnClick={handleOnClick}
                  id={news.id}
                  //@ts-ignore
                  image={news?.featured_image?.image}
                  alt={news?.alt_text}
                  title={news.title}
                  date={news.created_at}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default RoasterInfoPlayer;
