import React from "react";
import { Spinner } from "react-bootstrap";
import "../assets/styles/components/sht_button_styles.css";

type SHTButtonProps = {
  handleClick?: any;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
  disabled?: boolean;
  name: string;
  type: "submit";
  color?:
    | "primary"
    | "secondary"
    | "light"
    | "dark"
    | "empty"
    | "highlight"
    | "stats_primary"
    | "stats_secondary"
    | "game_filter_primary"
    | "game_filter_secondary"
    | "team_registration";
  size?: any;
  icon?: any;
  rightIcon?: any;
  className?: string;
  loading?: boolean;
  loadValue?: boolean;
  onKeyPress?: Function;
};

const SHTButton: React.FC<SHTButtonProps> = ({
  handleClick,
  loading,
  loadValue,
  disabled,
  type,
  name,
  color,
  size,
  icon,
  rightIcon,
  className,
  onKeyPress,
}) => {
  let colorClassName = "sht_button_primary";
  let sizeClassName = "sht_button_large";

  switch (color) {
    case "primary":
      colorClassName = "sht_button_primary";
      break;

    case "secondary":
      colorClassName = "sht_button_secondary";
      break;
    case "light":
      colorClassName = "sht_button_light";
      break;
    case "empty":
      colorClassName = "sht_button_empty";
      break;
    case "dark":
      colorClassName = "sht_button_dark";
      break;
    case "highlight":
      colorClassName = "sht_button_highlight";
      break;
    case "stats_primary":
      colorClassName = "sht_button_stats_primary";
      break;
    case "stats_secondary":
      colorClassName = "sht_button_stats_secondary";
      break;
    case "game_filter_primary":
      colorClassName = "sht_button_game_filter_primary";
      break;
    case "game_filter_secondary":
      colorClassName = "sht_button_game_filter_secondary";
      break;
    case "team_registration":
      colorClassName = "sht_button_team_registration";
      break;
  }

  switch (size) {
    case "sm":
      sizeClassName = "sht_button_small";
      break;

    case "md":
      sizeClassName = "sht_button_medium";
      break;

    case "lg":
      sizeClassName = "sht_button_large";
      break;
  }

  // Combine color and size class names
  const combinedClassName = `${colorClassName} ${sizeClassName} ${className}`;

  return (
    <button
      className={`${combinedClassName} ${disabled && "sht_button_disabled"}`}
      type={type}
      onClick={handleClick}
      disabled={loading || disabled ? true : false}
    >
      {loading ? (
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {/* @ts-ignore */}
          <Spinner
            animation="border"
            variant="light"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
      ) : (
        <>
          {" "}
          {icon && <span style={{ marginRight: 20 }}>{icon}</span>}
          {name}
          {rightIcon && <span style={{ marginRight: 20 }}>{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

SHTButton.defaultProps = {
  loading: false,
};

export default SHTButton;
