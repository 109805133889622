import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import TeamFilter from "./TeamFilter";
import TeamDetails from "../team/TeamDetails";
import TeamHeader from "./TeamHeader";
import TeamBanner from "./TeamBanner";
import SHTSpinner from "../../components/SHTSpinner";
import { useNavigate } from "react-router-dom";
import { teamResponse } from "../../interfaces/axios.dto";
import ScoreFilter from "../score/ScoreFilter";

interface TeamsProps {
  fetchTeams: Function;
}

const TeamSchedule = ({ fetchTeams }: TeamsProps) => {
  const [headerType, setHeaderType] = useState("");
  const [teamDetails, setTeamDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = React.useState(true);

  useEffect(() => {
    handleTeamFetch(null);
  }, []);

  const handleTeamFetch = async (params: any) => {
    setTeamDetails([]);
    setLoading(true);
    await fetchTeams(params)
      .then((response: teamResponse) => {
        if (response.status === 200) {
          setTeamDetails(response.data.results);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("error fetching skills");
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const handleNavigate = async (type: string) => {
    setActive(type === "league");
    await setHeaderType(type);
    navigate(`/${type}/schedule`);
  };

  const handleNavigateTeam = () => {
    navigate(`/team-of-the-week`);
  };

  const handleButtonClick = (id: string | number, image: string) => {
    navigate(`/teams/schedule/${id}`, { state: { image } });
  };

  return (
    <SHTSpinner loading={false}>
      <TeamHeader
        handleNavigate={(type: string) => handleNavigate(type)}
        active={active}
      />
      <ScoreFilter
        subTitle="SHOWTIME SCHEDULE"
        title={"SCHEDULES"}
        handleFilter={(filter: string) => handleTeamFetch(filter)}
      />

      <TeamDetails
        loading={loading}
        cardDetails={teamDetails}
        actionName="SCHEDULE"
        handleClick={(id: string | number, image: string) =>
          handleButtonClick(id, image)
        }
      />
      <TeamBanner handleClick={handleNavigateTeam} />
    </SHTSpinner>
  );
};

const mapStateToProps = ({}: any) => ({});

export default connect(mapStateToProps, actions)(TeamSchedule);
