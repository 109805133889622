import React, { useState } from "react";
import SHTText from "../../components/SHTText";
import { Controller, useForm } from "react-hook-form";
import SHTInput from "../../components/SHTInput";
import SHTRadio from "../../components/SHTRadio";
import SHTButton from "../../components/SHTButton";

interface FormInputs {
  have_health_insurance: string;
  heard_of_showtime: string;
}
const FifthForm = ({ handleSubmitData, loading }: any) => {
  let registerInput: FormInputs = {
    have_health_insurance: "",
    heard_of_showtime: "",
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: registerInput,
  });
  const RadiOptions = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const onSubmit = (data: FormInputs) => {
    // Call the external submit handler
    handleSubmitData(data);
  };

  const options = [
    { label: "Instagram", value: "Instagram" },
    { label: "Twitter", value: "Twitter" },
    { label: "Youtube", value: "Youtube" },
    { label: "Tiktok", value: "Tiktok" },
    { label: "Word of Mouth", value: "Word of Mouth" },
    { label: "Other", value: "Other" },
  ];
  return (
    <section className="first_form_container">
      <form>
        <div className="pb-4">
          <SHTText size="h3" text="Player Information" color="gray" />
        </div>
        <div className="mt-4">
          <SHTRadio
            name="have_health_insurance"
            options={RadiOptions}
            control={control}
            onRadioChange={(value) => setValue("have_health_insurance", value)}
            legend=<p className="radio_text">Do you have Health Insurance?</p>
          />
        </div>
        <div className="mt-4">
          <div className="mt-3">
            <SHTRadio
              name="heard_of_showtime"
              options={options}
              control={control}
              onRadioChange={(value) => setValue("heard_of_showtime", value)}
              legend=<p className="radio_text">
                Where did you hear about Showtime Flag?
              </p>
            />
          </div>
        </div>
      </form>
      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Submit"
          loading={loading}
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default FifthForm;
