import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { CompactPicker } from "react-color";
import "../assets/styles/page/team_information_style.css";
import SHTText from "./SHTText";
import SHTInput from "./SHTInput";
import { COLOR_INPUT_VALIDATION } from "../utils/validation";
import SHTButton from "./SHTButton";

type FormInputs = {
  abbreviation: string;
  team_color: string;
  no_of_player: string;
  bank_account: string;
  team_instagram: string;
  team_tiktok: string;
  team_facebook: string;
  team_twitter: string;
};

const SHTEditTeamInfoModal = ({ show, handleClose, handleSubmitData, initialData }: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>();

  // Set default values from initialData
  useEffect(() => {
    if (initialData) {
      setValue("abbreviation", initialData.abbreviation || "");
      setValue("team_color", initialData.team_color || "");
      setValue("no_of_player", initialData.no_of_player || "");
      setValue("bank_account", initialData.bank_account || "");
      setValue("team_instagram", initialData.team_instagram || "");
      setValue("team_tiktok", initialData.team_tiktok || "");
      setValue("team_facebook", initialData.team_facebook || "");
      setValue("team_twitter", initialData.team_twitter || "");
    }
  }, [initialData, setValue]);

  const handleColorChange = (color: any) => {
    setValue("team_color", color.hex);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <SHTText size="h5" text="Edit Team Information" color="gray" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form_element">
            <Controller
              control={control}
              name="abbreviation"
              rules={{ required: "Team Abbreviation is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Team Abbreviation"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.abbreviation && errors.abbreviation.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="team_color"
              rules={COLOR_INPUT_VALIDATION}
              render={({ field: { value, onChange } }) => (
                <div>
                  <SHTText text="Team Color" size="h6" color="gray" />
                  <CompactPicker color={value} onChangeComplete={handleColorChange} />
                  {errors.team_color && <p className="error">{errors.team_color.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="no_of_player"
              rules={{ required: "Number of Players is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Number of Players"
                  placeholder=""
                  type="number"
                  value={value}
                  onChangeText={onChange}
                  error={errors.no_of_player && errors.no_of_player.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="bank_account"
              rules={{ required: "Bank Account is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Bank Account"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.bank_account && errors.bank_account.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="team_instagram"
              rules={{ required: "Instagram Account is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Instagram Account"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.team_instagram && errors.team_instagram.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="team_tiktok"
              rules={{ required: "Tiktok Account is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Tiktok Account"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.team_tiktok && errors.team_tiktok.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="team_facebook"
              rules={{ required: "Facebook Account is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Facebook Account"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.team_facebook && errors.team_facebook.message}
                />
              )}
            />
          </div>
          <div className="form_element">
            <Controller
              control={control}
              name="team_twitter"
              rules={{ required: "Twitter Account is needed" }}
              render={({ field: { value, onChange } }) => (
                <SHTInput
                  label="Twitter Account"
                  placeholder=""
                  type="messageText"
                  value={value}
                  onChangeText={onChange}
                  error={errors.team_twitter && errors.team_twitter.message}
                />
              )}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <SHTButton
          type="submit"
          name="Save Changes"
          handleClick={handleSubmit(handleSubmitData)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SHTEditTeamInfoModal;
