import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Logo from "../../assets/images/showtime_logo.png";
import "../../assets/styles/page/login_style.css";
import SHTText from "../../components/SHTText";
import { useForm, Controller } from "react-hook-form";
import SHTInput from "../../components/SHTInput";
import SHTButton from "../../components/SHTButton";
import { RequestResponseInterface } from "../../interfaces/axios.dto";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import store from "../../store";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import {
  EMAIL_INPUT_VALIDATION,
  PASSWORD_INPUT_VALIDATION,
} from "../../utils/validation";

interface FormInputs {
  email: string;
  password: string;
}
const LoginPage = ({ playerLogin, getUserDetails }: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let loginInitalValues: FormInputs = {
    email: "",
    password: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });

  const handleFetchUser = async () => {
    await getUserDetails()
      .then((response: RequestResponseInterface) => {
        if (response.status === 200) {
          if (response.data.response_code === 3000) {
            store.dispatch({
              type: "USER",
              payload: response.data.data,
            });

            Cookies.set("user", JSON.stringify(response.data.data), {
              expires: 7,
            });
            if (response.data.data.status === "Team") {
              setLoading(false);
              navigate(`/team-info/${response.data.data._id}`);
              return;
            }
            navigate(
              `/players/roaster/${response.data.data._id}/${response.data.data.team_id}`
            );
            setLoading(false);
          }
        }
      })
      .catch((err: any) => {
        setLoading(false);
        if (err.response.status === 401) {
          toast.error("Login failed.");
          return;
        }
        toast.error("Login failed");
      });
  };

  const handleLogin = async (data: any) => {
    setLoading(true);

    await playerLogin(data)
      .then((response: RequestResponseInterface) => {

        if (response.status === 200) {
          Cookies.set("auth_token", response.data.data.access_token);
          handleFetchUser();
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);

        if (err.response.status === 401) {
          if (err.response.data.response_code === 4001) {
            toast.error("No active account found with the given credentials.");
            return;
          }
        } else {
          toast.error("No active account found with the given credentials.");
          return;
        }
        toast.error("Login error");
      });
  };

  return (
    <section className="register_team_container">
      <Toaster />
      <Container>
        <section
          className="register_form_container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <main className="form_container ">
            <section className="first_form_container">
              <div style={{ textAlign: "center" }}>
                <img
                  src={Logo}
                  style={{ width: 100, height: 60, marginBottom: 10 }}
                  alt="Logo"
                />
                <div className="pb-4">
                  <SHTText size="h3" text="User Login" color="gray" />
                </div>
              </div>
              <main className="login_form_container">
                <section>
                  <div className="login_input">
                    <Controller
                      control={control}
                      name="email"
                      rules={EMAIL_INPUT_VALIDATION}
                      render={({ field: { value, onChange } }) => (
                        <SHTInput
                          hasLabel={false}
                          placeholder="username@gmail.com"
                          type="messageText"
                          value={value}
                          // editable={loading}
                          onChangeText={onChange}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="password"
                    rules={PASSWORD_INPUT_VALIDATION}
                    render={({ field: { value, onChange } }) => (
                      <SHTInput
                        hasLabel={false}
                        placeholder="**********"
                        type="password"
                        value={value}
                        // editable={loading}
                        onChangeText={onChange}
                      />
                    )}
                  />
                </section>
                <section className="d-flex justify-content-between align-items-center">
                  <div
                    className="pointer"
                    onClick={() => navigate("/forgot_password")}
                  >
                    <SHTText
                      size="p"
                      text="Forgot your password?"
                      color="blue"
                    />
                  </div>
                  <SHTButton
                    type="submit"
                    name="Log In"
                    loading={loading}
                    handleClick={handleSubmit(handleLogin)}
                  />
                </section>

                <div
                  style={{
                    textAlign: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <div onClick={() => navigate("/login")}>
                    {" "}
                    <SHTText
                      size="p"
                      text="Don't have an account?"
                      color="white"
                    />
                  </div>

                  <div
                    className="pointer"
                    onClick={() => navigate("/register")}
                  >
                    <SHTText size="p" text=" Register" color="blue" />
                  </div>
                </div>
              </main>
            </section>
          </main>
        </section>
      </Container>
    </section>
  );
};

const mapStateToProps = ({ newsReducer }: any) => ({
  newsReducer,
});

export default connect(mapStateToProps, actions)(LoginPage);
