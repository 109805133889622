import React from "react";
import Card from 'react-bootstrap/Card';
import { Container } from "react-bootstrap";
import SHTText from "../../components/SHTText";
const LeagueBye = () => {
    return (
        <Container className="my-5">
            <div>
                <SHTText size="h1" text="TEAMS ON BYE" color="white" />
            </div>
            <Card>
                <Card.Body className="sh_background_dark border rounded"><SHTText size="p" text="No team have a bye this week." color="gray" /></Card.Body>
            </Card>

        </Container>
    )
}

export default LeagueBye
