import React from "react";
import { useForm, Controller } from "react-hook-form";
import SHTText from "../../components/SHTText";
import SHTInput from "../../components/SHTInput";
import "../../assets/styles/page/team_information_style.css";
import SHTRadio from "../../components/SHTRadio";
import SHTButton from "../../components/SHTButton";
import useFormPersistence from "../../utils/helper/useFormPersist";

interface FormInputs {
  bank_account: string;
  cac: any;
  team_verify: any;
  team_tier: any;
}
const TeamDetails = ({ handleSubmitData }: any) => {
  let loginInitalValues: FormInputs = {
    bank_account: "",
    cac: "",
    team_tier: "",
    team_verify: "",
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: loginInitalValues,
  });
  const RadiOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  const RadiOptions2 = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  const TierOptions = [
    {
      label: "Tier 1 (Independent team)",
      value: "Tier 1",
    },
    {
      label: "Tier 2 (Partner Team)",
      value: "Tier 2",
    },
    {
      label: "Tier 3 (Incubator Team)",
      value: "Tier 3",
    },
  ];

  const onSubmit = (data: FormInputs) => {
    // Call the external submit handler
    handleSubmitData(data);
  };

  // Use the custom hook to handle form persistence
  useFormPersistence<FormInputs>("TeamDetails", setValue, watch);
  return (
    <section className="first_form_container team_management">
      <div className="pb-4">
        <SHTText size="h2" text="Team Details" color="gray" />
      </div>
      <div className="form_list">
        <div className="">
        <Controller
          control={control}
          name="cac"
          rules={{
            required: "Enter your club CAC",
          }}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Team CAC number"
              placeholder=""
              type="messageText"
              value={value}
              // editable={loading}
              onChangeText={onChange}
            />
          )}
        />
        </div>
        <div className="my-4">
          <SHTRadio
            name="team_verify"
            options={RadiOptions2}
            control={control}
            onRadioChange={(value) => setValue("team_verify", value)}
            legend=<p className="radio_text">Do you have a team account?</p>
          />
        </div>
        <Controller
          control={control}
          name="bank_account"
          rules={{
            required: "Bank Details is needed",
          }}
          render={({ field: { value, onChange } }) => (
            <SHTInput
              label="Team Bank Name and Account Number"
              placeholder=""
              type="messageText"
              value={value}
              // editable={loading}
              onChangeText={onChange}
            />
          )}
        />
  
      </div>

      <div className="next_button player_form_button">
        <SHTButton
          type="submit"
          name="Next Page"
          handleClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default TeamDetails;
