import React from "react";
import { newsInterface } from "../../interfaces/axios.dto";
import SHTCard from "../../components/SHTCard";
import SHTText from "../../components/SHTText";
import { Container } from "react-bootstrap";
import SHTLineHeader from "../../components/SHTLineHeader";
import SHTButton from "../../components/SHTButton";
import SHTNoResult from "../../components/SHTNoResult";

interface propsInterface {
  highlights: newsInterface[];
  handleOnClick: any;
  loading: boolean;
}

const HighLightsComponent = (props: propsInterface) => {
  const { highlights, handleOnClick, loading } = props;
  return (
    <Container className="sht_container_space">
      <div className="py-4">
        <SHTLineHeader
          headerText="ALL HIGHLIGHTS"
          lineColor="white"
          lineWidth="short"
        />
      </div>

      <div className="d-flex flex-wrap ">
        {highlights?.length > 0 ? (
          <>
            {highlights.map((news: newsInterface) => (
              <div key={news.id} className="px-4 py-3">
                <SHTCard
                  handleOnClick={handleOnClick}
                  id={news.id}
                  //@ts-ignore
                  // image={news.featured_image.image}
                  video={news?.featured_video}
                  alt={news?.alt_text}
                  title={news.title}
                  date={news.created_at}
                  subText={news?.excerpt}
                />
              </div>
            ))}
          </>
        ) : (
          <div className="no_result_container">
            <SHTNoResult loading={loading} />
          </div>
        )}
      </div>
    </Container>
  );
};
export default HighLightsComponent;
