import React from "react";
import SHTText from "../../components/SHTText";
import SHTButton from "../../components/SHTButton";
import { Container } from "react-bootstrap";
import "../../assets/styles/page/team_banner_style.css";
type CardProps = {
  handleClick: Function
};

const TeamBanner = ({handleClick}: CardProps) => {
  return (
    <section className="team_banner_header my-5">
      <Container className="py-5">
        <div className="d-flex h-100 align-items-center">
          <div className="overlay"></div>
          <div className="banner_text">
            <SHTText size="h2" text="TEAM OF THE WEEK" color="white" />
            <SHTText size="h4" text="CHECK OUT EACH PLAYER" color="white" />
            <SHTButton
              name="View"
              type="submit"
              color="primary"
              className="my-2 py-2 px-5"
              handleClick={handleClick}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TeamBanner;
